import AuthService from '../services/auth.service';
const queryStatement = (params, strController) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;

  const resp = fetch(`${URL}${strController}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then((response) => response.json())
    .then((responseData) => {
      return responseData;
    })
    .catch((error) => console.warn(error));
  return resp;
};

const postCambioContrasena = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}cambiar-contrasena`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postRegresaImagenAvatar = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}regresa-imagen-avatar`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getValidaUsuarioSoporte = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}valida-soporte`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const UsuarioService = {
  queryStatement,
  postCambioContrasena,
  postRegresaImagenAvatar,
  getValidaUsuarioSoporte,
};

export default UsuarioService;
