import React, { useState, useEffect, useContext } from 'react';
import AuthService from '../services/auth.service';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Card,
  Col,
  Row,
  Typography,
  Layout,
  ConfigProvider,
  Space,
  Button,
  Select,
  Input,
} from 'antd';
import Logo from '../componentes/Logo';
import SolicitudesService from '../services/solicitudes.Service';
import { LoginOutlined, SaveOutlined } from '@ant-design/icons';

import { ComponentesContext } from '../contextos/componentesContext';

const { TextArea } = Input;

const { Header, Footer, Sider, Content } = Layout;

const { Meta } = Card;

const headerStyle = {
  textAlign: 'right',
  color: '#fff',
  height: 75,
  paddingInline: 50,
  paddingTop: 4,
  lineHeight: 'px',
  backgroundColor: '#061928',
};

const leftColStyle = {
  display: 'flex',
  alignContent: 'baseline',
  justifyContent: 'flex-end',
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: 15,
};

const rigthColStyle = {
  display: 'flex',
  justifyContent: 'flex-start',
  paddingLeft: 20,
  fontSize: 15,
};

const contentStyle = {
  textAlign: 'center',
  minHeight: 600,
  minWidth: 980,
  lineHeight: '25px',
  color: '#000000',
  backgroundColor: '#dedede',
  padding: 20,
};

const RespuestaHardwareSoftware = () => {
  const { openNotification, UpdateNotificationsCounter, ModalMessage } =
    useContext(ComponentesContext);

  const { token } = useParams();

  const [idToken, setidToken] = useState('');
  const [idTokenUsuario, setidTokenUsuario] = useState('');

  const [tituloDiv, settituloDiv] = useState('');

  const [usuarioBeneficiario, setusuarioBeneficiario] = useState('');
  const [puestoBeneficiario, setpuestoBeneficiario] = useState('');
  const [elementoSolicita, setelementoSolicita] = useState('');
  const [importe, setimporte] = useState('');
  const [justificacion, setjustificacion] = useState('');
  const [observaciones, setobservaciones] = useState('');

  const [formComentarios, setformComentarios] = useState('');
  const [formStatus, setformStatus] = useState(null);

  const [flagDetallesVisible, setflagDetallesVisible] = useState(true);
  const [divAutorizadoRechazado, setdivAutorizadoRechazado] = useState('');

  const [tipoSolicitud, settipoSolicitud] = useState(1);

  const [divHeight, setdivHeight] = useState(300);

  const [user, setUser] = useState({});
  const navigate = useNavigate();

  const handleGuardarRespuesta = async () => {
    if (formStatus == '') {
      ModalMessage(
        'Mensaje',
        'Debe seleccionar si Autoriza ó Rechaza la solicitud.'
      );
      return false;
    }

    if (formComentarios == '') {
      ModalMessage(
        'Mensaje',
        'Debe capturar un comentario para poder registrar la respuesta..'
      );
      return false;
    }

    let respuesta = await SolicitudesService.postRegistraRespuestaSolicitud({
      tokenSolicitud: idToken,
      tokenUsuario: idTokenUsuario,
      status: formStatus,
      comentarios: formComentarios,
    });

    if (respuesta.estado == 200) {
      if (formStatus != 2) {
        setdivAutorizadoRechazado('La solicitud fue autorizada correctamente.');
      } else {
        setdivAutorizadoRechazado('La solicitud fue rechazada correctamente.');
      }
    } else {
      ModalMessage(
        'Error',
        'Se produjo un error al registrar la respuesta, favor de reportar al area de sistemas.'
      );
      return false;
    }

    setflagDetallesVisible(false);
  };

  const handleInicializarFormulario = async () => {
    const arraytoken = token.toString().split('-');

    setflagDetallesVisible(false);
    setdivAutorizadoRechazado('Cargando información...');

    //console.log(arraytoken);
    //console.log(arraytoken.length);

    if (arraytoken.length < 2) {
      setflagDetallesVisible(false);
      setdivAutorizadoRechazado('El enlace ingresado es incorrecto.');
      return false;
    }

    setidToken(arraytoken[0]);
    setidTokenUsuario(arraytoken[1]);

    let solicitudesData =
      await SolicitudesService.getDatosAutorizarSolicitudToken(
        arraytoken[0],
        arraytoken[1]
      );

    //console.log('datos solicitud', solicitudesData);
    if (solicitudesData.estado == 500) {
      setdivAutorizadoRechazado(
        'No se pudo consultar la información, favor de reportar a Sistemas.'
      );
      setflagDetallesVisible(false);
      return false;
    }

    if (solicitudesData.estado == 201) {
      setflagDetallesVisible(false);
      setdivAutorizadoRechazado('La solicitud proporcionada ya no existe');
      return false;
    }

    setflagDetallesVisible(
      solicitudesData.data.statusAutorizar == 0 ? true : false
    );

    //console.log('Autorizado o rechazado', solicitudesData.data.statusAutorizar);
    setdivAutorizadoRechazado(
      solicitudesData.data.statusAutorizar == 1
        ? 'La solicitud ya fue autorizada'
        : 'La solicitud ya fue rechazada'
    );

    setusuarioBeneficiario(
      solicitudesData.data.usuarioBeneficiario.toUpperCase()
    );
    setpuestoBeneficiario(solicitudesData.data.puestoBeneficiario);
    setelementoSolicita(solicitudesData.data.elemento);

    const formatoMoneda = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setimporte(formatoMoneda.format(solicitudesData.data.importe));
    setjustificacion(solicitudesData.data.justificacion.toUpperCase());
    setobservaciones(solicitudesData.data.comentario.toUpperCase());

    settipoSolicitud(solicitudesData.data.tipoSolicitud);
  };

  useEffect(() => {
    setdivHeight(window.innerHeight - 100);

    const handleResize = () => {
      setdivHeight(window.innerHeight - 100);
    };

    let usr = AuthService.getCurrentUser();
    //console.log('USER HOOK', usr);
    setUser(usr);
    handleInicializarFormulario();
    // if (usr === null || usr === undefined) {
    //   AuthService.logout();
    //   navigate("/login");
    // }

    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#04142b',
        },
      }}
    >
      <Space
        direction="vertical"
        style={{
          width: '100%',
        }}
        size={[0, 48]}
      >
        <Layout>
          <Header style={headerStyle}>
            <Logo />
          </Header>
          <Layout style={{ height: window.innerHeight - 80 }}>
            <Content style={contentStyle}>
              <div
                id="contenedor-admin"
                className="content-main"
                style={{
                  backgroundColor: 'white',
                  height: '100%',
                  padding: 20,
                  minWidth: 800,
                  display: 'flex',
                  justifyContent: 'center',
                  maxHeight: divHeight,
                  overflow: 'auto',
                }}
              >
                <Typography.Title
                  level={1}
                  style={{ color: '#081b3e', marginTop: 150 }}
                >
                  {tituloDiv}
                </Typography.Title>

                <div
                  style={{
                    width: '70%',
                    display: flagDetallesVisible ? 'none' : '',
                    marginTop: 50,
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      borderRadius: 25,
                      backgroundColor: '#eff0f0',
                      paddingTop: 20,
                      paddingBottom: 20,
                    }}
                  >
                    <div
                      style={{
                        fontSize: 30,
                        fontWeight: 'bold',
                        marginTop: 30,
                        marginBottom: 30,
                      }}
                    >
                      "{divAutorizadoRechazado}"
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    width: '70%',
                    display: flagDetallesVisible ? '' : 'none',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      borderRadius: 25,
                      backgroundColor: '#eff0f0',
                      paddingTop: 20,
                      paddingBottom: 20,
                    }}
                  >
                    <div
                      style={{
                        fontSize: 30,
                        fontWeight: 'bold',

                        marginBottom: 30,
                      }}
                    >
                      Detalle de la solicitud de{' '}
                      {tipoSolicitud == 1 ? 'Hardware' : 'Software'}:
                    </div>

                    <Row>
                      <Col span={11} style={leftColStyle}>
                        Usuario que solicita:
                      </Col>
                      <Col span={12} style={rigthColStyle}>
                        {usuarioBeneficiario}
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 10 }}>
                      <Col span={11} style={leftColStyle}>
                        Puesto:
                      </Col>
                      <Col span={12} style={rigthColStyle}>
                        {puestoBeneficiario}
                      </Col>
                    </Row>

                    <Row style={{ marginTop: 10 }}>
                      <Col span={11} style={leftColStyle}>
                        {tipoSolicitud == 1 ? 'Elemento' : 'Software'} que
                        solicita:
                      </Col>
                      <Col span={12} style={rigthColStyle}>
                        {elementoSolicita}
                      </Col>
                    </Row>

                    <Row style={{ marginTop: 10 }}>
                      <Col span={11} style={leftColStyle}>
                        Importe:
                      </Col>
                      <Col span={12} style={rigthColStyle}>
                        {importe}
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 10 }}>
                      <Col span={11} style={leftColStyle}>
                        Justificación:
                      </Col>
                      <Col
                        span={12}
                        style={{
                          textAlign: 'left',
                          paddingLeft: 20,
                        }}
                      >
                        {justificacion}
                      </Col>
                    </Row>

                    <Row style={{ marginTop: 10, marginBottom: 20 }}>
                      <Col span={11} style={leftColStyle}>
                        Obsevación del area de sistemas:
                      </Col>
                      <Col
                        span={12}
                        style={{
                          textAlign: 'left',
                          paddingLeft: 20,
                        }}
                      >
                        {observaciones}
                      </Col>
                    </Row>

                    <div
                      style={{
                        fontSize: 23,
                        fontWeight: 'bold',
                        marginBottom: 20,
                      }}
                    >
                      Por Favor Autorice o Rechace la Solicitud:
                    </div>
                    <dic style={{ width: '100%' }}>
                      <center>
                        <div
                          style={{
                            width: '80%',
                            borderRadius: 25,
                            backgroundColor: '#fcfcfd',
                            paddingTop: 20,
                            paddingBottom: 20,
                          }}
                        >
                          <Row style={{ marginTop: 10 }}>
                            <Col span={11} style={leftColStyle}>
                              Autorizar/Rechazar:
                            </Col>
                            <Col span={12} style={rigthColStyle}>
                              <Select
                                placeholder="Seleccione una opción"
                                style={{ textAlign: 'left', width: '100%' }}
                                options={[
                                  {
                                    value: 1,
                                    label: 'Autorizar',
                                    nombre: 'Autorizar',
                                  },
                                  {
                                    value: 2,
                                    label: 'Rechazar',
                                    nombre: 'Rechazar',
                                  },
                                ]}
                                onChange={(e) => {
                                  setformStatus(e);
                                }}
                                value={formStatus}
                              />
                            </Col>
                          </Row>

                          <Row style={{ marginTop: 10 }}>
                            <Col span={11} style={leftColStyle}>
                              Comentarios:
                            </Col>
                            <Col span={12} style={rigthColStyle}>
                              <TextArea
                                value={formComentarios}
                                onChange={(e) => {
                                  setformComentarios(e.target.value);
                                }}
                                rows={4}
                              />
                            </Col>
                          </Row>
                        </div>
                      </center>
                    </dic>

                    <Button
                      style={{ width: 190, height: 60, marginTop: 20 }}
                      type="primary"
                      onClick={() => handleGuardarRespuesta()}
                    >
                      <SaveOutlined style={{ fontSize: 20 }} /> Registrar
                      Respuesta
                    </Button>
                  </div>
                </div>
              </div>
            </Content>
          </Layout>
        </Layout>
      </Space>
    </ConfigProvider>
  );
};
export default RespuestaHardwareSoftware;
