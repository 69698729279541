import AuthService from './auth.service';

const postAgregarClasificacion = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}agregar-clasificaciones`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postBorrarClasificacion = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}borrar-clasificacion`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postSubirOrdenClasificacion = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}subir-clasificacion`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postBajarOrdenClasificacion = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}bajar-clasificacion`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postAgregarEmpresas = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}agregar-empresa`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postBorrarEmpresas = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}borrar-empresas`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postSubirOrdenEmpresa = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}subir-empresa`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postBajarOrdenEmpresa = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}bajar-empresa`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postAgregarPlazas = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}agregar-plazas`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postBorrarPlazas = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}borrar-plazas`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postSubirOrdenPlazas = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}subir-plaza`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postBajarOrdenPlazas = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}bajar-plaza`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postAgregarUsuario = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}agregar-usuario`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postActualizarUsuario = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}actualizar-usuario`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postActualizarDatosUsuario = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}update-usuarios-data`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postBorrarUsuarios = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}borrar-usuario`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postEnviarCorreoUsuario = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}enviar-correo-usuario`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getListaSistemas = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}sistemas-tickets`, {
    method: 'GET',
    headers: {
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getListaPuestos = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}catalogos/puestos`, {
    method: 'GET',
    headers: {
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getListaElementos = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}hardware-software/lista`, {
    method: 'GET',
    headers: {
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postAgregarPuesto = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}agregar-puesto`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postAgregarSistema = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}agregar-sistema`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postBorrarSistema = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}borrar-sistema`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postBorrarPuesto = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}borrar-puesto`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getListaComponentes = (getParameter) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}hardware-software/componentes/${getParameter}`, {
    method: 'GET',
    headers: {
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postAgregarHardwareSoftware = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}hardware-software/agregar`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postActivaDesactivaHardwareSoftware = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}hardware-software/activa-desactiva`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postRegresaConfiguracion = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}configuraciones/configuracion`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postActualizaConfiguracion = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}configuraciones/actualiza`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const CatalogosService = {
  postAgregarClasificacion,
  postBorrarClasificacion,
  postSubirOrdenClasificacion,
  postBajarOrdenClasificacion,
  postAgregarEmpresas,
  postBorrarEmpresas,
  postSubirOrdenEmpresa,
  postBajarOrdenEmpresa,
  postAgregarPlazas,
  postBorrarPlazas,
  postSubirOrdenPlazas,
  postBajarOrdenPlazas,
  postAgregarUsuario,
  postActualizarUsuario,
  postActualizarDatosUsuario,
  postBorrarUsuarios,
  postEnviarCorreoUsuario,
  getListaSistemas,
  getListaPuestos,
  postAgregarPuesto,
  postAgregarSistema,
  postBorrarSistema,
  postBorrarPuesto,
  getListaComponentes,
  postActivaDesactivaHardwareSoftware,
  postAgregarHardwareSoftware,
  getListaElementos,
  postRegresaConfiguracion,
  postActualizaConfiguracion,
};

export default CatalogosService;
