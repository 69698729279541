import React, { useEffect, useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Layout,
  theme,
  ConfigProvider,
  Menu,
  Typography,
  Avatar,
  Badge,
  notification,
  Modal,
  Card,
  Button,
  Upload,
  Tooltip,
  Modal as ModalContrasena,
  Input,
  Spin,
  Row,
  Col,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/auth.service';
import NotificationsService from '../services/nofications.Service';
import TicketsService from '../services/tickets.Service';
import UsuariosService from '../services/usuarios.Service';
import returnValidUploadFileClass from '../clases/returnValidUploadFile.class';
import LocalData from '../persistencia/LocalData';

import logoTickets from '../asset/images/LogoTicketsBlanco.png';

import './StructureUser.css';
import {
  ProfileOutlined,
  LogoutOutlined,
  UserOutlined,
  HomeOutlined,
  ToolOutlined,
  BellOutlined,
  SettingOutlined,
  ReadOutlined,
  SaveOutlined,
  CloseOutlined,
  FileDoneOutlined,
  ContainerOutlined,
} from '@ant-design/icons';

import { ComponentesContext } from '../contextos/componentesContext';

StructureAdmin.propTypes = {
  children: PropTypes.string, // Se asegura que children sea de tipo React node
  menuOption: PropTypes.string, // Ajusta el tipo según lo que esperes recibir
};

export default function StructureAdmin({ children, menuOption }) {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;

  const ref1 = useRef(null);

  const {
    notificationsCounter,
    UpdateNotificationsCounter,
    UpdateDataLista,
    UpdatesettableHeight,
    ModalMessage,
    userImagenUrl,
    UpdatesetuserImagenUrl,
    UpdatesetdivContentWidth,
    tableHeight,
  } = useContext(ComponentesContext);
  const [user, setUser] = useState({});
  const [nombreUsuario, setNombreUsuario] = useState(user.fullname);
  const [Correo, setCorreo] = useState(user.correo);
  const [loadingModal, setloadingModal] = useState(false);
  const [imagenUsuario, setimagenUsuario] = useState(user.imagen_usuario);

  const navigate = useNavigate();
  let notificationsInterval = useRef();
  const { Title } = Typography;
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const [modal1Open, setModal1Open] = useState(false);
  const [OpenmodalContrasena, setOpenmodalContrasena] = useState(false);

  const headerStyle = {
    textAlign: 'right',
    color: '#fff',
    height: 50,
    paddingInline: 50,
    paddingTop: 4,
    lineHeight: '45px',
    backgroundColor: '#2b455a',
  };
  const contentStyle = {
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '120px',
    color: '#000000',
    padding: 20,
  };

  const openNotificationAlerts = (count) => {
    let strMensaje = '';
    if (count == 1) {
      strMensaje = 'Tienes ' + count + ' notificación pendiente de ver.';
    } else {
      strMensaje = 'Tienes ' + count + ' notificaciones pendiente de ver.';
    }

    notification.open({
      message: 'Notificación',
      description: (
        <Typography.Title level={3} style={{ margin: 0, color: '#081b3e' }}>
          <BellOutlined style={{ fontSize: 30, color: '#f9dc06' }} />{' '}
          {strMensaje}
        </Typography.Title>
      ),
      className: 'custom-class',
      style: {
        width: 600,
      },
      duration: 4,
    });
  };

  const getTicketsData = async () => {
    let ticketsData = await TicketsService.getTicketsSoporte();
    UpdateDataLista(ticketsData.data);
  };

  const getNotificacions = async () => {
    let notificationsData = await NotificationsService.getNotifications();

    if (notificationsData.estado == 500) {
      return false;
    }

    let totalNotificaciones = 0;
    for (const element of notificationsData.data) {
      if (element.mostrar == 1) {
        totalNotificaciones++;
      }
    }
    UpdateNotificationsCounter(notificationsData.data.length);

    if (totalNotificaciones > 0) {
      openNotificationAlerts(totalNotificaciones);
      getTicketsData();
    }
  };

  let NotificationBeginInterval = () => {
    notificationsInterval.current = setInterval(async () => {
      let usr = AuthService.getCurrentUser();
      if (usr === null || usr === undefined) {
        clearInterval(notificationsInterval.current);
        return false;
      }
      getNotificacions();
    }, 60000);
  };

  useEffect(() => {
    UpdatesettableHeight(window.innerHeight - 400);
    UpdatesetdivContentWidth(window.innerWidth - 410);

    const handleResize = () => {
      UpdatesettableHeight(window.innerHeight - 400);
      UpdatesetdivContentWidth(window.innerWidth - 410);
    };
    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      setUser({});

      setCorreo('');
    } else {
      setUser(usr);

      setNombreUsuario(usr.fullname);
      setCorreo(usr.correo);

      validaUsuarioSoporte();
      setimagenUsuario('');
      UpdatesetuserImagenUrl('');

      window.addEventListener('resize', handleResize);
    }

    NotificationBeginInterval();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const validaUsuarioSoporte = async () => {
    let tipoUsuario = await UsuariosService.getValidaUsuarioSoporte();

    if (tipoUsuario.estado == 200) {
      if (!tipoUsuario.usuarioSoporte) {
        navigate('/');
      }
    }
  };

  const handleNext = (option) => {
    navigate(option);
  };

  const logOut = () => {
    AuthService.logout();
    navigate('/login');
  };

  const itemsMenu = [
    getItem(
      <a onClick={() => handleNext('/')}>Inicio</a>,
      '1',
      <HomeOutlined />
    ),
    getItem(
      <a onClick={() => handleNext('/lista-tickets-soporte')}>
        Lista de Tickets
      </a>,
      '2',
      <ProfileOutlined />
    ),

    getItem(
      <a onClick={() => handleNext('/solicitudes-soporte')}>Solicitudes</a>,
      '4',
      <FileDoneOutlined />
    ),

    getItem(
      <a onClick={() => handleNext('/catalogos')}>Catalogos</a>,
      '5',
      <ContainerOutlined />
    ),

    getItem(
      <a onClick={() => handleNext('/base-conocimientos')}>
        Base de conocimientos
      </a>,
      '6',
      <ReadOutlined />
    ),

    getItem(
      <a onClick={() => logOut()}>Cerrar Sesión</a>,
      '7',
      <LogoutOutlined />
    ),
  ];

  const handleAvatar = () => {
    setModal1Open(true);
  };

  const handleBeforeUpload = async (file) => {
    setloadingModal(true);

    let flagArchivoValido =
      await returnValidUploadFileClass.returnImageValidUploadFile(file.type);
    if (flagArchivoValido == false) {
      ModalMessage(
        'Mensaje',
        'El tipo de archivo que se desea adjuntar no es valido.'
      );
      return false;
    }

    const fileSizeLimit = 1 * 1024 * 1024; // 5MB

    if (file.size > fileSizeLimit) {
      ModalMessage('Mensaje', 'El archivo no puede pesar mas de 1MB.');
      return false; // Cancela la carga del archivo
    }
    return true;
  };

  const handleChange = async () => {
    setTimeout(async function () {
      let UsuariosData = await TicketsService.getDatosUsuarios();
      setUser(UsuariosData.dataEmp);
      LocalData.save('user', UsuariosData.dataEmp);
      setimagenUsuario(UsuariosData.dataEmp.imagen_usuario);
      setloadingModal(false);
    }, 8000);
  };

  const handleOpenModalContra = () => {
    setactualpassword('');
    setPassword('');
    setrepassword('');
    setOpenmodalContrasena(true);
  };

  const handleCloseModalContra = () => {
    setOpenmodalContrasena(false);
  };

  const uploadButton = (
    <div>
      <Tooltip placement="bottom" title={'Click para cambiar imagen.'}>
        <Avatar
          src={imagenUsuario}
          shape="square"
          size={84}
          icon={<UserOutlined />}
          style={{ backgroundColor: '#04142b' }}
        />
      </Tooltip>
    </div>
  );

  const [password, setPassword] = useState('');
  const [repassword, setrepassword] = useState('');
  const [actualpassword, setactualpassword] = useState('');

  const handleActualPasswordChange = (event) => {
    const inputPassword = event.target.value;
    if (!inputPassword.includes(' ')) {
      setactualpassword(inputPassword);
    }
  };

  const handlePasswordChange = (event) => {
    const inputPassword = event.target.value;
    if (!inputPassword.includes(' ')) {
      setPassword(inputPassword);
    }
  };

  const handleRePasswordChange = (event) => {
    const inputPassword = event.target.value;
    if (!inputPassword.includes(' ')) {
      setrepassword(inputPassword);
    }
  };

  const handleGuardarContrasena = async () => {
    if (password == '' || repassword == '' || actualpassword == '') {
      ModalMessage('Mensaje', 'Las contraseñas no pueden estar en blanco.');

      return false;
    }

    if (password != repassword) {
      ModalMessage(
        'Mensaje',
        'Las contraseñas que ingresaste no coinciden. Asegúrate de escribir la misma contraseña en ambos campos para poder continuar.'
      );
      return false;
    }
    const jsonPost = {
      contrasena_actual: actualpassword,
      contrasena: password,
    };
    let respuesta = await UsuariosService.postCambioContrasena(jsonPost);

    ModalMessage('Mensaje', respuesta.mensaje);

    if (respuesta.estado != 403) {
      setactualpassword('');
      setPassword('');
      setrepassword('');
      setOpenmodalContrasena(false);
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#04142b',
        },
      }}
    >
      <div style={{ backgroundColor: '#dedede', minWidth: 1400 }}>
        <Row>
          <Col style={{ width: '100%' }}>
            <div style={headerStyle}>
              <Row>
                <Col
                  style={{ width: '30%', textAlign: 'left', paddingLeft: 27 }}
                >
                  {' '}
                  <img src={logoTickets} style={{ width: 140 }}></img>{' '}
                </Col>
                <Col style={{ width: '70%' }}>
                  {/* <Tooltip title="Novedades.">
                  <Button
                    type="primary"
                    shape="circle"
                    danger
                    icon={<BulbOutlined />}
                    onClick={() => setOpen(true)}
                  />
                </Tooltip> */}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <ToolOutlined /> Soporte, {nombreUsuario} &nbsp;{' '}
                  <a onClick={(e) => e.preventDefault()}>
                    <Badge size="small" count={notificationsCounter}>
                      <Avatar
                        onClick={handleAvatar}
                        src={user.imagen_usuario}
                        size="large"
                        icon={<UserOutlined />}
                        ref={ref1}
                      />
                    </Badge>
                  </a>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row
          style={{ minWidth: 1400, height: tableHeight + 345, minHeight: 400 }}
          className="custom-row"
        >
          <Col
            style={{
              minWidth: 300,
              maxWidth: 300,
              widows: '20%',
              backgroundColor: '#ebeaea',
              display: 'flex',
              justifyContent: 'center',
              paddingTop: 20,
              minHeight: 500,
            }}
            className="custom-col"
            flex="1 0 auto"
          >
            <div
              style={{
                borderRadius: 10,
                paddingTop: 20,
                width: 256,
              }}
            >
              <div
                style={{
                  backgroundColor: '#2c445c',
                  borderTopRightRadius: 10,
                  borderTopLeftRadius: 10,
                  height: 70,
                  display: 'flex',
                  alignContent: 'baseline',
                }}
              >
                <Title style={{ color: '#fff', marginLeft: 35 }} level={4}>
                  <ToolOutlined /> Menu Soporte
                </Title>
              </div>

              <Menu
                style={{
                  width: 256,
                  fontWeight: 'bold',
                  borderRadius: 0,
                  borderWidth: 0,
                  color: 'black',
                  paddingTop: 10,
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10,
                }}
                selectedKeys={[menuOption]}
                mode="inline"
                items={itemsMenu}
              />
            </div>
          </Col>
          <Col
            style={{
              backgroundColor: '#dedede',
              minHeight: 500,
            }}
            className="custom-col"
            flex="1 0 auto"
          >
            <div style={contentStyle}>
              <div
                className="content-main"
                style={{
                  backgroundColor: 'white',
                  padding: 20,
                  height: tableHeight + 260,
                  minHeight: 400,
                }}
              >
                {children}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        style={{ top: 15, left: window.innerWidth / 2 - 280 }}
        open={modal1Open}
        onOk={() => setModal1Open(false)}
        onCancel={() => setModal1Open(false)}
        footer={<></>}
        maskClosable={false}
        closeIcon={
          <CloseOutlined
            style={{
              backgroundColor: '#e3e3e3',
              borderRadius: 5,
              fontSize: 20,
              padding: 5,
            }}
          />
        }
      >
        <Spin spinning={loadingModal} active={true}>
          <input
            type="file"
            id="target"
            style={{ display: 'none' }}
            accept="image/png, image/gif, image/jpeg"
          />
          <Card
            style={{
              width: '100%',
              marginTop: 35,
            }}
            actions={[
              <Button
                key={'btnCambiarContra'}
                style={{ color: '#696969' }}
                icon={<SettingOutlined />}
                size="large"
                onClick={handleOpenModalContra}
              >
                Cambiar contraseña
              </Button>,
              // <Button
              //   onClick={(e) => {
              //     setModal1Open(false);
              //   }}
              //   style={{ color: '#696969', width: 200 }}
              //   icon={<CloseCircleOutlined />}
              //   size="large"
              // >
              //   Cerrar
              // </Button>,
            ]}
          >
            <table cellSpacing={0} aria-hidden="true">
              <tbody>
                <tr>
                  <td rowSpan={2}>
                    <Upload
                      name="archivo"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action={`${URL}cambia-imagen?token=${AuthService.getCurrentToken()}`}
                      beforeUpload={handleBeforeUpload}
                      onChange={handleChange}
                    >
                      {userImagenUrl ? (
                        <img
                          src={user.imagen_usuario}
                          alt="avatar"
                          style={{ width: '100%' }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </td>
                  <td style={{ verticalAlign: 'bottom' }}>
                    <b>Usuario:</b> {nombreUsuario}
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: 'top' }}>
                    {' '}
                    <b>Correo:</b> {Correo}
                  </td>
                </tr>
              </tbody>
            </table>
          </Card>
        </Spin>
      </Modal>

      <ModalContrasena
        style={{ top: 20, left: window.innerWidth / 2 - 280 }}
        open={OpenmodalContrasena}
        footer={<></>}
        closable={false}
      >
        <ConfigProvider
          theme={{
            components: {
              Button: {
                colorPrimary: '#3c5c7c',
                colorPrimaryHover: '#517da9',
                colorPrimaryActive: '#517da9',
              },
            },
          }}
        >
          <b>Contraseña actual:</b>
          <Input.Password
            placeholder="Ingrese contraseña 123..."
            style={{ width: 480, marginBottom: 20 }}
            value={actualpassword}
            onChange={handleActualPasswordChange}
          />

          <b>Nueva contraseña:</b>
          <Input.Password
            placeholder="Ingrese contraseña 123..."
            style={{ width: 480, marginBottom: 20 }}
            value={password}
            onChange={handlePasswordChange}
          />
          <b>Vuelve a ingresar la nueva contraseña:</b>
          <Input.Password
            placeholder="Ingrese nuevamente la contraseña 123..."
            style={{ width: 480, marginBottom: 20 }}
            value={repassword}
            onChange={handleRePasswordChange}
          />
          <center>
            <table aria-hidden="true">
              <tbody>
                <tr>
                  <td>
                    <Button
                      style={{ width: 190 }}
                      type="primary"
                      danger
                      onClick={() => handleCloseModalContra()}
                      icon={<CloseOutlined />}
                    >
                      Salir
                    </Button>
                  </td>
                  <td>
                    <Button
                      style={{ width: 190 }}
                      type="primary"
                      onClick={() => handleGuardarContrasena()}
                      icon={<SaveOutlined />}
                    >
                      Guardar contraseña
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </center>
        </ConfigProvider>
      </ModalContrasena>
    </ConfigProvider>
  );
}
