import React, { useState, useEffect, useContext } from "react";
import AuthService from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import "./DashBoard.css";
import { Card,Typography, Table, Divider, Badge, Skeleton } from "antd";
import { BellOutlined } from "@ant-design/icons";
import Structure from "../../componentes/StructureMonitor";
import { ComponentesContext } from "../../contextos/componentesContext";
import moment from "moment";
import TicketsService from "../../services/tickets.Service";
import RegresaTiempoTranscurrido from "../../componentes/ReturnElapsedTime";
import ReturnStatusTag from "../../componentes/ReturnStatusTagObject";
const { Meta } = Card;

const ListaTickets = () => {
  const { DataLista, UpdateDataLista, tableHeight } = useContext(ComponentesContext);
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const [valorRow, setvalorRow] = useState("");
  const [Data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const ReturnNotificationCount = (cantidad) => {
    
    return (
      <Badge count={cantidad} />
    );
  };

  const HandlePrueba = (id_ticket) =>{

    navigate("/detalle-ticket-soporte", {
      state: {
        record: {
          id_ticket: id_ticket,
        },
      },
    });
  }

  const HandleClick = (record) =>{
    setvalorRow(record.ticket);
  }

  const columns = [
    {
      title: "No. Ticket",
      dataIndex: "ticket",
      key: "ticket",
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.ticket - b.ticket,
      render: (text) => (
        <a className="button" onClick={ () => { HandlePrueba(text) }  } ><span>{text} </span></a>
      ),
    },
    {
      title: "Solicita",
      dataIndex: "usuario_ticket",
      key: "usuario_ticket",
    },
    {
      title: "Titulo",
      dataIndex: "titulo",
      key: "titulo",
    },
    {
      title: "Clasificación",
      dataIndex: "clasificacion",
      key: "clasificacion",
    },
    {
      title: "Estatus",
      key: "status",
      dataIndex: "status",
      filters: [
        {
          text: "No asignado",
          value: "0",
        },
        {
          text: "Asignado",
          value: "1",
        },
        {
          text: "Trabajando",
          value: "2",
        },
        {
          text: "Terminado",
          value: "3",
        },
        {
          text: "Finalizado",
          value: "4",
        },
        {
          text: "Cancelado",
          value: "5",
        },
      ],
      onFilter: (value, record) => record.status.startsWith(value),
      defaultSortOrder: "descend",
      sorter: (a, b) => a.status - b.status,
      render: (_, record) => <>&nbsp;&nbsp;&nbsp;&nbsp;{ReturnStatusTag(record.status)}</>,
    },
    {
      title: "Fecha",
      dataIndex: "fecha",
      key: "fecha",
      sorter: (a, b) => moment(a.fecha).unix() - moment(b.fecha).unix(),
      render: (_, record) => (
        <>{moment(record.fecha).format("YYYY-MM-DD, h:mm:ss a")}</>
      ),
    },

    {
      title: "Transcurrido",
      dataIndex: "fecha",
      key: "fecha",
      render: (_, record) => (
        <>{RegresaTiempoTranscurrido(record.fecha)}</>
      ),
    },
    {
      title: "Usuario asignado",
      dataIndex: "usuario_asignado",
      key: "usuario_asignado",
    },
    {
      title: <BellOutlined/>,
      width: 60,
      align: "center",
      dataIndex: "notificaciones",
      key: "notificaciones",
      render: (_, record) => <>{ReturnNotificationCount(record.notificaciones)}</>,
    },
  ];
  
  const getTicketsData = async () => {
    let ticketsData = await TicketsService.getTicketsSoporte();
    UpdateDataLista(ticketsData.data);
  };

  useEffect(() => {

    let usr = AuthService.getCurrentUser();
    setUser(usr);

    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate("/login");
    }

    getTicketsData();
    setLoading(false);
  }, []);

  return (
    <Structure
      breadcrumb={"Bienvenido al sistema Fondeadora Finamo"}
      menuOption={"2"}
    >
      <Skeleton loading={loading} active={true}>
      <center>
        <Typography.Title level={1} style={{ margin: 0, color: "#081b3e" }}>
          Monitor de Tickets
          <Divider />
        </Typography.Title>
      </center>
      
      <center>
      
        <Table
          style={{minWidth:700, marginTop:-10}}
          size="middle"
          columns={columns}
          dataSource={DataLista}
          pagination={false}
          scroll={{
            y: tableHeight - 180,
          }}
          onRow={(record, rowIndex) => {
            return {
              onMouseEnter: (event) => {HandleClick(record)},
            };
          }}
        />
        
      </center>
      </Skeleton>
    </Structure>
  );
};
export default ListaTickets;
