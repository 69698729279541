import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Select,
  Input,
  Row,
  Col,
  Divider,
  Table,
  Modal,
  InputNumber,
} from 'antd';
import {
  EditOutlined,
  CloseOutlined,
  SearchOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
const { TextArea } = Input;
import UtilsClass from '../../clases/Utils.class';
import { ComponentesContext } from '../../contextos/componentesContext';
import SolicitudesService from '../../services/solicitudes.Service';
import TicketsService from '../../services/tickets.Service';
import CatalogosService from '../../services/catalogos.Service';
import getColumnSearchProps from '../SearchPropsCustom';
import AuthService from '../../services/auth.service';

import returnDateFormatClass from '../../clases/returnDateFormat.class';
import returnTagClass from '../../clases/returnTags.class';

export default function SoftwareRevision(param = null) {
  const tableRefSolicitudes = useRef();
  const navigate = useNavigate();
  const { ShowLoading, ModalMessage } = useContext(ComponentesContext);

  const [idRegistro, setidRegistro] = useState(false);

  const [flagFolioVisible, setflagFolioVisible] = useState(false);
  const [flagBloquearFormulario, setflagBloquearFormulario] = useState(false);
  const [inputFolio, setinputFolio] = useState('');
  const [inputCorreoBeneficio, setinputCorreoBeneficio] = useState('');
  const [inputEspecifique, setinputEspecifique] = useState('');
  const [inputJustificacion, setinputJustificacion] = useState('');
  const [DataSolicitudes, setDataSolicitudes] = useState([]);
  const [formBeneficiario, setformBeneficiario] = useState('');
  const [formPuesto, setformPuesto] = useState('');
  const [formComponente, setformComponente] = useState('');
  const [optionComponentes, setoptionComponentes] = useState('');
  const [optionPuestos, setoptionPuestos] = useState('');
  const [optionUsuarios, setoptionUsuarios] = useState('');
  const divInternoVisible = '';
  const [divExternoVisible, setdivExternoVisible] = useState('none');
  const divSistemas = '';
  const [isModalOpen, setisModalOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const [inputImporte, setinputImporte] = useState(0);
  const [inputComentarios, setinputComentarios] = useState('');

  useEffect(() => {
    if (param.selectedTab == 'revision' || param.token != 'valor') {
      InicializaFormulario();
    }
  }, [param.selectedTab]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const InicializaFormulario = async () => {
    getTableData();
    getComponentesData();
    getPuestosData();

    if (param.token != 'valor' && param.token != null) {
      let solicitudesData =
        await SolicitudesService.getListaRevisionSolicitudesToken(
          2,
          param.token
        );

      if (solicitudesData.estado == 201) {
        ModalMessage('Mensaje', 'No se encontro la solicitud proporcionada.');
        navigate('/solicitudes-soporte/software/revision/valor');
        return false;
      }

      if (solicitudesData.estado != 200) {
        let usr = AuthService.getCurrentUser();
        if (usr === null || usr === undefined) {
          return false;
        }
        ModalMessage(
          'Mensaje',
          'Se produjo un error al cargar los datos, favor de reportar al area de sistemas.'
        );
        return false;
      }

      if (solicitudesData.data[0].id_status == 1) {
        ModalMessage('Mensaje', 'La solicitud ya fue verificada.');
        navigate('/solicitudes-soporte/software/revision/valor');
        return false;
      }

      handleOpenModal(solicitudesData.data[0]);
    }
  };

  const getTableData = async () => {
    let solicitudesData =
      await SolicitudesService.getListaRevisionSolicitudes(2);

    if (solicitudesData.estado != 200) {
      let usr = AuthService.getCurrentUser();
      if (usr === null || usr === undefined) {
        return false;
      }
      ModalMessage(
        'Mensaje',
        'Se produjo un error al cargar los datos, favor de reportar al area de sistemas.'
      );
      return false;
    }

    setDataSolicitudes(solicitudesData.data);
  };

  const getComponentesData = async () => {
    let ComponentesData = await CatalogosService.getListaComponentes(2);

    setoptionComponentes(
      ComponentesData.data.map((item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.nombre}</div>,
        nombre: item.nombre,
      }))
    );
  };

  const getPuestosData = async () => {
    let puestosData = await CatalogosService.getListaPuestos();

    setoptionPuestos(
      puestosData.data.map((item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.descripcion}</div>,
        nombre: item.descripcion,
      }))
    );
  };

  const handleBeneficiarioChange = (e, option) => {
    setformBeneficiario(e);
    setformPuesto(option.puesto);
    setinputCorreoBeneficio(option.correo);
  };

  const handleSelectChange = (value) => {
    setSelectedValue(value.toString());
  };

  const HandleRevisarSolicitud = async () => {
    if (inputComentarios == '') {
      ModalMessage('Mensaje', 'Debes capturar un comentario.');
      return false;
    }

    const json = {
      id_registro: idRegistro,
      importe: inputImporte,
      comentarios: inputComentarios,
    };

    let SolicitudesData = await SolicitudesService.postVerificaSolicitud(json);

    if (SolicitudesData.estado == 200) {
      getTableData();
      ModalMessage('Mensaje', 'Se registró la verificación correctamente.');
      setisModalOpen(false);
    } else {
      ModalMessage(
        'Mensaje',
        'Error al registrar la verificación, favor de reportar a el area de sistemas.'
      );
    }
  };

  const HandleRechazarSolicitud = async () => {
    if (inputComentarios == '') {
      ModalMessage('Mensaje', 'Debes capturar un comentario.');
      return false;
    }

    const json = {
      id_registro: idRegistro,
      comentarios: inputComentarios,
    };

    let SolicitudesData = await SolicitudesService.postRechazarSolicitud(json);

    if (SolicitudesData.estado == 200) {
      getTableData();
      ModalMessage('Mensaje', 'La solicitud se rechazó correctamente.');
      setisModalOpen(false);
    } else {
      ModalMessage(
        'Mensaje',
        'Error al registrar el rechazo, favor de reportar a el area de sistemas.'
      );
    }
  };

  const handleOpenModal = async (record) => {
    ShowLoading(true);

    setidRegistro(record.id);

    let UsuariosData = await TicketsService.getListaDatosUsuarios();

    if (UsuariosData.estado != 200) {
      ModalMessage(
        'Mensaje',
        'Se produjo un error al cargar los datos, favor de reportar al area de sistemas.'
      );
      return false;
    }

    setoptionUsuarios(
      UsuariosData.data.map((item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.nombre_completo}</div>,
        nombre: item.nombre_completo,
        correo: item.correo,
        puesto: item.id_puesto,
      }))
    );

    setinputFolio(String(record.id).padStart(5, '0'));
    setformBeneficiario(record.id_usuario);

    const filteredDataBeneficiario = UsuariosData.data.filter(
      (item) => parseInt(item.id) === record.id_usuario
    );

    if (filteredDataBeneficiario.length > 0) {
      setformPuesto(filteredDataBeneficiario[0].id_puesto);
      setinputCorreoBeneficio(filteredDataBeneficiario[0].correo);
    }

    setformComponente(record.id_componente);
    setinputJustificacion(record.justificacion.toString().toUpperCase());

    let ComponentesData = await CatalogosService.getListaComponentes(2);

    const filteredDataComponente = ComponentesData.data.filter(
      (item) => parseInt(item.id) === record.id_componente
    );

    if (filteredDataComponente[0].nombre == 'OTRO') {
      setdivExternoVisible('');
      setinputEspecifique(record.nombre_componente.toString().toUpperCase());
    } else {
      setdivExternoVisible('none');
    }

    setinputImporte(
      record.importe_cotizado == null ? 0.0 : record.importe_cotizado
    );

    setinputComentarios(record.comentarios);

    ShowLoading(false);

    setflagFolioVisible(true);
    setflagBloquearFormulario(true);
    setisModalOpen(true);
  };

  const columnsSolicitudes = [
    {
      title: 'Opciones',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 85,
      render: (_, record) => (
        <div>
          {record.id_status == 0 ? (
            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={() => {
                handleOpenModal(record);
              }}
              style={{ width: 40, height: 34 }}
            ></Button>
          ) : (
            <></>
          )}
        </div>
      ),
    },

    {
      width: 90,
      title: 'Folio',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => <div>{String(record.id).padStart(5, '0')}</div>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      width: 150,
      title: 'Estatus',
      key: 'status',
      dataIndex: 'status',
      filters: [
        {
          text: 'Pendiente',
          value: 'Pendiente',
        },
        {
          text: 'Verificado',
          value: 'Verificado',
        },
        {
          text: 'Autorizado',
          value: 'Autorizado',
        },
        {
          text: 'Entregado',
          value: 'Entregado',
        },
        {
          text: 'Rechazado',
          value: 'Rechazado',
        },
        {
          text: 'Cancelado',
          value: 'Cancelado',
        },
        {
          text: 'Otro',
          value: 'Otro',
        },
      ],
      onFilter: (value, record) => record.status.startsWith(value),
      sorter: (a, b) => a.status.length - b.status.length,
      render: (_, record) => (
        <>
          &nbsp;&nbsp;&nbsp;&nbsp;
          {returnTagClass.ReturnTipoSolicitudHardwareSoftwareTag(record.status)}
        </>
      ),
    },
    {
      width: 250,
      title: 'Usuario solicita',
      dataIndex: 'nombre_usuario',
      key: 'nombre_usuario',
      ...getColumnSearchProps({
        dataIndex: 'nombre_usuario',
        searchInput,
        handleSearch,
        handleReset,
        setSearchText,
        setSearchedColumn,
        searchText,
        searchedColumn,
      }),
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Software',
      dataIndex: 'nombre_componente',
      key: 'nombre_componente',
      ...getColumnSearchProps({
        dataIndex: 'nombre_componente',
        searchInput,
        handleSearch,
        handleReset,
        setSearchText,
        setSearchedColumn,
        searchText,
        searchedColumn,
      }),
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha_registro',
      key: 'fecha_registro',
      width: 140,
      defaultSortOrder: 'descend',
      sorter: (a, b) =>
        moment(a.fecha_registro).unix() - moment(b.fecha_registro).unix(),
      render: (_, record) => (
        <>
          {returnDateFormatClass.regresaFormatoFechaTimeZone(
            record.fecha_registro
          )}
        </>
      ),
    },
  ];

  return (
    <div
      style={{
        width: '98%',
        minWidth: 1050,
      }}
    >
      <Modal
        closable={false}
        destroyOnClose={true}
        open={isModalOpen}
        width={950}
        style={{ minWidth: 950, marginTop: -50 }}
        title={
          <div>
            <EditOutlined /> &nbsp; Revisar solicitud de Software
          </div>
        }
        footer={[
          <Button
            key={'btnRechazar'}
            type="primary"
            onClick={() => {
              HandleRechazarSolicitud();
            }}
            danger
          >
            <CloseCircleOutlined />
            Rechazar
          </Button>,
          <Button
            key={'btnAceptar'}
            type="primary"
            onClick={() => {
              HandleRevisarSolicitud();
            }}
          >
            <CheckCircleOutlined />
            Aceptar
          </Button>,
          <Button
            key={'btnCerrar'}
            onClick={() => {
              setformBeneficiario('');
              setformPuesto('');
              setinputJustificacion('');
              setisModalOpen(false);
            }}
          >
            <CloseOutlined />
            Cerrar
          </Button>,
        ]}
      >
        <Divider orientation="left" style={{ fontWeight: 'bold' }}>
          Datos del beneficiario
        </Divider>

        <Row
          gutter={16}
          style={{
            marginTop: 20,
            display: flagFolioVisible ? 'flex' : 'none',
            justifyContent: 'flex-end',
            marginLeft: -40,
            marginRight: 50,
          }}
        >
          <Col
            span={4}
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            Folio:
          </Col>
          <Col>
            <Input
              maxLength={60}
              style={{ width: 100 }}
              onChange={(e) => {
                setinputFolio(e.target.value);
              }}
              disabled
              value={inputFolio}
            />
          </Col>
        </Row>

        <Row
          gutter={16}
          style={{ marginTop: 20, display: divInternoVisible, marginLeft: -40 }}
        >
          <Col
            span={4}
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            Nombre:
          </Col>
          <Col>
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Seleccione una opción"
              options={optionUsuarios}
              value={formBeneficiario}
              onChange={(e, option) => {
                handleBeneficiarioChange(e, option);
              }}
              disabled={flagBloquearFormulario}
              filterOption={UtilsClass.filterOption}
              style={{ textAlign: 'left', width: 720 }}
            />
          </Col>
        </Row>

        <Row
          gutter={16}
          style={{ marginTop: 20, display: divInternoVisible, marginLeft: -40 }}
        >
          <Col
            span={4}
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            Puesto:
          </Col>
          <Col>
            <Select
              disabled
              style={{ textAlign: 'left', width: 320 }}
              options={optionPuestos}
              onChange={handleSelectChange}
              value={formPuesto}
            />
          </Col>

          <Col
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            Correo:
          </Col>
          <Col>
            <Input
              maxLength={60}
              style={{ width: 320 }}
              onChange={(e) => {
                setinputCorreoBeneficio(e.target.value);
              }}
              disabled
              value={inputCorreoBeneficio}
            />
          </Col>
        </Row>

        <Divider orientation="left" style={{ fontWeight: 'bold' }}>
          Datos del software
        </Divider>

        <Row
          gutter={16}
          style={{ marginTop: 20, display: divSistemas, marginLeft: -40 }}
        >
          <Col
            span={4}
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            Componente:
          </Col>
          <Col>
            <Select
              style={{ textAlign: 'left', width: 720 }}
              options={optionComponentes}
              disabled
              onChange={(e, val) => {
                setformComponente(e);
                if (val.nombre == 'OTRO') {
                  setdivExternoVisible('');
                } else {
                  setdivExternoVisible('none');
                }
              }}
              value={formComponente}
            />
          </Col>
        </Row>

        <Row
          gutter={16}
          style={{ marginTop: 20, display: divExternoVisible, marginLeft: -40 }}
        >
          <Col
            span={4}
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            Especifique:
          </Col>
          <Col>
            <Input
              maxLength={60}
              style={{ width: 720 }}
              onChange={(e) => {
                setinputEspecifique(e.target.value);
              }}
              value={inputEspecifique}
              disabled
            />
          </Col>
        </Row>

        <Row
          gutter={16}
          style={{ marginTop: 20, marginLeft: -40, marginBottom: 20 }}
        >
          <Col span={4} style={{ textAlign: 'right' }}>
            Justificación:
          </Col>
          <Col>
            <TextArea
              rows={3}
              value={inputJustificacion}
              onChange={(e) => {
                setinputJustificacion(e.target.value);
              }}
              style={{ width: 720 }}
              disabled
            />
          </Col>
        </Row>

        <Divider orientation="left" style={{ fontWeight: 'bold' }}>
          Complementar solicitud
        </Divider>

        <Row
          gutter={16}
          style={{ marginTop: 20, marginLeft: -40, marginBottom: 20 }}
        >
          <Col span={4} style={{ textAlign: 'right' }}>
            Importe Cotizado:
          </Col>
          <Col>
            <InputNumber
              style={{ textAlign: 'left', width: 720 }}
              value={inputImporte}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              onChange={(e) => {
                setinputImporte(e);
              }}
            />
          </Col>
        </Row>

        <Row
          gutter={16}
          style={{ marginTop: 20, marginLeft: -40, marginBottom: 20 }}
        >
          <Col span={4} style={{ textAlign: 'right' }}>
            Comentarios:
          </Col>
          <Col>
            <TextArea
              rows={3}
              value={inputComentarios}
              onChange={(e) => {
                setinputComentarios(e.target.value);
              }}
              style={{ width: 720 }}
            />
          </Col>
        </Row>
      </Modal>

      <div
        style={{
          fontSize: 25,
          fontWeight: 'bold',
          marginTop: 20,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {' '}
        Solicitudes de Software pendientes de revisar
      </div>
      <Table
        rowKey="id"
        columns={columnsSolicitudes}
        dataSource={DataSolicitudes}
        pagination={false}
        ref={tableRefSolicitudes}
        style={{ height: window.innerHeight - 300 }}
        scroll={{
          y: window.innerHeight - 300,
        }}
      />
    </div>
  );
}
