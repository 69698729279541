import { Tag } from 'antd';

const ReturnStatusTag = (status) => {
  let color = '';
  let text = '';
  switch (status) {
    case '0':
      color = '#FF7929';
      text = 'No asignado';
      break;
    case '1':
      color = '#FABE00';
      text = 'Asignado';
      break;
    case '2':
      color = '#0088ff';
      text = 'Trabajando';
      break;
    case '3':
      color = '#16d0a7';
      text = 'Terminado';
      break;
    case '4':
      color = '#00BD04';
      text = 'Finalizado';
      break;
    case '5':
      color = '#ff0000';
      text = 'Cancelado';
      break;
    case '6':
      color = '#a30078';
      text = 'Pendiente';
      break;
    default:
      color = '#ff00bb';
      text = 'Otro';
  }
  return (
    <Tag color={color} key={status} style={{ width: 105, textAlign: 'center' }}>
      {text.toUpperCase()}
    </Tag>
  );
};
export default ReturnStatusTag;
