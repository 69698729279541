import LocalData from '../persistencia/LocalData';
import AuthService from './auth.service';

const postRegistrarSolicitud = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}registra-solicitud`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postGuardaRechazoSolicitud = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}guarda-rechazo-solicitud`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postRegresaStatusSolicitud = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}regresa-status-solicitud`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getListSolicitudes = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}solicitudes`, {
    method: 'GET',
    headers: {
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postRegistrarCambioSolicitud = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}registra-cambio-solicitud`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postRegistrarBajaSolicitud = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}registra-baja-solicitud`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postListSolicitudesUsuario = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}solicitudes-usuario`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postListSolicitudesCorreo = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}solicitudes-correo`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postListSolicitudesHardware = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}hardware-software/solicitudes`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postRegistrarSolicitudHardware = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}hardware-software/registra-solicitud`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postRegistrarConfiguracionHS = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}hardware-software/registra-configuracion`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postListaConfiguracionHS = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}hardware-software/lista-configuracion`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getListaRevisionSolicitudes = (getParameter) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(
    `${URL}hardware-software/revision-solicitudes/${getParameter}`,
    {
      method: 'GET',
      headers: {
        Authorization: AuthService.getCurrentToken(),
      },
    }
  )
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postVerificaSolicitud = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}hardware-software/verifica-solicitud`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postBorrarConfiguracion = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}hardware-software/borrar-configuracion`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getListaRevisionSolicitudesToken = (getParameter, getParameterToken) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(
    `${URL}hardware-software/revision-solicitudes/${getParameter}/${getParameterToken}`,
    {
      method: 'GET',
      headers: {
        Authorization: AuthService.getCurrentToken(),
      },
    }
  )
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getDatosAutorizarSolicitudToken = (getParameter, getParameterToken) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(
    `${URL}hardware-software/solicitud-token/${getParameter}/${getParameterToken}`,
    {
      method: 'GET',
      headers: {
        Authorization: AuthService.getCurrentToken(),
      },
    }
  )
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postRegistraRespuestaSolicitud = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}hardware-software/guarda-respuesta`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postRechazarSolicitud = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}hardware-software/rechazar-solicitud`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const SolicitudesService = {
  postRegistrarSolicitud,
  postGuardaRechazoSolicitud,
  postRegresaStatusSolicitud,
  getListSolicitudes,
  postRegistrarCambioSolicitud,
  postRegistrarBajaSolicitud,
  postListSolicitudesUsuario,
  postListSolicitudesHardware,
  postRegistrarSolicitudHardware,
  postRegistrarConfiguracionHS,
  postListaConfiguracionHS,
  getListaRevisionSolicitudes,
  postVerificaSolicitud,
  getListaRevisionSolicitudesToken,
  getDatosAutorizarSolicitudToken,
  postRegistraRespuestaSolicitud,
  postRechazarSolicitud,
  postBorrarConfiguracion,
  postListSolicitudesCorreo,
};

export default SolicitudesService;
