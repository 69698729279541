import LocalData from "../persistencia/LocalData";
import AuthService from "./auth.service";
const getNotifications = () => {
  //console.log("currentToken",AuthService.getCurrentToken());
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}notifications`, {
    method: "GET",
    headers: {
      "Authorization": AuthService.getCurrentToken()
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":500};
        }
        
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postUpdateNotifications = (json) => {

  //console.log("currentToken",AuthService.getCurrentToken());

  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}notifications-update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": AuthService.getCurrentToken()
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":500};
        }
        
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};


const postSendNotificacionMail = (json) => {

  //console.log("currentToken",AuthService.getCurrentToken());

  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}enviar-correo-usuario-detalleticket`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": AuthService.getCurrentToken()
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":500};
        }
        
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getNotificationsActive = () => {
  //console.log("currentToken",AuthService.getCurrentToken());
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}notifications-active`, {
    method: "GET",
    headers: {
      "Authorization": AuthService.getCurrentToken()
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if(responseData.status==403){
          return {"estado":403};
        }
        else{
          return {"estado":500};
        }
        
      }
      else{
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};


const NotificationsService = {
  getNotifications,
  postUpdateNotifications,
  postSendNotificacionMail,
  getNotificationsActive
};

export default NotificationsService;
