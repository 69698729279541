import React, { useState, useEffect, useContext } from 'react';
import AuthService from '../services/auth.service';
import { useNavigate } from 'react-router-dom';
import ReturnUserTypeClass from '../clases/returnUserType.class';

import TicketsService from '../services/tickets.Service';

import { Card, Typography, Statistic, Divider, List } from 'antd';
import {
  ContainerOutlined,
  CommentOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import Structure from '../componentes/StructureUser';
import StructureSoporte from '../componentes/StructureAdmin';

import ListaTicketsMonitor from '../pages/soporte/ListaTicketsMonitor';

import { ComponentesContext } from '../contextos/componentesContext';
import LoadingPage from '../componentes/LoadingPage';
import RegresaTiempoTranscurrido from '../componentes/ReturnElapsedTime';

import returnDateFormatClass from '../clases/returnDateFormat.class';

const { Meta } = Card;

const Dashboard = () => {
  const { divContentWidth } = useContext(ComponentesContext);

  const navigate = useNavigate();

  const [Page_content, setPage_content] = useState(<LoadingPage></LoadingPage>);

  const ReturnIcon = (status) => {
    switch (status) {
      case 0:
        return (
          <ContainerOutlined style={{ fontSize: '16px', color: '#f58b00' }} />
        );
      case 1:
        return (
          <CommentOutlined style={{ fontSize: '16px', color: '#f58b00' }} />
        );
      case 2:
        return (
          <ClockCircleOutlined style={{ fontSize: '16px', color: '#0044ff' }} />
        );

      case 3:
      case 4:
        return (
          <CheckCircleOutlined style={{ fontSize: '16px', color: '#00d61d' }} />
        );
      case 5:
        return <CloseCircleOutlined />;
    }
  };

  const ReturnUserStructure = (totales, actividades) => {
    return (
      <Structure breadcrumb={''} menuOption={'1'}>
        <div>
          <center>
            <Typography.Title level={1} style={{ margin: 0, color: '#081b3e' }}>
              Bienvenido
              <Divider />
            </Typography.Title>
          </center>

          <div
            style={{
              minHeight: 100,
              maxHeight: maxDivHeight - 210,
              overflow: 'auto',
              paddingLeft: 20,
              paddingRight: 20,
              marginBottom: -20,
              marginTop: -10,
              minWidth: 980,
              width: divContentWidth - 20,
            }}
          >
            <div style={{ width: '100%', marginBottom: 20 }}>
              <center>
                <table width={'70%'} aria-hidden="true">
                  <tbody>
                    <tr>
                      <td style={{ textAlign: 'center' }}>
                        <Card
                          bordered={false}
                          style={{ backgroundColor: '#f2f2f2' }}
                        >
                          <Statistic
                            title={
                              <Typography.Title
                                level={5}
                                style={{ margin: 0, color: '#081b3e' }}
                              >
                                Tickets Pendientes
                                <Divider />
                              </Typography.Title>
                            }
                            value={totales.pendientes}
                          />
                        </Card>
                      </td>
                      <td>&nbsp;</td>
                      <td style={{ textAlign: 'center' }}>
                        <Card
                          bordered={false}
                          style={{ backgroundColor: '#f2f2f2' }}
                        >
                          <Statistic
                            title={
                              <Typography.Title
                                level={5}
                                style={{ margin: 0, color: '#081b3e' }}
                              >
                                Tickets Finalizados
                                <Divider />
                              </Typography.Title>
                            }
                            value={totales.finalizados}
                          />
                        </Card>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </center>
            </div>
            <center>
              <Typography.Title
                level={4}
                style={{ margin: 0, color: '#081b3e' }}
              >
                Ultimas actividades
              </Typography.Title>

              <List
                style={{ width: '80%' }}
                bordered
                itemLayout="horizontal"
                dataSource={actividades}
                renderItem={(item, index) => (
                  <List.Item>
                    <List.Item.Meta
                      style={{ textAlign: 'left', paddingLeft: 60 }}
                      description={
                        <>
                          {ReturnIcon(item.status)} {item.description}, &nbsp;
                          Fecha:{' '}
                          {returnDateFormatClass.regresaFormatoFechaTimeZone(
                            item.fecha
                          )}
                          , &nbsp; Transcurrido:{' '}
                          {RegresaTiempoTranscurrido(item.fecha)}, &nbsp;
                          Ticket: #{item.id_ticket}
                        </>
                      }
                    />
                  </List.Item>
                )}
              />
            </center>
          </div>
        </div>
      </Structure>
    );
  };

  const ReturnSuportStructure = (totales, actividades) => {
    return (
      <StructureSoporte breadcrumb={''} menuOption={'1'}>
        <center>
          <Typography.Title level={2} style={{ margin: 0, color: '#081b3e' }}>
            SOPORTE
            <Divider />
          </Typography.Title>
        </center>

        <div
          style={{
            minHeight: 100,
            maxHeight: maxDivHeight - 210,
            overflow: 'auto',
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: -20,
            marginTop: -10,
            minWidth: 980,
            width: divContentWidth - 20,
          }}
        >
          <div style={{ width: '100%', marginBottom: 20 }}>
            <center>
              <table aria-hidden="true" width={'90%'}>
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'center', width: '30' }}>
                      <Card
                        bordered={false}
                        style={{ backgroundColor: '#f2f2f2' }}
                        bodyStyle={{ height: 145 }}
                      >
                        <Statistic
                          title={
                            <Typography.Title
                              level={5}
                              style={{ margin: 0, color: '#081b3e' }}
                            >
                              Tickets Pendientes
                              <Divider />
                            </Typography.Title>
                          }
                          value={totales.pendientes}
                        />
                      </Card>
                    </td>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'center', width: '30' }}>
                      <Card
                        bordered={false}
                        style={{ backgroundColor: '#f2f2f2' }}
                        bodyStyle={{ height: 145 }}
                      >
                        <Statistic
                          title={
                            <Typography.Title
                              level={5}
                              style={{ margin: 0, color: '#081b3e' }}
                            >
                              Tickets Asignados
                              <Divider />
                            </Typography.Title>
                          }
                          value={totales.asignados}
                        />
                      </Card>
                    </td>
                    <td>&nbsp;</td>
                    <td style={{ textAlign: 'center', width: '30' }}>
                      <Card
                        bordered={false}
                        style={{ backgroundColor: '#f2f2f2' }}
                        bodyStyle={{ height: 145 }}
                      >
                        <Statistic
                          title={
                            <Typography.Title
                              level={5}
                              style={{ margin: 0, color: '#081b3e' }}
                            >
                              Tickets Sin Asignar
                              <Divider />
                            </Typography.Title>
                          }
                          value={totales.sinasignar}
                        />
                      </Card>
                    </td>
                  </tr>
                </tbody>
              </table>
            </center>
          </div>

          <center>
            <Typography.Title level={4} style={{ margin: 0, color: '#081b3e' }}>
              Ultimas actividades
            </Typography.Title>

            <List
              style={{ width: '80%' }}
              bordered
              itemLayout="horizontal"
              dataSource={actividades}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta
                    style={{ textAlign: 'left', paddingLeft: 60 }}
                    description={
                      <>
                        {ReturnIcon(item.status)} {item.description}, &nbsp;
                        Fecha:{' '}
                        {returnDateFormatClass.regresaFormatoFechaTimeZone(
                          item.fecha
                        )}
                        , &nbsp; Transcurrido:{' '}
                        {RegresaTiempoTranscurrido(item.fecha)}, &nbsp; Ticket:
                        #{item.id_ticket}
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </center>
        </div>
      </StructureSoporte>
    );
  };

  const ReturnMonitorStructure = (totales, actividades) => {
    return <ListaTicketsMonitor></ListaTicketsMonitor>;
  };

  const ReturnStructure = async () => {
    let tipoUsuario = await ReturnUserTypeClass.ReturnUserType();

    if (tipoUsuario.estado == 403) {
      AuthService.logout();
      navigate('/login');
      return false;
    }

    if (tipoUsuario.estado == 500) {
      AuthService.logout();
      navigate('/login');
      return false;
    }

    if (tipoUsuario.data.tipo == '1') {
      let totales = await TicketsService.getTotalesUsuario();
      let totalesCard = {
        pendientes: totales.data[0].pendientes,
        finalizados: totales.data[0].finalizados,
      };

      let actividades = await TicketsService.getActividadesUsuario();

      setPage_content(ReturnUserStructure(totalesCard, actividades.data));
    } else if (tipoUsuario.data.tipo == '2') {
      let totales = await TicketsService.getTotalesSoporte();
      let totalesCard = {
        pendientes: totales.data[0].pendientes,
        asignados: totales.data[0].asignados,
        sinasignar: totales.data[0].sinasignar,
      };

      let actividades = await TicketsService.getActividadesSoporte();
      setPage_content(ReturnSuportStructure(totalesCard, actividades.data));
    } else {
      let totales = await TicketsService.getTotalesSoporte();
      let totalesCard = {
        pendientes: totales.data[0].pendientes,
        asignados: totales.data[0].asignados,
        sinasignar: totales.data[0].sinasignar,
      };

      let actividades = await TicketsService.getActividadesSoporte();
      setPage_content(ReturnMonitorStructure(totalesCard, actividades.data));
    }
  };

  const [maxDivHeight, setmaxDivHeight] = useState(300);

  useEffect(() => {
    setmaxDivHeight(window.innerHeight);

    const handleResize = () => {
      setmaxDivHeight(window.innerHeight);
      console.log(maxDivHeight);
    };

    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate('/login');
    }

    window.addEventListener('resize', handleResize);

    ReturnStructure();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [maxDivHeight]);

  return Page_content;
};

export default Dashboard;
