import React, { useState, useEffect, useContext, useRef } from 'react';
import AuthService from '../../services/auth.service';
import { useNavigate } from 'react-router-dom';
import './DashBoard.css';
import {
  Card,
  Typography,
  Table,
  Divider,
  Skeleton,
  Button,
  Modal,
  Modal as ModalPreview,
  Spin,
  Upload,
  Row,
  Col,
  ConfigProvider,
  Empty,
} from 'antd';
import {
  BellOutlined,
  FolderViewOutlined,
  ClockCircleOutlined,
  FileImageOutlined,
} from '@ant-design/icons';
import Structure from '../../componentes/StructureAdmin';
import { ComponentesContext } from '../../contextos/componentesContext';
import moment from 'moment';
import TicketsService from '../../services/tickets.Service';
import RegresaTiempoTranscurrido from '../../componentes/ReturnElapsedTime';
import ReturnStatusTag from '../../componentes/ReturnStatusTagObject';
import getColumnSearchProps from '../../componentes/SearchPropsCustom';
import { Editor } from '@tinymce/tinymce-react';

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const { Meta } = Card;

import returnDateFormatClass from '../../clases/returnDateFormat.class';
import returnNotificationsClass from '../../clases/returnNotifications.class';

const ListaTickets = () => {
  const {
    DataLista,
    UpdateDataLista,
    tableHeight,
    divContentWidth,
    ModalMessage,
  } = useContext(ComponentesContext);

  const editorRef = useRef(null);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [loadingModal, setloadingModal] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [isModalEvidenciasOpen, setisModalEvidenciasOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const [IdTicket, setIdTicket] = useState(null);

  const searchInput = useRef(null);

  const HandlePrueba = (id_ticket) => {
    navigate('/detalle-ticket-soporte', {
      state: {
        record: {
          id_ticket: id_ticket,
        },
      },
    });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'No. Ticket',
      dataIndex: 'ticket',
      key: 'ticket',
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.ticket - b.ticket,
      render: (_, record, status) =>
        record.status == '6' || record.status == '5' ? (
          <></>
        ) : (
          <Button
            style={{
              height: 40,
              width: 95,
            }}
            className="button"
            onClick={() => {
              HandlePrueba(record.ticket);
            }}
          >
            <span>{record.ticket} </span>
          </Button>
        ),
    },
    {
      title: 'Solicita',
      dataIndex: 'usuario_ticket',
      key: 'usuario_ticket',
      ...getColumnSearchProps({
        dataIndex: 'usuario_ticket',
        searchInput,
        handleSearch,
        handleReset,
        setSearchText,
        setSearchedColumn,
        searchText,
        searchedColumn,
      }),
    },
    {
      title: 'Titulo',
      dataIndex: 'titulo',
      key: 'titulo',
    },
    {
      title: 'Clasificación',
      dataIndex: 'clasificacion',
      key: 'clasificacion',
    },
    {
      title: 'Estatus',
      key: 'status',
      dataIndex: 'status',
      filters: [
        {
          text: 'No asignado',
          value: '0',
        },
        {
          text: 'Asignado',
          value: '1',
        },
        {
          text: 'Trabajando',
          value: '2',
        },
        {
          text: 'Terminado',
          value: '3',
        },
        {
          text: 'Finalizado',
          value: '4',
        },
        {
          text: 'Cancelado',
          value: '5',
        },
      ],
      onFilter: (value, record) => record.status.startsWith(value),
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.status - b.status,
      render: (_, record) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            alignContent: 'center',
          }}
        >
          {ReturnStatusTag(record.status)}
        </div>
      ),
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      sorter: (a, b) => moment(a.fecha).unix() - moment(b.fecha).unix(),
      render: (_, record) => (
        <>{returnDateFormatClass.regresaFormatoFechaTimeZone(record.fecha)}</>
      ),
    },

    {
      title: <ClockCircleOutlined />,
      dataIndex: 'fecha',
      key: 'fecha',
      width: 80,
      render: (_, record) => <>{RegresaTiempoTranscurrido(record.fecha)}</>,
    },
    {
      title: 'Usuario asignado',
      dataIndex: 'usuario_asignado',
      key: 'usuario_asignado',
    },
    {
      title: <BellOutlined />,
      width: 60,
      align: 'center',
      dataIndex: 'notificaciones',
      key: 'notificaciones',
      render: (_, record) => (
        <>
          {returnNotificationsClass.returnNotificationCount(
            record.notificaciones
          )}
        </>
      ),
    },
    {
      title: <FolderViewOutlined />,
      width: 55,
      key: 'flag_evidencias',
      dataIndex: 'flag_evidencias',
      filters: [
        {
          text: 'Sin evidencias',
          value: '0',
        },
        {
          text: 'Con evidencias',
          value: '1',
        },
      ],
      onFilter: (value, record) => record.flag_evidencias.startsWith(value),
      defaultSortOrder: 'descend',
      render: (_, record) => (
        <div
          style={{
            display: record.flag_evidencias === '1' ? 'flex' : 'none',
            justifyContent: 'center',
            textAlign: 'center',
            alignContent: 'center',
          }}
        >
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorPrimary: '#3c5c7c',
                  colorPrimaryHover: '#517da9',
                  colorPrimaryActive: '#517da9',
                },
                Card: {
                  headerBg: '#3c5c7c',
                },
              },
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                HandleVerEvidencias(record);
              }}
              icon={<FolderViewOutlined />}
            ></Button>
          </ConfigProvider>
        </div>
      ),
    },
  ];

  const getTicketsData = async () => {
    let ticketsData = await TicketsService.getTicketsSoporte();
    UpdateDataLista(ticketsData.data);
  };

  const HandleVerEvidencias = async (record) => {
    setloadingModal(true);

    console.log(record.ticket);
    setIdTicket(record.ticket);

    let respuesta = await TicketsService.getEvidencias(record.ticket);

    if (respuesta.estado === 201) {
      setloadingModal(false);
      return false;
    }

    setisModalEvidenciasOpen(true);
    setTimeout(function () {
      const evidencias = JSON.parse(respuesta.data);

      setFileList(evidencias.adjuntos);
      setloadingModal(false);
      editorRef.current.setContent(evidencias.comentarios);
    }, 1000);
  };

  const handlePreview = async (file) => {
    console.log(file);

    const fileExtension = file.nombre_archivo.split('.').pop();

    switch (fileExtension.toLowerCase()) {
      case 'apng':
      case 'avif':
      case 'gif':
      case 'jpg':
      case 'jpeg':
      case 'jfif':
      case 'pjpeg':
      case 'pjp':
      case 'png':
      case 'svg':
      case 'webp':
      case 'bmp':
      case 'ico':
      case 'cur':
      case 'tif':
      case 'tiff':
        file.preview = file.url;
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        break;
    }
  };

  const handeDownload = async (file) => {
    const urlDownload = `${process.env.REACT_APP_URL_API_SOPORTE}download?ticket=${encodeURIComponent(IdTicket)}&filename=${encodeURIComponent(file.nombre_archivo)}&token=${encodeURIComponent(AuthService.getCurrentToken())}`;
    const response = await fetch(urlDownload);
    if (!response.ok) {
      ModalMessage('Mensaje', 'Se produjo un error al descargar el archivo.');
      return false;
    }
    window.location.href = urlDownload;
  };

  useEffect(() => {
    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate('/login');
    }

    getTicketsData();
    setLoading(false);
  }, []);

  return (
    <Structure menuOption={'2'}>
      <Skeleton loading={loading} active={true}>
        <center>
          <Typography.Title level={1} style={{ margin: 0, color: '#081b3e' }}>
            Lista de Tickets
            <Divider />
          </Typography.Title>
        </center>

        <center>
          <Table
            style={{ width: divContentWidth, minWidth: 1000, marginTop: -10 }}
            size="middle"
            columns={columns}
            dataSource={DataLista}
            pagination={false}
            scroll={{
              y: tableHeight + 100,
            }}
          />
        </center>

        <Modal
          open={isModalEvidenciasOpen}
          title={
            <div>
              <FolderViewOutlined /> &nbsp; Evidencias
            </div>
          }
          footer={[
            <Button
              key={'btnOkModalEvidencia'}
              type="primary"
              onClick={() => {
                setisModalEvidenciasOpen(false);
              }}
              danger
            >
              Cerrar
            </Button>,
          ]}
          closable={false}
          width={780}
          style={{ marginTop: -40 }}
        >
          <Spin spinning={loadingModal} active={true}>
            <div
              style={{
                width: '100%',
                height: 'auto',

                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <Row style={{ marginTop: 10, marginLeft: 10 }}>
                <Col>
                  <div style={{ fontSize: 16 }}>
                    Comentarios de la evidencia:
                  </div>
                </Col>
              </Row>
              <Editor
                disabled
                tinymceScriptSrc={
                  process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'
                }
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue="<p></p>"
                init={{
                  height: 150,
                  resize: false,
                  language: 'es_MX',
                  language_url:
                    process.env.PUBLIC_URL + '/tinymce/langs/es_MX.js',
                  menubar: false,
                  plugins: ['lists'],
                  toolbar: '',
                  content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                }}
              />
            </div>

            <div style={{ fontSize: 16, marginTop: 20, marginLeft: 10 }}>
              Imagenes:
            </div>
            <div
              style={{
                backgroundColor: '#f5f5f5',
                borderRadius: 10,
                marginBottom: 20,
              }}
            >
              <Upload
                disabled
                name="archivo"
                listType="picture-card"
                fileList={fileList}
                showUploadList={{
                  showRemoveIcon: false,
                  showDownloadIcon: true,
                }}
                onPreview={handlePreview}
                onDownload={handeDownload}
              >
                {fileList.length == 0 ? (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={false}
                  />
                ) : null}
              </Upload>

              <ModalPreview
                open={previewOpen}
                title={
                  <div>
                    <FileImageOutlined /> &nbsp; Vista previa de imagen
                  </div>
                }
                footer={[
                  <Button
                    key={'btnOkModalPreview'}
                    type="primary"
                    onClick={() => {
                      setPreviewOpen(false);
                    }}
                  >
                    OK
                  </Button>,
                ]}
                closable={false}
                width={divContentWidth - 150}
                style={{ marginTop: -40 }}
              >
                <div
                  style={{
                    width: '100%',
                    height: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <TransformWrapper>
                    <TransformComponent>
                      <img
                        alt="example"
                        style={{
                          width: '100%',
                        }}
                        src={previewImage}
                      />
                    </TransformComponent>
                  </TransformWrapper>
                </div>
              </ModalPreview>
            </div>
          </Spin>
        </Modal>
      </Skeleton>
    </Structure>
  );
};
export default ListaTickets;
