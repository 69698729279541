import LocalData from '../persistencia/LocalData';
import AuthService from '../services/auth.service';
const getTickets = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}tickets`, {
    method: 'GET',
    headers: {
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getTicketsSoporte = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}tickets-soporte`, {
    method: 'GET',
    headers: {
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getDetalleTickets = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}regresa-ticket`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postCreateTicket = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}registra-ticket`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      //console.log("response",responseData);
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postCreateDetailTicket = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}registra-detalle-ticket`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      //console.log("response",responseData);
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postGetTicketsMessage = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}regresa-mensajes-ticket`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      //console.log("response",responseData);
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postDeleteFile = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}delete-file`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      //console.log("response",responseData);
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postDeleteTemporal = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}delete-temporales`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      //console.log("response",responseData);
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getDescargaAdjunto = (getUrl, getFile) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}download/${getUrl}/${getUrl}`, {
    method: 'GET',
    headers: {
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postDeleteFileDetailTicket = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}delete-file-detailticket`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      //console.log("response",responseData);
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getResetAdjuntos = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}reset-attachments`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      //console.log("response",responseData);
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getResetTemporales = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}reset-temporales`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      //console.log("response",responseData);
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postAdjuntosTicket = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}regresa-adjuntos-ticket`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postRegresaTemporales = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}regresa-temporales`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getUserType = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}tipo-usuario`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      //console.log("response",responseData);
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getTotalesSoporte = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}totales-soporte`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      //console.log("response",responseData);
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getActividadesSoporte = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}actividades-soporte`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      //console.log("response",responseData);
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getTotalesUsuario = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}totales-usuario`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      //console.log("response",responseData);
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getActividadesUsuario = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}actividades-usuario`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      //console.log("response",responseData);
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getClasificacionTicket = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}clasificacion-tickets`, {
    method: 'GET',
    headers: {
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getPlazasTicket = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}plazas-tickets`, {
    method: 'GET',
    headers: {
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getEmpresasTicket = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}empresas-tickets`, {
    method: 'GET',
    headers: {
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getDatosUsuarios = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}usuarios-data`, {
    method: 'GET',
    headers: {
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getListaDatosConocimientos = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}conocimientos-lista-data`, {
    method: 'GET',
    headers: {
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getListaDatosUsuarios = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}usuarios-tickets`, {
    method: 'GET',
    headers: {
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postGuardarEvidencia = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}tickets/guarda-evidencias`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      //console.log("response",responseData);
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const getEvidencias = (getParameter) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}tickets/evidencias/${getParameter}`, {
    method: 'GET',
    headers: {
      Authorization: AuthService.getCurrentToken(),
    },
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const postBorrarEvidencia = (json) => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const resp = fetch(`${URL}tickets/borrar-evidencias`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: AuthService.getCurrentToken(),
    },
    body: JSON.stringify(json),
  })
    .then((response) => response.json())
    .then((responseData) => {
      //console.log("response",responseData);
      if (typeof responseData.estado == 'undefined') {
        if (responseData.status == 403) {
          return { estado: 403 };
        } else {
          return { estado: 500 };
        }
      } else {
        return responseData;
      }
    })
    .catch((err) => {
      return { estado: 500 };
    });
  return resp;
};

const TicketsService = {
  getTickets,
  postCreateTicket,
  getDetalleTickets,
  getResetAdjuntos,
  postDeleteFile,
  postAdjuntosTicket,
  postCreateDetailTicket,
  postGetTicketsMessage,
  getTicketsSoporte,
  getUserType,
  getTotalesSoporte,
  getActividadesSoporte,
  getTotalesUsuario,
  getActividadesUsuario,
  postDeleteFileDetailTicket,
  getClasificacionTicket,
  getPlazasTicket,
  getEmpresasTicket,
  getDatosUsuarios,
  getListaDatosConocimientos,
  getListaDatosUsuarios,
  postRegresaTemporales,
  getResetTemporales,
  postDeleteTemporal,
  postGuardarEvidencia,
  getEvidencias,
  postBorrarEvidencia,
};

export default TicketsService;
