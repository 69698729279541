import React, { useState, useEffect, useContext, useRef } from 'react';
import AuthService from '../../services/auth.service';
import NotificationsService from '../../services/nofications.Service';
import TicketsService from '../../services/tickets.Service';
import { useNavigate, useLocation } from 'react-router-dom';
import './DashBoard.css';
import ModalMessageCustom from '../../componentes/ModalMessageCustom';
import ModalDocumentViewer from '../../componentes/ModalDocumentViewer';
import ReturnStatusTag from '../../componentes/ReturnStatusTag';
import ReturnUserTypeClass from '../../clases/returnUserType.class';
import returnValidUploadFileClass from '../../clases/returnValidUploadFile.class';
import returnDateFormatClass from '../../clases/returnDateFormat.class';
import returnCardsClass from '../../clases/returnCards.class';

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import axios from 'axios';

import {
  Card,
  Button,
  Upload,
  Typography,
  Tag,
  Avatar,
  Modal,
  Timeline,
  Modal as ModalAttachement,
  Modal as ModalPreview,
  Rate,
  Divider,
  Skeleton,
  Spin,
  ConfigProvider,
  Empty,
} from 'antd';
import {
  FileImageOutlined,
  ContainerOutlined,
  PaperClipOutlined,
  PlusOutlined,
  CloseOutlined,
  CommentOutlined,
  CheckOutlined,
  UserOutlined,
  RobotOutlined,
} from '@ant-design/icons';
import Structure from '../../componentes/StructureUser';
import { ComponentesContext } from '../../contextos/componentesContext';
import moment from 'moment';

import { Editor } from '@tinymce/tinymce-react';

const { Meta } = Card;

const DetalleTicket = () => {
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const {
    openNotification,
    UpdateNotificationsCounter,
    messageList,
    UpdatemessageList,
    ModalMessage,
    divContentWidth,
    ShowLoading,
    tableHeight,
  } = useContext(ComponentesContext);
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const [loadingModal, setloadingModal] = useState(false);

  const [canRemove, setcanRemove] = useState(true);

  const [color, setcolor] = useState('');
  const [text, settext] = useState('');
  const [folio, setfolio] = useState('');
  const [fecha, setfecha] = useState('');
  const [status_ticket, setstatus_ticket] = useState('');
  const [nombreCompleto, setnombreCompleto] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalAttachementOpen, setisModalAttachementOpen] = useState(false);
  const [modalTitle, setmodalTitle] = useState('');
  const [detalleTicket, setdetalleTicket] = useState('');
  const [TituloTicket, setTituloTicket] = useState('');
  const IdTicket =
    typeof location.state.record.id_ticket != 'undefined' &&
    location.state.record.id_ticket != null
      ? location.state.record.id_ticket
      : '';

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [ModelAttachementType, setModelAttachementType] = useState(0);
  const editorRef = useRef(null);
  const [isResponseModalOpen, setisResponseModalOpen] = useState(false);
  const [OpenModelDocument, setOpenModelDocument] = useState(false);
  const [pathDocument, setpathDocument] = useState([]);

  const [finalizarButtonHidden, setfinalizarButtonHidden] = useState('none');
  const [attachementButtonHidden, setattachementButtonHidden] = useState('');
  const [answerButtonHidden, setanswerButtonHidden] = useState('');
  const [finishButtonHidden, setfinishButtonHidden] = useState('none');
  const [canAnswerButtonHidden, setcanAnswerButtonHidden] = useState('');
  const [answerModalTitle, setanswerModalTitle] = useState('Responder Ticket');
  const [rateTicketValue, setrateTicketValue] = useState(0);

  const [flagAdjuntarModal, setflagAdjuntarModal] = useState(false);

  const [disableButton, setdisableButton] = useState(false);

  const getDetalleTicketsData = async (id_ticket) => {
    const ticket = {
      id_ticket: id_ticket,
    };

    let notificationsData =
      await NotificationsService.postUpdateNotifications(ticket);
    UpdateNotificationsCounter(notificationsData.data.length);

    let ticketsData = await TicketsService.getDetalleTickets(ticket);

    setstatus_ticket(ticketsData.data[0].status);
    let tag = ReturnStatusTag(ticketsData.data[0].status);
    setcolor(tag.color);
    settext(tag.text);
    setTituloTicket(ticketsData.data[0].titulo);
    setfolio(ticketsData.data[0].ticket);
    setfecha(
      returnDateFormatClass.regresaFormatoFechaTimeZone(
        ticketsData.data[0].fecha
      )
    );
    setnombreCompleto(ticketsData.data[0].nombre_completo);
    setdetalleTicket(ticketsData.data[0].descripcion);

    if (ticketsData.data[0].status == '3') {
      setfinalizarButtonHidden('');
    } else {
      setfinalizarButtonHidden('none');

      if (ticketsData.data[0].status == '4') {
        setcanAnswerButtonHidden('none');
      } else {
        setcanAnswerButtonHidden('');
      }
    }
  };

  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    setUser(usr);

    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate('/login');
    }

    getDetalleTicketsData(IdTicket);
    handleGetTicketsDetail();
    moment.locale('es-mx');

    setTimeout(function () {
      setLoading(false);
    }, 400);
  }, []);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const ShowModalDocument = () => {
    setmodalTitle('Datos del ticket');
    setIsModalOpen(true);
  };

  const OpenResponseModal = () => {
    setattachementButtonHidden('');
    setanswerButtonHidden('');
    setfinishButtonHidden('none');
    setanswerModalTitle('Responder Ticket');
    setrateTicketValue(0);
    setcanRemove(true);
    setisResponseModalOpen(true);
  };

  const OpenFinishModal = () => {
    setattachementButtonHidden('none');
    setanswerButtonHidden('none');
    setfinishButtonHidden('');
    setanswerModalTitle('Finalizar Ticket');
    setrateTicketValue(0);
    setisResponseModalOpen(true);
  };

  const handleModalResponseClose = () => {
    setflagAdjuntarModal(false);
    setisResponseModalOpen(false);
  };

  const handleSaveDetail = async () => {
    ShowLoading(true);

    let tipo_usuario = await ReturnUserTypeClass.ReturnUserType();

    if (editorRef.current.getContent() == '') {
      ModalMessage(
        'Mensaje',
        'La respuesta del ticket no puede estar en blanco.'
      );
      setdisableButton(false);
      ShowLoading(false);
      return false;
    }

    const ticket = {
      id_ticket: IdTicket,
      mensaje: editorRef.current.getContent(),
      tipo_usuario: tipo_usuario.data.tipo,
      status: 1,
      status_ticket: status_ticket,
      rate: 0,
      id_base_conocimiento: '0',
    };

    let ticketsData = await TicketsService.postCreateDetailTicket(ticket);

    if (ticketsData.estado != 200) {
      ModalMessage('Mensaje', 'Error al registrar la respuesta.');
      return false;
    }

    editorRef.current.setContent('');

    handleGetTicketsDetail();
    setisResponseModalOpen(false);

    openNotification('Se registro la respuesta del ticket', 'top');

    setdisableButton(false);
    ShowLoading(false);
  };

  const handleFinishTicket = async () => {
    if (editorRef.current.getContent() == '') {
      ModalMessage(
        'Mensaje',
        'Para finalizar el ticket debes capturar un mensaje.'
      );
      return false;
    }

    const ticket = {
      id_ticket: IdTicket,
      mensaje: editorRef.current.getContent(),
      tipo_usuario: user.tipo,
      status: 4,
      status_ticket: status_ticket,
      rate: rateTicketValue,
    };

    let ticketsData = await TicketsService.postCreateDetailTicket(ticket);

    if (ticketsData.estado != 200) {
      ModalMessage('Mensaje', 'Error al finalizar el ticket.');
      return false;
    }

    handleGetTicketsDetail();
    setisResponseModalOpen(false);
    editorRef.current.setContent('');
    setcolor('#00d61d');
    settext('FINALIZADO');
    setcanAnswerButtonHidden('none');
    setfinalizarButtonHidden('none');
    openNotification('Ticket finalizado correctamente.', 'top');
  };

  const handleModalAttachementOpen = async (id_ticket_detalle, tipo) => {
    let AdjuntosData = await TicketsService.postAdjuntosTicket({
      id_ticket: IdTicket,
      tipo: tipo,
      id_ticket_detalle: id_ticket_detalle,
      id_base_conocimiento: 0,
    });
    setFileList(AdjuntosData.data);
    setloadingModal(false);
    setcanRemove(false);
    setModelAttachementType(0);
    setisModalAttachementOpen(true);
  };

  const handleModalResponseAttachementOpen = async () => {
    let DetalleAdjuntosData = await TicketsService.postRegresaTemporales({
      id_ticket: IdTicket,
      tipo: 1,
      id_ticket_detalle: 0,
      id_base_conocimiento: 0,
    });

    setFileList(DetalleAdjuntosData.data);
    setcanRemove(true);
    setModelAttachementType(1);
    setisModalAttachementOpen(true);
    setloadingModal(false);
  };

  const handleModalAttachementClose = () => {
    setisModalAttachementOpen(false);
  };

  const handlePreview = async (file) => {
    const fileExtension = file.nombre_archivo.split('.').pop();

    switch (fileExtension.toLowerCase()) {
      case 'apng':
      case 'avif':
      case 'gif':
      case 'jpg':
      case 'jpeg':
      case 'jfif':
      case 'pjpeg':
      case 'pjp':
      case 'png':
      case 'svg':
      case 'webp':
      case 'bmp':
      case 'ico':
      case 'cur':
      case 'tif':
      case 'tiff':
        file.preview = file.url;
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        break;
      case 'pdf':
        setpathDocument(file.url);
        setOpenModelDocument(true);
        break;
    }
  };

  const handleModalDocument = () => {
    setOpenModelDocument(false);
  };

  const handeDownload = async (file) => {
    const urlDownload = `${process.env.REACT_APP_URL_API_SOPORTE}download?ticket=${encodeURIComponent(IdTicket)}&filename=${encodeURIComponent(file.name)}&token=${encodeURIComponent(AuthService.getCurrentToken())}`;
    const response = await fetch(urlDownload);
    if (!response.ok) {
      ModalMessage('Mensaje', 'Se produjo un error al descargar el archivo.');
      return false;
    }
    window.location.href = urlDownload;

    // file.preview = file.url;
    // const response = await axios({
    //   url: file.url,
    //   method: 'GET',
    //   responseType: 'blob', // indicar que esperamos una respuesta de tipo 'blob'
    // });
    // const url = window.URL.createObjectURL(new Blob([response.data]));
    // const link = document.createElement('a');
    // link.href = url;
    // link.download = file.name;
    // document.body.appendChild(link);
    // link.click();
    // link.parentNode.removeChild(link);
    // window.URL.revokeObjectURL(url);
  };

  const handleRemove = async (file) => {
    setloadingModal(true);
    let ticketsData = await TicketsService.postDeleteTemporal({
      nombre_archivo: file.nombre_archivo,
    });
    if (ticketsData.estado == 200) {
      let DetalleAdjuntosData = await TicketsService.postRegresaTemporales({
        id_ticket: IdTicket,
        tipo: 1,
        id_ticket_detalle: 0,
        id_base_conocimiento: 0,
      });

      if (DetalleAdjuntosData.estado == 200) {
        setFileList(DetalleAdjuntosData.data);
      }
    }
    setloadingModal(false);
  };

  const handleBeforeUpload = async (file) => {
    let flagArchivoValido =
      await returnValidUploadFileClass.returnValidUploadFile(file.type);

    if (!flagArchivoValido) {
      ModalMessage(
        'Mensaje',
        'El tipo de archivo que se desea adjuntar no es valido.'
      );
      return false;
    }

    const fileSizeLimit = 10 * 1024 * 1024; // 5MB

    if (file.size > fileSizeLimit) {
      ModalMessage('Mensaje', 'El archivo no puede pesar mas de 10MB.');
      return false; // Cancela la carga del archivo
    }

    setloadingModal(true);
    return true;
  };

  const handleGetTicketsDetail = async () => {
    const ticket = {
      id_ticket: IdTicket,
    };
    let ticketsData = await TicketsService.postGetTicketsMessage(ticket);

    let flagColor = 1;
    let color;
    let coloBar;
    let newList = [];

    for (const element of ticketsData.data) {
      if (flagColor > 0) {
        color = '#f5f5f5';
        coloBar = '#e3e3e3';
      } else {
        color = '#dbdbdb';
        coloBar = '#c4c4c4';
      }

      let tag = ReturnStatusTag(element.status.toString());

      flagColor = flagColor * -1;

      newList = newList.concat({
        dot: (
          <Avatar
            src={element.imagen_usuario}
            size="large"
            icon={
              element.tipo == 'Soporte' ? <RobotOutlined /> : <UserOutlined />
            }
            style={{ backgroundColor: '#3c5c7c', marginTop: 0 }}
          />
        ),
        children: returnCardsClass.RegresaCard(
          element,
          returnDateFormatClass.regresaFormatoFechaTimeZone(
            element.fecha_registro
          ),
          color,
          returnDateFormatClass.regresaMomentFromNowTimezoneLocal(
            element.fecha_registro
          ),
          tag.color,
          tag.text,
          coloBar,
          handleModalAttachementOpen
        ),
      });
    }

    UpdatemessageList(newList);
  };

  const handleCancel = () => setPreviewOpen(false);

  const handleChangeRate = (value) => {
    setrateTicketValue(value);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Adjuntar archivo
      </div>
    </div>
  );

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: '#3c5c7c',
            colorPrimaryHover: '#517da9',
            colorPrimaryActive: '#517da9',
          },
          Card: {
            headerBg: '#3c5c7c',
          },
        },
      }}
    >
      <Structure breadcrumb={'Bienvenido al sistema Fondeadora Finamo'}>
        <div
          style={{
            minHeight: tableHeight + 260,
            maxHeight: tableHeight + 260,
            overflow: 'auto',
            marginBottom: -20,
            marginTop: 10,
            minWidth: 800,
          }}
        >
          <center>
            {/* <Typography.Title level={2} style={{ margin: 0, color: "#081b3e" }}>
          Detalle del Ticket
        </Typography.Title>
        <Divider style={{ marginTop: 5 }} /> */}
            <div>
              <Typography.Title
                level={5}
                style={{ marginTop: -5, color: '#081b3e' }}
              >
                Datos del Ticket:
              </Typography.Title>
            </div>

            <Skeleton loading={loading} active={true}>
              <Card
                headStyle={{ height: 70 }}
                bodyStyle={{
                  height: 80,
                  overflow: 'hidden',
                  overflowY: 'scroll',
                  padding: 0,
                  margin: 0,
                  maxWidth: divContentWidth + 27,
                  minWidth: 1020,
                }}
                title={
                  <div>
                    <p style={{ lineHeight: 0.2, fontSize: 15 }}>
                      <b>Folio:</b> &nbsp;{folio} &nbsp;{' '}
                      <b>Usuario Solicitó:</b>
                      &nbsp;{nombreCompleto}
                    </p>{' '}
                    <p style={{ lineHeight: 0.2, fontSize: 15 }}>
                      <b>Titulo:</b>"{TituloTicket}" &nbsp; <b>Status:</b>&nbsp;
                      <Tag color={color}>{text.toUpperCase()}</Tag>{' '}
                      <b>Fecha:</b>
                      &nbsp;{fecha}
                      &nbsp;&nbsp;&nbsp;
                      <Button
                        style={{ height: 30 }}
                        type="primary"
                        danger
                        icon={<PaperClipOutlined />}
                        onClick={() => handleModalAttachementOpen(0, 0)}
                      ></Button>
                    </p>{' '}
                  </div>
                }
                bordered={false}
                style={{ backgroundColor: '#f5f5f5' }}
                type="inner"
              >
                <div
                  style={{
                    margin: 0,
                    padding: 0,
                    overflow: 'hidden',
                    cursor: 'zoom-in',
                  }}
                  dangerouslySetInnerHTML={{ __html: detalleTicket }}
                  onClick={ShowModalDocument}
                ></div>
              </Card>
            </Skeleton>
          </center>
          <Typography.Title
            level={5}
            style={{ marginTop: 10, color: '#081b3e' }}
          >
            Mensajes:
          </Typography.Title>
          <Skeleton loading={loading} active={true}>
            <div
              style={{
                maxHeight:
                  window.innerHeight - (canAnswerButtonHidden ? 450 : 450),
                overflow: 'auto',
                paddingLeft: 20,
                paddingRight: 20,
                marginBottom: -20,
                marginTop: -10,
                minWidth: 980,
                width: divContentWidth - 20,
              }}
            >
              {messageList.length == 0 ? (
                <Empty
                  style={{ marginTop: 60, marginBottom: 60 }}
                  description={
                    <Typography.Text>
                      Sin mensajes por el momento.
                    </Typography.Text>
                  }
                ></Empty>
              ) : (
                <Timeline style={{ marginTop: 20 }} items={messageList} />
              )}
            </div>
            <Button
              style={{ width: 200, height: 50, display: canAnswerButtonHidden }}
              type="primary"
              onClick={() => OpenResponseModal()}
              icon={<CommentOutlined />}
            >
              Responder
            </Button>
            &nbsp;
            <Button
              style={{
                width: 200,
                height: 50,
                backgroundColor: '#04bc73',
                display: finalizarButtonHidden,
              }}
              type="primary"
              onClick={() => OpenFinishModal()}
              icon={<CheckOutlined />}
            >
              Finalizar
            </Button>
          </Skeleton>
        </div>
        <Modal
          closable={false}
          destroyOnClose={true}
          open={isResponseModalOpen}
          width={900}
          title={
            <div>
              <ContainerOutlined /> &nbsp; {answerModalTitle}
            </div>
          }
          footer={[
            <>
              <Button
                style={{ height: 40, display: attachementButtonHidden }}
                type="primary"
                danger
                icon={<PaperClipOutlined />}
                onClick={() => {
                  handleModalResponseAttachementOpen();
                  setflagAdjuntarModal(true);
                }}
              >
                Archivos Adjuntos
              </Button>
              &nbsp;&nbsp;
            </>,
            <span key={'spanCalificar'} style={{ display: finishButtonHidden }}>
              <b>Calificar:</b>&nbsp;&nbsp;
              <Rate allowHalf defaultValue={0} onChange={handleChangeRate} />
              &nbsp; <Divider type="vertical" />
            </span>,
            <Button
              key={'btnCerrarModal'}
              type="primary"
              onClick={handleModalResponseClose}
            >
              <CloseOutlined />
              Cerrar
            </Button>,
            <Button
              key={'btnResponderModal'}
              style={{ display: answerButtonHidden }}
              type="primary"
              onClick={() => {
                setdisableButton(true);
                handleSaveDetail();
              }}
              disabled={disableButton}
            >
              <CommentOutlined />
              Responder
            </Button>,
            <Button
              key={'btFinalizarModal'}
              style={{
                width: 155,
                backgroundColor: '#04bc73',
                display: finishButtonHidden,
              }}
              type="primary"
              onClick={() => handleFinishTicket()}
              icon={<CheckOutlined />}
            >
              Finalizar
            </Button>,
          ]}
        >
          <Editor
            tinymceScriptSrc={
              process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'
            }
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue="<p></p>"
            init={{
              height: 200,
              resize: false,
              language: 'es_MX',
              language_url: process.env.PUBLIC_URL + '/tinymce/langs/es_MX.js',
              menubar: false,
              plugins: [
                'lists',
                // "link",
                // "charmap",
                // "anchor",
                // "searchreplace",
                // "visualblocks",
                // "table",
                // "preview",
                // "help",
                // "wordcount",
              ],
              toolbar:
                'undo redo | blocks | ' +
                'bold italic forecolor | alignleft aligncenter ',
              // "alignright alignjustify | bullist numlist outdent indent ",
              content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
          />
        </Modal>
        <ModalMessageCustom
          closable={false}
          destroyOnClose={true}
          title={
            <div>
              <ContainerOutlined /> &nbsp; {modalTitle}
            </div>
          }
          open={isModalOpen}
          onCancel={handleModalClose}
          width={1100}
          style={{ top: 60 }}
          footer={[
            <Button
              key={'btnCerrarModalCustom'}
              type="primary"
              onClick={handleModalClose}
            >
              Cerrar
            </Button>,
          ]}
        >
          <Card
            headStyle={{ height: 70 }}
            title={
              <center>
                <div>
                  <p style={{ lineHeight: 0.2, fontSize: 15 }}>
                    <b>Folio:</b> &nbsp;{folio} &nbsp; <b>Usuario Solicitó:</b>
                    &nbsp;{nombreCompleto}
                  </p>{' '}
                  <p style={{ lineHeight: 0.2, fontSize: 15 }}>
                    <b>Titulo:</b>"{TituloTicket}" &nbsp; <b>Status:</b>&nbsp;
                    <Tag color={color}>{text.toUpperCase()}</Tag> <b>Fecha:</b>
                    &nbsp;{fecha}
                  </p>{' '}
                </div>
              </center>
            }
            bordered={false}
            type="inner"
            style={{ backgroundColor: '#f5f5f5' }}
          >
            <div
              style={{
                margin: 0,
                padding: 0,
                overflow: 'hidden',
              }}
              dangerouslySetInnerHTML={{ __html: detalleTicket }}
            ></div>
            <center>
              <Button
                style={{ height: 40, marginTop: 20 }}
                type="primary"
                danger
                icon={<PaperClipOutlined />}
                onClick={() => handleModalAttachementOpen(0, 0)}
              >
                Archivos Adjuntos
              </Button>
            </center>
          </Card>
        </ModalMessageCustom>
        <ModalAttachement
          destroyOnClose={true}
          closable={false}
          title={
            <div>
              <PaperClipOutlined /> &nbsp; Archivos adjuntos
            </div>
          }
          open={isModalAttachementOpen}
          onCancel={handleModalAttachementClose}
          footer={[
            <Button
              key={1}
              type="primary"
              onClick={handleModalAttachementClose}
            >
              Cerrar
            </Button>,
          ]}
        >
          <Spin spinning={loadingModal} active={true}>
            <div style={{ backgroundColor: '#f5f5f5', borderRadius: 10 }}>
              <Upload
                name="archivo"
                customRequest={({ file, onSuccess, onError }) => {
                  setloadingModal(true);
                  const formData = new FormData();
                  formData.append('archivo', file);
                  fetch(
                    `${URL}guarda-archivo?token=${AuthService.getCurrentToken()}&id_ticket=${IdTicket}&tipo=1`,
                    {
                      method: 'POST',
                      body: formData,
                    }
                  )
                    .then((response) => response.json())
                    .then((data) => {
                      setFileList(data.data);
                      setloadingModal(false);
                    })
                    .catch((error) => {
                      setloadingModal(false);
                      ModalMessage(
                        'Error',
                        'Se produjo un error al guardar el documento.'
                      );
                    });
                }}
                listType="picture-card"
                fileList={fileList}
                showUploadList={{
                  showRemoveIcon: canRemove,
                  showDownloadIcon: true,
                }}
                onPreview={handlePreview}
                onRemove={handleRemove}
                onDownload={handeDownload}
                beforeUpload={handleBeforeUpload}
                disabled={!flagAdjuntarModal}
              >
                {ModelAttachementType == 0 ? (
                  fileList.length == 0 ? (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={false}
                    />
                  ) : null
                ) : (
                  uploadButton
                )}
              </Upload>
            </div>
          </Spin>

          <ModalPreview
            open={previewOpen}
            title={
              <div>
                <FileImageOutlined /> &nbsp; Vista previa de imagen
              </div>
            }
            footer={[
              <Button
                key={'btnOkPreview'}
                type="primary"
                onClick={handleCancel}
              >
                OK
              </Button>,
            ]}
            closable={false}
            width={divContentWidth - 50}
            onCancel={handleCancel}
            style={{ marginTop: -40 }}
          >
            <div
              style={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <TransformWrapper>
                <TransformComponent>
                  <img
                    alt="example"
                    style={{
                      width: '100%',
                    }}
                    src={previewImage}
                  />
                </TransformComponent>
              </TransformWrapper>
            </div>
          </ModalPreview>

          <ModalDocumentViewer
            open={OpenModelDocument}
            width={divContentWidth - 50}
            titulo={'Vista previa del documento'}
            url={pathDocument}
            closable={false}
            footer={[
              <Button
                key={'btnOkViewer'}
                type="primary"
                onClick={handleModalDocument}
              >
                OK
              </Button>,
            ]}
          />
        </ModalAttachement>
      </Structure>
    </ConfigProvider>
  );
};
export default DetalleTicket;
