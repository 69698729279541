import React from 'react';
import { Tag, Tooltip } from 'antd';

import {
  UnlockOutlined,
  FolderOutlined,
  FileOutlined,
} from '@ant-design/icons';

const styleIcons = { fontSize: 20 };

const ReturnTipoSolicitudAltaTag = (valor) => {
  let color = '';
  let text = '';
  switch (valor) {
    case 'Alta':
      color = '#0886b4';
      text = 'Alta';
      break;
    case 'Cambio':
      color = '#7208b4';
      text = 'Cambio';
      break;
  }
  return (
    <Tag color={color} key={valor} style={{ width: 65, textAlign: 'center' }}>
      {text.toUpperCase()}
    </Tag>
  );
};

const ReturnTipoSolicitudTag = (valor) => {
  let color = '';
  let text = '';
  switch (valor) {
    case 1:
      color = '#ff8000';
      text = 'Jefe Soporte(Revisa solicitud)';
      break;
    case 2:
      color = '#0090f0';
      text = 'Gerente sistemas';
      break;
    case 3:
      color = '#00c792';
      text = 'Directivo Autoriza';
      break;
  }
  return (
    <Tag
      color={color}
      key={valor}
      style={{
        width: 300,
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        height: 25,
      }}
    >
      {text.toUpperCase()}
    </Tag>
  );
};

const ReturnTipoSolicitudHardwareSoftwareTag = (valor) => {
  let color = '';
  let text = '';
  switch (valor) {
    case 'Pendiente':
      color = '#ff8000';
      text = 'Pendiente';
      break;
    case 'Verificado':
      color = '#0090f0';
      text = 'Verificado';
      break;
    case 'Autorizado':
      color = '#00c792';
      text = 'Autorizado';
      break;
    case 'Entregado':
      color = '#6edb00';
      text = 'Entregado';
      break;
    case 'Rechazado':
      color = '#fe0000';
      text = 'Rechazado';
      break;
    case 'Cancelado':
      color = '#ff006e';
      text = 'Cancelado';
      break;
    case 'Otro':
      color = '#b700ff';
      text = 'Otro';
      break;
  }
  return (
    <Tag color={color} key={valor}>
      {text.toUpperCase()}
    </Tag>
  );
};

const ReturnTipoAccesoTag = (valor) => {
  let color = '';
  let text = '';
  let icon = null;
  switch (valor) {
    case 1:
      color = '#1dd381';
      text = 'Acceso a Sistema';
      icon = <UnlockOutlined style={styleIcons} />;
      break;
    case 2:
      color = '#ffc800';
      text = 'Acceso a Carpeta';
      icon = <FolderOutlined style={styleIcons} />;
      break;
    case 3:
      color = '#05cdf0';
      text = 'Acceso a Archivo';
      icon = <FileOutlined style={styleIcons} />;
      break;
  }
  return (
    <Tooltip title={text}>
      <Tag color={color} key={valor}>
        <div>{icon}</div>
      </Tag>
    </Tooltip>
  );
};

const ReturnStatusSolicitudTag = (valor) => {
  let color = '';
  let text = '';
  switch (valor.status) {
    case 'PENDIENTE':
      color = '#ffa600';
      text = 'PENDIENTE';
      break;
    case 'AUTORIZADO':
      color = '#1dd381';
      text = 'AUTORIZADO';
      break;
    case 'RECHAZADO':
      color = '#ff0062';
      text = 'RECHAZADO';
      break;
    case 'BAJA':
      color = '#05cdf0';
      text = 'BAJA';
      break;
    case 'OTRO':
      color = '#7208b4';
      text = 'OTRO';
      break;
  }
  return (
    <Tooltip
      title={valor.status == 'AUTORIZADO' ? '# Ticket: ' + valor.id_ticket : ''}
    >
      <Tag
        color={color}
        key={valor.status}
        style={{ width: 90, textAlign: 'center' }}
      >
        {text.toUpperCase()}
      </Tag>
    </Tooltip>
  );
};

const returnTagClass = {
  ReturnTipoSolicitudTag,
  ReturnTipoAccesoTag,
  ReturnStatusSolicitudTag,
  ReturnTipoSolicitudAltaTag,
  ReturnTipoSolicitudHardwareSoftwareTag
};

export default returnTagClass;
