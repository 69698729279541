import { createContext, useState } from 'react';
import { Typography, Spin, message, notification, Modal } from 'antd';
import AuthService from '../services/auth.service';
import UsuariosService from '../services/usuarios.Service';
import { useNavigate } from 'react-router-dom';
import { HistoryOutlined, CheckCircleOutlined } from '@ant-design/icons';

export const ComponentesContext = createContext();

export function ComponentesContextProvider({ children }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [notificationsCounter, SetnotificationsCount] = useState(0);
  const [DataLista, setDataLista] = useState([]);
  const [messageList, setmessageList] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [tableHeight, settableHeight] = useState(300);
  const [userType, setuserType] = useState();
  const [userImagenUrl, setuserImagenUrl] = useState();

  const [UrlAutoriza, setUrlAutoriza] = useState('');

  const [ActiveTab, setActiveTab] = useState('');

  const [divContentWidth, setdivContentWidth] = useState(1000);

  const { confirm } = Modal;

  const InputTypes = {
    Text: 0,
    Integer: 1,
    Decimal: 2,
    Rfc: 3,
  };

  const openNotification = (content, placement) => {
    api.open({
      message: (
        <>
          <CheckCircleOutlined />
          &nbsp;Mensaje
        </>
      ),
      description: (
        <Typography.Title level={3} style={{ margin: 0, color: '#081b3e' }}>
          &nbsp;&nbsp;&nbsp;{content}
        </Typography.Title>
      ),
      className: 'custom-class',
      style: {
        width: 600,
      },
      placement,
    });
  };

  const ShowLoading = (flagLoading) => {
    setLoading(flagLoading);
  };

  const UpdateNotificationsCounter = (count) => {
    SetnotificationsCount(count);
  };

  const UpdateDataLista = (data) => {
    setDataLista(data);
  };

  const UpdatemessageList = (data) => {
    setmessageList(data);
  };

  const UpdatesettableHeight = (height) => {
    settableHeight(height);
  };

  const UpdatesetuserType = (type) => {
    setuserType(type);
  };

  const UpdateUrlAutoriza = (value) => {
    setUrlAutoriza(value);
  };

  const UpdateActiveTab = (value) => {
    setActiveTab(value);
  };

  const UpdatesetdivContentWidth = (value) => {
    setdivContentWidth(value);
  };

  const UpdatesetuserImagenUrl = async (type) => {
    let imagen_avatar = await UsuariosService.postRegresaImagenAvatar();
    //console.log('avatar', imagen_avatar.urlImagen);
    setuserImagenUrl(imagen_avatar.urlImagen);
  };

  const handleCloseSession = async (values) => {
    AuthService.logout();
    navigate('/login');
  };

  const CloseSession = () => {
    message.warning(
      <Typography.Title level={3} style={{ margin: 0, color: '#081b3e' }}>
        Su sessión ha expirado. La pagina se redigirirá al inicio de sesión.
      </Typography.Title>,
      4,
      handleCloseSession
    );
  };

  const ModalMessage = (titulo, contenido) => {
    Modal.info({
      title: titulo,
      content: contenido,
      onOk() {},
    });
  };

  return (
    <ComponentesContext.Provider
      value={{
        CloseSession,
        ShowLoading,
        InputTypes,
        openNotification,
        notificationsCounter,
        UpdateNotificationsCounter,
        DataLista,
        UpdateDataLista,
        messageList,
        UpdatemessageList,
        tableHeight,
        UpdatesettableHeight,
        ModalMessage,
        userType,
        UpdatesetuserType,
        userImagenUrl,
        UpdatesetuserImagenUrl,
        UrlAutoriza,
        UpdateUrlAutoriza,
        ActiveTab,
        UpdateActiveTab,
        divContentWidth,
        UpdatesetdivContentWidth,
      }}
    >
      {contextHolder}
      <Spin size="large" spinning={loading}>
        {children}
      </Spin>
    </ComponentesContext.Provider>
  );
}
