import {  Modal as MyModal } from "antd";
import { ContainerOutlined} from "@ant-design/icons";
import { Children } from "react";
//import 'dayjs/locale/es';
//import locale from 'antd/es/date-picker/locale/es_ES';

export default function ModalMessageCustom(props) {
  //const { value, onChange } = props;

  return (
    <MyModal
      {...props}
      destroyOnClose={true}
    >
    </MyModal>
  );
}
