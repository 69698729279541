import React, { useState, useEffect, useContext, useRef } from 'react';
import Structure from '../../componentes/StructureAdmin';
import AuthService from '../../services/auth.service';
import TicketsService from '../../services/tickets.Service';
import BaseConocimientosService from '../../services/baseConocimiento.Service';
import CatalogosService from '../../services/catalogos.Service';
import { useNavigate, useLocation } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment';
import ModalDocumentViewer from '../../componentes/ModalDocumentViewer';
import returnValidUploadFileClass from '../../clases/returnValidUploadFile.class';

import {
  Tabs,
  Input,
  Table,
  Table as TableEmpresa,
  Table as TablePlaza,
  Typography,
  Button,
  Modal,
  Select,
  Spin,
  Upload,
  Divider,
} from 'antd';
import {
  PlusOutlined,
  ReadOutlined,
  PaperClipOutlined,
  EditOutlined,
  FilePdfOutlined,
  CloseOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { ComponentesContext } from '../../contextos/componentesContext';
import CompoundedSpace from 'antd/es/space';

const BaseConocimientos = () => {
  const { tableHeight, ShowLoading, ModalMessage, divContentWidth } =
    useContext(ComponentesContext);
  const tableRef = useRef();
  const URL = process.env.REACT_APP_URL_API_SOPORTE;

  //ID DEL REGISTRO DE LA BASE DE CONOCIMIENTO.
  const [idBaseConocimiento, setidBaseConocimiento] = useState(0);

  const [urlGuardaAdjunto, seturlGuardaAdjunto] = useState(
    `${URL}guarda-archivo-baseconocimiento?token=${AuthService.getCurrentToken()}&id_ticket=0&tipo=0`
  );
  const [user, setUser] = useState({});
  const [Data, setData] = useState([]);
  const [valorRow, setvalorRow] = useState('');
  const [ordenRow, setordenRow] = useState('');
  const [inputValue, setInputValue] = useState('');
  const { confirm } = Modal;
  const { Title } = Typography;
  const [fileList, setFileList] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [inputTitulo, setinputTitulo] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalAttachOpen, setisModalAttachOpen] = useState(false);
  const [rowDataList, setrowDataList] = useState('');
  const [optionClasificacion, setoptionClasificacion] = useState([]);
  const [OpenModelDocument, setOpenModelDocument] = useState(false);
  const [pathDocument, setpathDocument] = useState('');
  const editorRef = useRef(null);
  const [loadingModal, setloadingModal] = useState(false);
  let notificationsInterval = useRef();

  const [isUploadButton, setisUploadButton] = useState(
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Adjuntar archivo
      </div>
    </div>
  );

  const EnumCatalogo = {
    Clasificacion: 0,
    Empresa: 1,
    Plaza: 2,
  };

  const handlePreview = async (file) => {
    const fileExtension = file.name.split('.').pop();
    switch (fileExtension.toLowerCase()) {
      case 'pdf':
        setpathDocument(file.url);
        setOpenModelDocument(true);
        break;
    }
  };

  const handleBeforeUpload = async (file) => {
    let flagArchivoValido =
      await returnValidUploadFileClass.returnPdfValidUploadFile(file.type);
    if (flagArchivoValido == false) {
      ModalMessage(
        'Mensaje',
        'El tipo de archivo que se desea adjuntar no es valido.'
      );
      return false;
    }

    const fileSizeLimit = 10 * 1024 * 1024; // 5MB

    if (file.size > fileSizeLimit) {
      ModalMessage('Mensaje', 'El archivo no puede pesar mas de 10MB.');
      return false; // Cancela la carga del archivo
    }

    setloadingModal(true);
    return true;
  };

  const handleRemove = async (file) => {
    setloadingModal(true);
    let ticketsData = await BaseConocimientosService.postBorrarArchivos({
      nombre_archivo: file.name,
      id_base_conocimiento: idBaseConocimiento,
    });
  };

  const getknowledgeData = async () => {
    let ListaBaseConocimientoData =
      await BaseConocimientosService.getListaBaseConocimiento();
    setrowDataList(ListaBaseConocimientoData.data);

    setpathDocument(ListaBaseConocimientoData.data[0].url_documento);
  };

  const scrollToBottomClasificacion = () => {
    if (tableRef.current) {
      const tableBody = tableRef.current.querySelector('.ant-table-body');
      tableBody.scrollTop = tableBody.scrollHeight;
    }
  };

  const ObtieneClasificaciones = async () => {
    let clasificaciones = await TicketsService.getClasificacionTicket();
    //console.log(clasificaciones.data);
    setoptionClasificacion(clasificaciones.data);
  };

  const handleModalDocument = () => {
    setOpenModelDocument(false);
  };

  const handleModalDocumentOpen = (url) => {
    if (url == '') {
      ModalMessage('Mensaje', 'No hay manuales adjuntos.');
      return false;
    }
    setpathDocument(url);
    setOpenModelDocument(true);
  };

  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    setUser(usr);

    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate('/login');
    }

    getknowledgeData();
    ObtieneClasificaciones();
    //setLoading(false);
  }, []);

  const columns = [
    {
      title: 'Opciones',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (_, record) => (
        <div>
          <Button
            type="primary"
            style={{ marginRight: 10, width: 40 }}
            onClick={() => {
              handleEditeForm(record.id);
            }}
            icon={<EditOutlined />}
          ></Button>
          <Button
            type="primary"
            style={{ marginRight: 10, width: 40 }}
            onClick={() => {
              handleModalDocumentOpen(record.url_documento);
            }}
            icon={<FilePdfOutlined />}
          ></Button>
        </div>
      ),
    },
    {
      title: 'Titulo',
      dataIndex: 'titulo',
      key: 'titulo',
    },
    {
      title: 'Usuario',
      dataIndex: 'usuario',
      key: 'usuario',
    },

    {
      title: 'Fecha',
      dataIndex: 'fecha_registro',
      key: 'fecha_registro',
      sorter: (a, b) =>
        moment(a.fecha_registro).unix() - moment(b.fecha_registro).unix(),
      render: (_, record) => (
        <>{moment(record.fecha_registro).format('YYYY-MM-DD, h:mm:ss a')}</>
      ),
    },
  ];

  const handleShowForm = () => {
    setIsModalOpen(true);
  };

  const handleEditeForm = async (id) => {
    setloadingModal(true);
    let respuesta =
      await BaseConocimientosService.postRegresaRegistroBaseConocimiento({
        id,
      });
    setIsModalOpen(true);

    setidBaseConocimiento(id);
    seturlGuardaAdjunto(
      `${URL}actualiza-archivo?token=${AuthService.getCurrentToken()}&id_base_conocimiento=${id}`
    );

    setTimeout(function () {
      setinputTitulo(respuesta.data.titulo);
      setSelectedValue(respuesta.data.id_clasificacion);
      editorRef.current.setContent(respuesta.data.descripcion);
      setloadingModal(false);
    }, 2000);
  };

  const handleModalClose = () => {
    setinputTitulo('');
    setSelectedValue('');
    editorRef.current.setContent('');
    setidBaseConocimiento(0);
    seturlGuardaAdjunto(
      `${URL}guarda-archivo?token=${AuthService.getCurrentToken()}&id_ticket=0&tipo=0`
    );
    getknowledgeData();
    setIsModalOpen(false);
  };

  const handleModalAttachClose = () => {
    setisModalAttachOpen(false);
  };

  const handleModalAttachOpen = async () => {
    let DetalleAdjuntosData =
      await BaseConocimientosService.postAdjuntosBaseConocimientos({
        id_ticket: 0,
        tipo: 0,
        id_ticket_detalle: 0,
        id_base_conocimiento: idBaseConocimiento,
      });

    let arregloFinal = [];

    DetalleAdjuntosData.data.map((fila) => {
      arregloFinal.push(fila);
    });

    setFileList(arregloFinal);
    setisModalAttachOpen(true);
  };

  const handleChangeTitulo = (e) => {
    //console.log(e.target.value);
    setinputTitulo(e.target.value);
  };

  const handleSelectChange = (value) => {
    //console.log(value);
    setSelectedValue(value);
  };

  const handleChangeAttach = async ({ fileList: newFileList }) => {
    setTimeout(async function () {
      let DetalleAdjuntosData =
        await BaseConocimientosService.postAdjuntosBaseConocimientos({
          id_ticket: 0,
          tipo: 0,
          id_ticket_detalle: 0,
          id_base_conocimiento: idBaseConocimiento,
        });

      let arregloFinal = [];

      DetalleAdjuntosData.data.map((fila) => {
        arregloFinal.push(fila);
      });

      //console.log(arregloFinal);

      setFileList(arregloFinal);
      //console.log(fileList.length);
      //console.log(fileList);

      setloadingModal(false);
    }, 6000);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Adjuntar archivo
      </div>
    </div>
  );

  const handleGuardarBaseConocimiento = async () => {
    if (inputTitulo == '') {
      ModalMessage('Mensaje', 'El titulo no puede estar en blanco.');
      return false;
    }

    if (selectedValue == '') {
      ModalMessage('Mensaje', 'Debes seleccionar una clasificación.');
      return false;
    }

    if (editorRef.current.getContent() == '') {
      ModalMessage('Mensaje', 'La descripción no puede estar en blanco.');
      return false;
    }

    const baseConocimiento = {
      id_baseconocimiento: idBaseConocimiento,
      titulo: inputTitulo,
      descripcion: editorRef.current.getContent(),
      id_clasificacion: selectedValue,
    };

    let respuesta =
      await BaseConocimientosService.postRegistrarBaseConocimiento(
        baseConocimiento
      );
    //getknowledgeData();
    editorRef.current.setContent('');

    if (respuesta.estado == 500) {
      ModalMessage('Mensaje', 'Se produjo un error al agregar el registro.');
      return false;
    }

    setinputTitulo('');
    setSelectedValue('');

    getknowledgeData();

    setIsModalOpen(false);

    ModalMessage('Mensaje', 'El registro se realizo correctamente.');
  };

  return (
    <Structure
      breadcrumb={'Bienvenido al sistema Fondeadora Finamo'}
      menuOption={'6'}
    >
      <center>
        <Typography.Title level={2} style={{ marginTop: 0, color: '#081b3e' }}>
          <ReadOutlined /> &nbsp; Base de conocimiento
          <Divider />
        </Typography.Title>

        <div style={{ textAlign: 'right', marginTop: -35 }}>
          <Button
            type="primary"
            onClick={handleShowForm}
            icon={<PlusOutlined />}
          >
            Agregar
          </Button>
        </div>
        <Table
          style={{ width: divContentWidth, marginTop: -10, minWidth: 1000 }}
          size="middle"
          columns={columns}
          dataSource={rowDataList}
          pagination={false}
          scroll={{
            y: tableHeight + 100,
          }}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onMouseEnter: (event) => {HandleClick(record)},
          //   };
          // }}
        />

        <Modal
          open={isModalOpen}
          closable={false}
          maskClosable={false}
          title={
            <div>
              <ReadOutlined /> Datos de base de conocimiento
            </div>
          }
          footer={[
            <Spin spinning={loadingModal} active={true} indicator={null}>
              <Button
                style={{ height: 40, marginLeft: 20 }}
                type="primary"
                danger
                icon={<PaperClipOutlined />}
                onClick={handleModalAttachOpen}
              >
                Adjuntar manual
              </Button>
              ,
              <Button
                key={1}
                type="primary"
                onClick={handleGuardarBaseConocimiento}
                icon={<SaveOutlined />}
              >
                Guardar
              </Button>
              ,
              <Button
                key={1}
                type="primary"
                onClick={handleModalClose}
                icon={<CloseOutlined />}
              >
                Salir
              </Button>
              ,
            </Spin>,
          ]}
          onCancel={handleModalClose}
          width={1000}
        >
          <Spin spinning={loadingModal} active={true}>
            <table width={'100%'} style={{ marginBottom: 20 }}>
              <tr>
                <td width={50}>
                  <b>Titulo:</b>
                </td>
                <td>
                  <Input
                    maxLength={60}
                    onChange={handleChangeTitulo}
                    value={inputTitulo}
                  />
                </td>
              </tr>
              <tr>
                <td width={50}>
                  <b>Clasificación:</b>
                </td>
                <td>
                  <Select
                    style={{ textAlign: 'left', width: 400 }}
                    options={optionClasificacion}
                    onChange={handleSelectChange}
                    value={selectedValue}
                  />
                </td>
              </tr>
            </table>

            <b>Describe la resolución del problema:</b>
            <Editor
              tinymceScriptSrc={
                process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'
              }
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue="<p></p>"
              init={{
                height: 300,
                resize: false,
                language: 'es_MX',
                language_url:
                  process.env.PUBLIC_URL + '/tinymce/langs/es_MX.js',
                menubar: false,
                plugins: ['lists'],
                toolbar:
                  'undo redo | blocks | ' +
                  'bold italic forecolor | alignleft aligncenter ',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            />
          </Spin>
        </Modal>

        <Modal
          destroyOnClose={true}
          closable={false}
          title={
            <div>
              <PaperClipOutlined /> &nbsp; Adjuntar archivos
            </div>
          }
          open={isModalAttachOpen}
          onCancel={handleModalAttachClose}
          footer={[
            <Button key={1} type="primary" onClick={handleModalAttachClose}>
              OK
            </Button>,
          ]}
        >
          <Spin spinning={loadingModal} active={true}>
            <div style={{ backgroundColor: '#f5f5f5', borderRadius: 10 }}>
              <Upload
                name="archivo"
                action={urlGuardaAdjunto}
                listType="picture-card"
                // showUploadList={false}
                fileList={fileList}
                onPreview={handlePreview}
                onRemove={handleRemove}
                onChange={handleChangeAttach}
                beforeUpload={handleBeforeUpload}
              >
                {fileList.length > 0 ? null : uploadButton}
              </Upload>
            </div>
          </Spin>
        </Modal>

        <ModalDocumentViewer
          open={OpenModelDocument}
          titulo={'Guía de uso de sistema'}
          url={pathDocument}
          closable={false}
          footer={[
            <Button type="primary" onClick={handleModalDocument}>
              OK
            </Button>,
          ]}
        />
      </center>
    </Structure>
  );
};

export default BaseConocimientos;
