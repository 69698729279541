import React, { useEffect, useContext } from 'react';
import AuthService from '../../services/auth.service';
import { useNavigate } from 'react-router-dom';
import './DashBoard.css';
import { Card, Typography, Table, Divider, Button } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import Structure from '../../componentes/StructureUser';
import { ComponentesContext } from '../../contextos/componentesContext';
import moment from 'moment';
import TicketsService from '../../services/tickets.Service';
import RegresaTiempoTranscurrido from '../../componentes/ReturnElapsedTime';
import ReturnStatusTag from '../../componentes/ReturnStatusTagObject';
import returnDateFormatClass from '../../clases/returnDateFormat.class';
import returnNotificationsClass from '../../clases/returnNotifications.class';

const { Meta } = Card;

const ListaTickets = () => {
  const { DataLista, UpdateDataLista, tableHeight, divContentWidth } =
    useContext(ComponentesContext);

  const navigate = useNavigate();

  const HandlePrueba = (id_ticket) => {
    navigate('/detalle-ticket', {
      state: {
        record: {
          id_ticket: id_ticket,
        },
      },
    });
  };

  const columns = [
    {
      title: 'No. Ticket',
      dataIndex: 'ticket',
      key: 'ticket',
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.ticket - b.ticket,
      render: (_, { ticket, status }) => (
        <Button
          style={{
            display: status == '6' || status == '5' ? 'none' : '',
            height: 40,
            width: 105,
          }}
          className="button"
          onClick={() => {
            HandlePrueba(ticket);
          }}
        >
          <span>{ticket} </span>
        </Button>
      ),
    },
    {
      title: 'Estatus',
      key: 'status',
      dataIndex: 'status',
      filters: [
        {
          text: 'No asignado',
          value: '0',
        },
        {
          text: 'Asignado',
          value: '1',
        },
        {
          text: 'Trabajando',
          value: '2',
        },
        {
          text: 'Terminado',
          value: '3',
        },
        {
          text: 'Finalizado',
          value: '4',
        },
        {
          text: 'Cancelado',
          value: '5',
        },
        {
          text: 'Pendiente',
          value: '6',
        },
      ],
      onFilter: (value, record) => record.status.startsWith(value),

      sorter: (a, b) => a.status - b.status,
      render: (_, record) => (
        <div>&nbsp;&nbsp;&nbsp;&nbsp;{ReturnStatusTag(record.status)}</div>
      ),
    },
    {
      title: 'Titulo',
      dataIndex: 'titulo',
      key: 'titulo',
    },
    {
      title: 'Clasificación',
      dataIndex: 'clasificacion',
      key: 'clasificacion',
    },

    {
      title: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      sorter: (a, b) => moment(a.fecha).unix() - moment(b.fecha).unix(),
      render: (_, record) => (
        <>{returnDateFormatClass.regresaFormatoFechaTimeZone(record.fecha)}</>
      ),
    },

    {
      title: 'Transcurrido',
      dataIndex: 'fecha',
      key: 'fecha',
      render: (_, record) => <>{RegresaTiempoTranscurrido(record.fecha)}</>,
    },

    {
      title: 'Usuario asignado',
      dataIndex: 'usuario_asignado',
      key: 'usuario_asignado',
    },
    {
      title: <BellOutlined />,
      width: 60,
      align: 'center',
      dataIndex: 'notificaciones',
      key: 'notificaciones',
      render: (_, record) => (
        <>
          {returnNotificationsClass.returnNotificationCount(
            record.notificaciones
          )}
        </>
      ),
    },
  ];

  const getTicketsData = async () => {
    let ticketsData = await TicketsService.getTickets();
    UpdateDataLista(ticketsData.data);
  };

  useEffect(() => {
    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate('/login');
    }

    getTicketsData();
  }, []);

  return (
    <Structure
      breadcrumb={'Bienvenido al sistema Fondeadora Finamo'}
      menuOption={'2'}
    >
      <center>
        <Typography.Title level={1} style={{ margin: 0, color: '#081b3e' }}>
          Mis Tickets
          <Divider />
        </Typography.Title>
      </center>
      <center>
        <Table
          style={{ width: divContentWidth, minWidth: 1000, marginTop: -10 }}
          columns={columns}
          size="middle"
          dataSource={DataLista}
          pagination={false}
          scroll={{
            y: tableHeight + 98,
          }}
        />
      </center>
    </Structure>
  );
};
export default ListaTickets;
