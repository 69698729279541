import { Modal as MyModal } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
//import 'dayjs/locale/es';
//import locale from 'antd/es/date-picker/locale/es_ES';

export default function ModalDocumentViewer(props) {
  //const { value, onChange } = props;

  return (
    <MyModal
      {...props}
      destroyOnClose={true}
      title={
        <div>
          <FilePdfOutlined /> &nbsp; {props.titulo}
        </div>
      }
      width={props.width}
      style={{ marginTop: -40 }}
    >
      <object
        type="application/pdf"
        data={props.url}
        width="100%"
        height="400"
      ></object>
    </MyModal>
  );
}
