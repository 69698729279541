import { Layout, ConfigProvider, Space, Menu, Avatar, Badge, Spin, Typography, Skeleton } from "antd";

import {
  ProfileOutlined,
  LogoutOutlined,
  UserOutlined,
  HomeOutlined,
  ToolOutlined,
  BellOutlined,
} from "@ant-design/icons";

import "./LoadingPage.css";

const LoadingPage = () => {
  const { Header, Footer, Sider, Content } = Layout;
  const { Title } = Typography;

  const headerStyle = {
    textAlign: "right",
    color: "#fff",
    height: 50,
    paddingInline: 50,
    paddingTop: 4,
    lineHeight: "45px",
    backgroundColor: "#2b455a",
  };

  const contentStyle = {
    textAlign: "center",
    minHeight: 120,
    minWidth: 800,
    lineHeight: "120px",
    color: "#000000",
    backgroundColor: "#dedede",
    padding: 20,
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#04142b",
        },
      }}
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size={[0, 48]}
      >
        <Layout>
          <Header style={headerStyle}>
          <Skeleton loading={true} active={true}>
            <ToolOutlined />
            <Badge size="small" count={0}>
              <Avatar size="large" icon={<UserOutlined />} />
            </Badge>
            </Skeleton>
          </Header>
          <Layout style={{ height: window.innerHeight - 60 }}>
            <Sider
              width={300}
              style={{ padding: 20, backgroundColor: "#ebeaea" }}
            >
              <div style={{ backgroundColor: "white", borderRadius: 10 }}>
                <Title level={4}>
                  &nbsp;&nbsp; <ToolOutlined /> Menu Soporte
                </Title>
                <Skeleton loading={true} active={true}>
                <Menu
                  style={{
                    width: 256,
                    fontWeight: "bold",
                    borderRadius: 10,
                    borderWidth: 0,
                  }}
                  mode="inline"
                  inlineCollapsed={true}
                />
                </Skeleton>
              </div>
            </Sider>
            <Content style={contentStyle}>
              <div
                id="contenedor-admin"
                className="content-main"
                style={{
                  backgroundColor: "white",
                  height: "100%",
                  padding: 20,
                  minWidth: 800,
                }}
              >
                <center>
                  <div
                    style={{
                      height: 250,
                      width: 400,
                      backgroundColor: "#ededed",
                      padding: 30,
                      marginTop: 100,
                      borderRadius: 8,
                    }}
                  >
                    <div className="content_loading">
                      <Spin
                        tip={<div style={{ fontWeight: "bold" }}>Cargando</div>}
                        size="large"
                      />
                    </div>
                  </div>
                </center>
              </div>
            </Content>
          </Layout>
        </Layout>
      </Space>
    </ConfigProvider>
  );
};
export default LoadingPage;
