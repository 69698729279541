import React, { useState, useEffect, useContext, useRef } from 'react';

import {
  Checkbox,
  Button,
  Select,
  Input,
  Row,
  Col,
  Divider,
  Radio,
  Tooltip,
  Table as TableSolicitudes,
  Modal,
} from 'antd';
import {
  PlusOutlined,
  SaveOutlined,
  EditOutlined,
  CloseOutlined,
} from '@ant-design/icons';

import moment from 'moment';
const { TextArea } = Input;

import UtilsClass from '../../clases/Utils.class';

import { ComponentesContext } from '../../contextos/componentesContext';
import SolicitudesService from '../../services/solicitudes.Service';
import TicketsService from '../../services/tickets.Service';
import CatalogosService from '../../services/catalogos.Service';

import getColumnSearchProps from '../../componentes/SearchPropsCustom';
import returnDateFormatClass from '../../clases/returnDateFormat.class';

import returnTagClass from '../../clases/returnTags.class';

export default function SolicitudAlta(props) {
  const { ShowLoading, ModalMessage } = useContext(ComponentesContext);

  const [inputCorreo, setinputCorreo] = useState('');
  const [inputCorreoBeneficio, setinputCorreoBeneficio] = useState('');
  const [inputBeneficiarioEspecifique, setinputBeneficiarioEspecifique] =
    useState('');
  const [inputNombreElemento, setinputNombreElemento] = useState('');
  const [inputJustificacion, setinputJustificacion] = useState('');

  const [inputRuta, setinputRuta] = useState('');

  const [formUsuarios, setformUsuarios] = useState('');
  const [formSolicitante, setformSolicitante] = useState('');
  const [formBeneficiario, setformBeneficiario] = useState('');
  const [formPuesto, setformPuesto] = useState('');
  const [formSistema, setformSistema] = useState('');
  const [formDescarga, setformDescarga] = useState(0);
  const [formTemporal, setformTemporal] = useState('');

  const [optionUsuarios, setoptionUsuarios] = useState('');
  const [optionSistemas, setoptionSistemas] = useState('');
  const [optionPuestos, setoptionPuestos] = useState('');

  const [optionCarpetas, setoptionCarpetas] = useState('');
  const [optionArchivos, setoptionArchivos] = useState('');

  const [formElemento, setformElemento] = useState('');

  const [divInternoVisible, setdivInternoVisible] = useState('');
  const [divExternoVisible, setdivExternoVisible] = useState('none');
  const [divPeriodoVisible, setdivPeriodoVisible] = useState('');

  const [divSistemas, setdivSistemas] = useState('');
  const [divElemento, setdivElemento] = useState('none');

  const [descargaVisible, setdescargaVisible] = useState('none');
  const [archivoVisible, setarchivoVisible] = useState('none');

  const [valueTipoUsuario, setvalueTipoUsuario] = useState(1);
  const [valueRequerimientoAcceso, setvalueRequerimientoAcceso] = useState(1);
  const [valueTipoPermiso, setvalueTipoPermiso] = useState(1);
  const [valueTipoAcceso, setvalueTipoAcceso] = useState(1);

  const [disabledButton, setdisabledButton] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const [valGuardaEdita, setvalGuardaEdita] = useState(1);

  const [rutaVisible, setrutaVisiblen] = useState('none');

  const searchInput = useRef(null);

  const [isModalOpen, setisModalOpen] = useState('');
  const [DataSolicitudes, setDataSolicitudes] = useState([]);
  const tableRefSolicitudes = useRef();
  const [idSolicitud, setidSolicitud] = useState('');
  const [formChecked, setformChecked] = useState(false);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  useEffect(() => {
    InicializaFormulario();
  }, []);

  const InicializaFormulario = async () => {
    getUsuariosData();
    getSistemasData();
    getPuestosData();
    getSolicitudesData();
  };

  const getUsuariosData = async () => {
    let UsuariosData = await TicketsService.getListaDatosUsuarios();

    setformSolicitante(UsuariosData.usuarioDefault);
    setformBeneficiario(UsuariosData.usuarioDefault);

    const filteredDataSistemas = UsuariosData.data.filter(
      (item) => parseInt(item.id) === parseInt(UsuariosData.usuarioDefault)
    );
    setformPuesto(filteredDataSistemas[0].id_puesto);
    setinputCorreoBeneficio(filteredDataSistemas[0].correo);

    setoptionUsuarios(
      UsuariosData.data.map((item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.nombre_completo}</div>,
        nombre: item.nombre_completo,
        correo: item.correo,
        puesto: item.id_puesto,
      }))
    );
  };

  const getSistemasData = async () => {
    let sistemasData = await CatalogosService.getListaSistemas();

    const filteredDataSistemas = sistemasData.data.filter(
      (item) => parseInt(item.tipo) === 1
    );

    setoptionSistemas(
      filteredDataSistemas.map((item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.descripcion}</div>,
        nombre: item.descripcion,
        tipo: item.tipo,
        id_usuario_propietario: item.id_usuario_propietario,
        correo: item.correo,
      }))
    );

    const filteredDataCarpeta = sistemasData.data.filter(
      (item) => parseInt(item.tipo) === 2
    );

    setoptionCarpetas(
      filteredDataCarpeta.map((item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.descripcion}</div>,
        nombre: item.descripcion,
        tipo: item.tipo,
        id_usuario_propietario: item.id_usuario_propietario,
        correo: item.correo,
        ruta: item.ruta,
      }))
    );

    const filteredDataArchivo = sistemasData.data.filter(
      (item) => parseInt(item.tipo) === 3
    );

    setoptionArchivos(
      filteredDataArchivo.map((item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.descripcion}</div>,
        nombre: item.descripcion,
        tipo: item.tipo,
        id_usuario_propietario: item.id_usuario_propietario,
        correo: item.correo,
        ruta: item.ruta,
      }))
    );
  };

  const getPuestosData = async () => {
    let puestosData = await CatalogosService.getListaPuestos();

    setoptionPuestos(
      puestosData.data.map((item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.descripcion}</div>,
        nombre: item.descripcion,
      }))
    );
  };

  const handleTipoUsuarioChange = (e) => {
    setvalueTipoUsuario(e.target.value);
    setdivInternoVisible(e.target.value == 1 ? '' : 'none');
    setdivExternoVisible(e.target.value == 2 ? '' : 'none');
    setinputCorreoBeneficio('');

    setformBeneficiario('');
    setformPuesto('');
  };

  const handleBeneficiarioChange = (e, option) => {
    setformBeneficiario(e);
    setformPuesto(option.puesto);
    setinputCorreoBeneficio(option.correo);
  };

  const handleDuenoChange = (e, option) => {
    setformUsuarios(e);
    setinputCorreo(option.correo);
  };

  const handleSelectChange = (value) => {
    setSelectedValue(value.toString());
  };

  const handleRequerimientoAccesoChange = (e) => {
    setvalueRequerimientoAcceso(e);

    if (e != 1) {
      setarchivoVisible('');
      setdescargaVisible('none');

      setdivSistemas('none');
      setdivElemento('');

      if (formSistema != '') {
        const newArray = [...optionSistemas];
        const findValue = newArray.find(
          (item) => item.value === parseInt(formSistema)
        );

        if (findValue.tipo == 2) {
          setdescargaVisible('');
        } else {
          setdescargaVisible('none');
        }
      } else {
        setdescargaVisible('none');
      }
    } else {
      setdivSistemas('');
      setdivElemento('none');

      setarchivoVisible('none');
    }
  };

  const handleSelectSistemasChange = (e, option) => {
    if (option.tipo == 2) {
      setdescargaVisible('');
    } else {
      setdescargaVisible('none');
    }

    setformSistema(e);

    setformUsuarios(option.id_usuario_propietario);
    setinputCorreo(option.correo);
  };

  const onChange = (key) => {
    if (key.target.checked) {
      setformChecked(true);
      setformDescarga(1);
    } else {
      setformChecked(false);
      setformDescarga(0);
    }
  };

  const handleInputCorreo = (e) => {
    setinputCorreo(e.target.value);
  };

  const optionsTemporal = [
    {
      value: '',
      label: '',
    },
    {
      value: '1',
      label: '1 Semana',
    },
    {
      value: '2',
      label: '2 Semanas',
    },
    {
      value: '3',
      label: '3 Semanas',
    },
    {
      value: '4',
      label: '4 Semanas',
    },
    {
      value: '5',
      label: '2 Meses',
    },
    {
      value: '6',
      label: '3 Meses',
    },
  ];

  const handleClearForm = async () => {
    setvalueTipoUsuario(1);

    setvalueRequerimientoAcceso(1);
    setformSistema('');
    setinputNombreElemento('');
    setvalueTipoPermiso(1);
    setformDescarga('');
    setvalueTipoAcceso(1);
    setformTemporal('');
    setinputJustificacion('');
    setformUsuarios('');
    setformUsuarios('');

    setformElemento('');

    setdivExternoVisible('none');
    setdescargaVisible('none');
    setarchivoVisible('none');

    setdivInternoVisible('');
    setdivPeriodoVisible('');

    setinputCorreo('');
    setinputRuta('');

    let solicitudesData = await SolicitudesService.getListSolicitudes();
    setDataSolicitudes(solicitudesData.data);

    getUsuariosData();
  };

  const HandleAgregarSolicitud = async () => {
    let regEXCorreo = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;

    if (formSolicitante == '') {
      ModalMessage('Mensaje', 'El solicitante no puede estar en blanco.');
      setdisabledButton(false);
      return false;
    }

    if (valueTipoUsuario == 1) {
      if (formBeneficiario == '') {
        ModalMessage('Mensaje', 'El beneficiario no puede estar en blanco.');
        setdisabledButton(false);
        return false;
      }
    } else {
      if (inputBeneficiarioEspecifique == '') {
        ModalMessage('Mensaje', 'El beneficiario no puede estar en blanco.');
        setdisabledButton(false);
        return false;
      }

      if (inputCorreoBeneficio == '') {
        ModalMessage(
          'Mensaje',
          'El correo del beneficiario no puede estar en blanco.'
        );
        setdisabledButton(false);
        return false;
      }

      if (!regEXCorreo.test(inputCorreoBeneficio)) {
        ModalMessage(
          'Mensaje',
          'El correo del beneficiario no tiene un formato valido, por favor verifique.'
        );
        setdisabledButton(false);
        return false;
      }
    }

    if (valueRequerimientoAcceso != 1) {
      if (inputNombreElemento == '') {
        ModalMessage(
          'Mensaje',
          'El nombre del elemento no puede estar en blanco.'
        );
        setdisabledButton(false);
        return false;
      }

      if (formElemento == '') {
        ModalMessage(
          'Mensaje',
          'El nombre del elemento no puede estar en blanco.'
        );
        setdisabledButton(false);
        return false;
      }
    } else {
      if (formSistema == '') {
        ModalMessage('Mensaje', 'El sistema no puede estar en blanco.');
        setdisabledButton(false);
        return false;
      }
    }

    if (valueTipoAcceso == 1) {
      if (formTemporal == '') {
        ModalMessage('Mensaje', 'El periodo no puede estar en blanco.');
        setdisabledButton(false);
        return false;
      }
    }

    if (inputJustificacion == '') {
      ModalMessage('Mensaje', 'La justificación no puede estar en blanco.');
      setdisabledButton(false);
      return false;
    }

    if (formUsuarios == '') {
      ModalMessage('Mensaje', 'El dueño del activo no puede estar en blanco.');
      setdisabledButton(false);
      return false;
    }

    if (inputCorreo == '') {
      ModalMessage(
        'Mensaje',
        'El correo del dueño del activo no puede estar en blanco.'
      );
      setdisabledButton(false);
      return false;
    }

    if (!regEXCorreo.test(inputCorreo)) {
      ModalMessage(
        'Mensaje',
        'El correo del dueño del activo no tiene un formato valido, por favor verifique.'
      );
      setdisabledButton(false);
      return false;
    }

    ShowLoading(true);

    const solicitudes = {
      id_usuario_solicitante: formSolicitante,
      tipo_usuario: valueTipoUsuario,
      id_usuario_beneficiario: formBeneficiario,
      beneficiario_especifique: inputBeneficiarioEspecifique,
      correo_beneficiario: inputCorreoBeneficio,
      tipo_requerimiento: valueRequerimientoAcceso,
      //id_sistema: formSistema,
      id_sistema: valueRequerimientoAcceso == 1 ? formSistema : formElemento,
      elemento: inputNombreElemento,
      //id_elemento: formElemento == '' ? null : formElemento,
      id_elemento: valueRequerimientoAcceso != 1 ? formElemento : null,
      tipo_permiso: valueTipoPermiso,
      flag_descarga: formDescarga == '' ? '0' : formDescarga,
      tipo_acceso: valueTipoAcceso,
      periodo_temporal: formTemporal,
      justificacion: inputJustificacion,
      id_usuario_permiso: formUsuarios,
      correo_permiso: inputCorreo,
      ruta: inputRuta,
    };

    const updatedJson =
      valGuardaEdita === 2
        ? { ...solicitudes, id_solicitud: idSolicitud }
        : solicitudes;

    let SolicitudesData = null;

    if (valGuardaEdita === 1) {
      SolicitudesData =
        await SolicitudesService.postRegistrarSolicitud(updatedJson);
    } else {
      SolicitudesData =
        await SolicitudesService.postRegistrarCambioSolicitud(updatedJson);
    }

    if (SolicitudesData.estado == 200) {
      handleClearForm();
      ModalMessage(
        'Mensaje',
        'La solicitud se ' +
          (valGuardaEdita === 1 ? 'registró' : 'modificó') +
          ' correctamente.'
      );
    } else {
      ModalMessage(
        'Mensaje',
        'Error al ' +
          (valGuardaEdita === 1 ? 'registrar' : 'modificar') +
          ' la solicitud, favor de reportar a el area de sistemas.'
      );
    }

    setisModalOpen(false);
    setdisabledButton(false);
    ShowLoading(false);
  };

  const getSolicitudesData = async () => {
    let solicitudesData = await SolicitudesService.getListSolicitudes();

    setDataSolicitudes(solicitudesData.data);
  };

  const handleOpenModal = async (reg) => {
    setvalGuardaEdita(2);

    setidSolicitud(reg.id);

    setformSolicitante(reg.id_usuario_solicitante);

    if (reg.beneficiario_especifique == '') {
      setvalueTipoUsuario(1);
      setformBeneficiario(reg.id_usuario_beneficiario);

      const filteredDataSistemas = optionUsuarios.filter(
        (item) => parseInt(item.value) === parseInt(reg.id_usuario_beneficiario)
      );
      setformPuesto(filteredDataSistemas[0].puesto);
    } else {
      setvalueTipoUsuario(2);
      setinputBeneficiarioEspecifique(reg.beneficiario_especifique);
    }

    setinputCorreoBeneficio(reg.correo_beneficiario);

    setdivInternoVisible(reg.beneficiario_especifique == '' ? '' : 'none');
    setdivExternoVisible(reg.beneficiario_especifique != '' ? '' : 'none');

    setvalueRequerimientoAcceso(reg.tipo_requerimiento);

    if (reg.tipo_requerimiento != 1) {
      setarchivoVisible('');
      setdescargaVisible('none');

      setdivSistemas('none');
      setdivElemento('');

      setinputNombreElemento(reg.elemento);

      if (formSistema != '') {
        const newArray = [...optionSistemas];
        const findValue = newArray.find(
          (item) => item.value === parseInt(formSistema)
        );

        if (findValue.tipo == 2) {
          setdescargaVisible('');
        } else {
          setdescargaVisible('none');
        }
      } else {
        setdescargaVisible('none');
      }

      setrutaVisiblen('');
      setinputRuta(reg.ruta);
    } else {
      setdivSistemas('');
      setdivElemento('none');

      setarchivoVisible('none');

      setformSistema(reg.id_sistema);

      setrutaVisiblen('none');
    }

    setformElemento(reg.id_elemento);

    setvalueTipoAcceso(reg.tipo_acceso);

    if (reg.tipo_acceso == 1) {
      setformTemporal(reg.periodo_temporal.toString());
      setdivPeriodoVisible('');
    } else {
      setformTemporal('');
      setdivPeriodoVisible('none');
    }

    setvalueTipoPermiso(reg.tipo_permiso);

    setformDescarga(reg.flag_descarga);

    if (parseInt(reg.flag_descarga) == 1) {
      setformChecked(true);
    } else {
      setformChecked(false);
    }

    setformUsuarios(reg.id_usuario_permiso);
    setinputCorreo(reg.correo_permiso);

    setisModalOpen(true);
  };

  const columnsSolicitudes = [
    {
      title: 'Opciones',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 100,
      render: (_, record) => (
        <div>
          {record.id_status == 0 || record.id_status == 2 ? (
            <Tooltip title="Capturar cambios a la solicitud">
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() => {
                  handleOpenModal(record);
                }}
                style={{ width: 40, height: 34 }}
              ></Button>
            </Tooltip>
          ) : (
            <></>
          )}
        </div>
      ),
    },

    {
      width: 60,
      title: 'Folio',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => <div>{String(record.id).padStart(5, '0')}</div>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      width: 100,
      title: 'Acceso',
      key: 'tipo_requerimiento',
      dataIndex: 'tipo_requerimiento',
      filters: [
        {
          text: 'Sistema',
          value: 1,
        },
        {
          text: 'Carpeta',
          value: 2,
        },
        {
          text: 'Archivo',
          value: 3,
        },
      ],
      onFilter: (value, record) => record.tipo_requerimiento === value,
      sorter: (a, b) => a.tipo_requerimiento - b.tipo_requerimiento,
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {returnTagClass.ReturnTipoAccesoTag(record.tipo_requerimiento)}
        </div>
      ),
    },

    {
      width: 150,
      title: 'Beneficiario',
      dataIndex: 'beneficiario',
      key: 'beneficiario',
      ...getColumnSearchProps({
        dataIndex: 'beneficiario',
        searchInput,
        handleSearch,
        handleReset,
        setSearchText,
        setSearchedColumn,
        searchText,
        searchedColumn,
      }),
      render: (_, record) => (
        <>
          {record.tipo_usuario == 1
            ? record.beneficiario
            : record.beneficiario_especifique}
        </>
      ),
    },

    {
      title: 'Sistema/Elemento',
      dataIndex: 'sistema',
      key: 'sistema',
      align: 'center',

      render: (_, record) => (
        <div>
          <labler>
            {record.tipo_requerimiento != 1 ? record.elemento : record.sistema}
          </labler>
        </div>
      ),
    },
    {
      width: 125,
      title: 'Estatus',
      key: 'status',
      dataIndex: 'status',
      filters: [
        {
          text: 'PENDIENTE',
          value: 'PENDIENTE',
        },
        {
          text: 'AUTORIZADO',
          value: 'AUTORIZADO',
        },
        {
          text: 'RECHAZADO',
          value: 'RECHAZADO',
        },
        {
          text: 'BAJA',
          value: 'BAJA',
        },
        {
          text: 'OTRO',
          value: 'OTRO',
        },
      ],
      onFilter: (value, record) => record.status.startsWith(value),

      sorter: (a, b) => a.status.length - b.status.length,
      render: (_, record) => (
        <Tooltip
          title={
            record.status != 'RECHAZADO'
              ? ''
              : 'Motivo rechazo: ' + record.motivo_rechazo
          }
        >
          <div style={{ textAlign: 'center' }}>
            {returnTagClass.ReturnStatusSolicitudTag(record)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha_movimiento',
      key: 'fecha_movimiento',
      width: 120,
      defaultSortOrder: 'descend',
      sorter: (a, b) =>
        moment(a.fecha_movimiento).unix() - moment(b.fecha_movimiento).unix(),
      render: (_, record) => (
        <>
          {returnDateFormatClass.regresaFormatoFechaTimeZone(
            record.fecha_movimiento
          )}
        </>
      ),
    },
    {
      width: 125,
      title: 'Movimiento',
      key: 'tipo',
      dataIndex: 'tipo',
      filters: [
        {
          text: 'Alta',
          value: 'Alta',
        },
        {
          text: 'Cambio',
          value: 'Cambio',
        },
      ],
      onFilter: (value, record) => record.tipo.startsWith(value),

      sorter: (a, b) => a.tipo.length - b.tipo.length,
      render: (_, record) => (
        <>
          &nbsp;&nbsp;&nbsp;&nbsp;
          {returnTagClass.ReturnTipoSolicitudAltaTag(record.tipo)}
        </>
      ),
    },
  ];

  return (
    <div
      style={{
        width: '98%',
        minWidth: 1050,
      }}
    >
      <div
        style={{
          marginTop: 50,
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 15,
        }}
      >
        <Button
          style={{ width: 200, height: 50 }}
          type="primary"
          onClick={() => {
            handleClearForm();
            setvalGuardaEdita(1);
            setisModalOpen(true);
          }}
          icon={<PlusOutlined />}
        >
          Registrar Solicitud
        </Button>
      </div>

      <TableSolicitudes
        rowKey="id"
        size="small"
        columns={columnsSolicitudes}
        dataSource={DataSolicitudes}
        pagination={false}
        ref={tableRefSolicitudes}
        scroll={{
          y: window.innerHeight - 400,
        }}
        // rowSelection={{
        //   ...rowSelection,
        //   type: 'radio',
        //   columnWidth: 48,
        // }}
      />

      <Modal
        closable={false}
        destroyOnClose={true}
        open={isModalOpen}
        width={1180}
        style={{ marginTop: -70, minWidth: 1100 }}
        title={
          <div>
            <EditOutlined /> &nbsp;{' '}
            {valGuardaEdita === 1
              ? 'Registrar solicitud'
              : 'Modificar solicitud'}
          </div>
        }
        footer={[
          <Button
            key={'btnGuardarRegistro'}
            type="primary"
            onClick={() => {
              setdisabledButton(true);
              HandleAgregarSolicitud();
            }}
            disabled={disabledButton}
          >
            <SaveOutlined />
            Guardar
          </Button>,
          <Button
            key={'btnCerrarrRegistro'}
            type="primary"
            onClick={() => {
              setisModalOpen(false);
            }}
            danger
          >
            <CloseOutlined />
            Cerrar
          </Button>,
        ]}
      >
        <Divider orientation="left" style={{ fontWeight: 'bold' }}>
          Datos del solicitante
        </Divider>
        <Row style={{ marginLeft: -30 }}>
          <Col
            span={4}
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            Solicitante: &nbsp;&nbsp;&nbsp;
          </Col>
          <Col>
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Seleccione una opción"
              options={optionUsuarios}
              value={formSolicitante}
              onChange={setformSolicitante}
              filterOption={UtilsClass.filterOption}
              style={{ textAlign: 'left', width: 400 }}
              disabled
            />
          </Col>
        </Row>

        <Divider orientation="left" style={{ fontWeight: 'bold' }}>
          Datos del beneficiario
        </Divider>

        <Row style={{ marginLeft: -30 }}>
          <Col span={4} style={{ textAlign: 'right' }}>
            Tipo de usuario: &nbsp;&nbsp;&nbsp;
          </Col>
          <Col>
            <Radio.Group
              onChange={(e) => {
                handleTipoUsuarioChange(e);
              }}
              value={valueTipoUsuario}
              disabled={valGuardaEdita != 1}
            >
              <Radio value={1}>Interno</Radio>
              <Radio value={2}>Externo</Radio>
            </Radio.Group>
          </Col>
        </Row>

        <Row
          gutter={16}
          style={{ marginTop: 20, display: divInternoVisible, marginLeft: -40 }}
        >
          <Col
            span={4}
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            Nombre:
          </Col>
          <Col>
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Seleccione una opción"
              options={optionUsuarios}
              value={formBeneficiario}
              onChange={(e, option) => {
                handleBeneficiarioChange(e, option);
              }}
              filterOption={UtilsClass.filterOption}
              style={{ textAlign: 'left', width: 400 }}
              disabled={valGuardaEdita != 1}
            />
          </Col>
        </Row>

        <Row
          gutter={16}
          style={{ marginTop: 20, display: divInternoVisible, marginLeft: -40 }}
        >
          <Col
            span={4}
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            Puesto:
          </Col>
          <Col>
            <Select
              disabled
              style={{ textAlign: 'left', width: 400 }}
              options={optionPuestos}
              onChange={handleSelectChange}
              value={formPuesto}
            />
          </Col>

          <Col
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            Correo:
          </Col>
          <Col>
            <Input
              maxLength={60}
              style={{ width: 400 }}
              onChange={(e) => {
                setinputCorreoBeneficio(e.target.value);
              }}
              disabled
              value={inputCorreoBeneficio}
            />
          </Col>
        </Row>

        <Row
          gutter={16}
          style={{ marginTop: 20, display: divExternoVisible, marginLeft: -40 }}
        >
          <Col
            span={4}
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            Nombre:
          </Col>
          <Col>
            <Input
              maxLength={60}
              style={{ width: 400 }}
              onChange={(e) => {
                setinputBeneficiarioEspecifique(e.target.value);
              }}
              placeholder="Teclee el nombre completo del beneficiario"
              value={inputBeneficiarioEspecifique}
              disabled={valGuardaEdita != 1}
            />
          </Col>

          <Col
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            Correo:
          </Col>
          <Col>
            <Input
              maxLength={60}
              style={{ width: 400 }}
              onChange={(e) => {
                setinputCorreoBeneficio(e.target.value);
              }}
              value={inputCorreoBeneficio}
              disabled={valGuardaEdita != 1}
            />
          </Col>
        </Row>

        <Divider orientation="left" style={{ fontWeight: 'bold' }}>
          Datos del permiso
        </Divider>

        <Row gutter={16} style={{ marginLeft: -40 }}>
          <Col span={4} style={{ textAlign: 'right' }}>
            Acceso a:
          </Col>
          <Col>
            <Radio.Group
              onChange={(e) => {
                handleRequerimientoAccesoChange(e.target.value);
                setformElemento('');
                setformUsuarios('');
                setinputCorreo('');
                setformSistema('');

                if (e.target.value == 1) {
                  setrutaVisiblen('none');
                } else {
                  setrutaVisiblen('');
                }
              }}
              value={valueRequerimientoAcceso}
            >
              <Radio value={1}>Sistema</Radio>
              <Radio value={2}>Carpeta</Radio>
              <Radio value={3}>Archivo</Radio>
            </Radio.Group>
          </Col>
        </Row>

        <Row
          gutter={16}
          style={{ marginTop: 20, display: divSistemas, marginLeft: -40 }}
        >
          <Col
            span={4}
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            Sistema:
          </Col>
          <Col>
            <Select
              style={{ textAlign: 'left', width: 400 }}
              options={optionSistemas}
              onChange={handleSelectSistemasChange}
              value={formSistema}
            />
          </Col>
        </Row>

        <Row
          gutter={16}
          style={{
            marginTop: 20,
            display: divElemento,
            marginLeft: -40,
          }}
        >
          <Col
            span={4}
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            Elemento:
          </Col>
          <Col>
            <Select
              style={{ textAlign: 'left', width: 400 }}
              options={
                valueRequerimientoAcceso == 2 ? optionCarpetas : optionArchivos
              }
              onChange={(e, option) => {
                setformElemento(e);
                setinputNombreElemento(option.nombre);

                console.log(option);
                setformUsuarios(option.id_usuario_propietario);
                setinputCorreo(option.correo);
                setinputRuta(option.ruta);
              }}
              value={formElemento}
            />
          </Col>
        </Row>

        <Row
          gutter={16}
          style={{
            marginTop: 20,
            display:
              archivoVisible == ''
                ? 'none'
                : descargaVisible == ''
                  ? ''
                  : 'none',
            marginLeft: -40,
          }}
        >
          <Col span={4} style={{ textAlign: 'right' }}>
            Tipo permiso:
          </Col>

          <Col style={{ display: descargaVisible }}>
            <Checkbox onChange={onChange} checked={formChecked}>
              Descarga
            </Checkbox>
          </Col>
        </Row>

        <Row
          gutter={16}
          style={{ marginTop: 20, display: archivoVisible, marginLeft: -40 }}
        >
          <Col span={4} style={{ textAlign: 'right' }}>
            Tipo permiso:
          </Col>
          <Col>
            <Radio.Group
              onChange={(e) => {
                setvalueTipoPermiso(e.target.value);
              }}
              value={valueTipoPermiso}
            >
              <Radio value={1}>Solo lectura</Radio>
              <Radio value={2}>Escritura/Lectura</Radio>
            </Radio.Group>
          </Col>

          <Col>
            <Checkbox onChange={onChange} checked={formChecked}>
              Descarga
            </Checkbox>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: 20, marginLeft: -40 }}>
          <Col span={4} style={{ textAlign: 'right' }}>
            Tipo Acceso:
          </Col>
          <Col>
            <Radio.Group
              onChange={(e) => {
                setvalueTipoAcceso(e.target.value);
                setdivPeriodoVisible(e.target.value == 1 ? '' : 'none');
              }}
              value={valueTipoAcceso}
            >
              <Radio value={1}>Temporal</Radio>
              <Radio value={2}>Permanente</Radio>
            </Radio.Group>
          </Col>

          <Col
            span={3}
            style={{ textAlign: 'right', display: divPeriodoVisible }}
          >
            <Select
              style={{ textAlign: 'left', width: 165 }}
              options={optionsTemporal}
              value={formTemporal}
              onChange={setformTemporal}
            />
          </Col>
        </Row>

        <Row
          gutter={16}
          style={{ marginTop: 20, marginLeft: -40, display: rutaVisible }}
        >
          <Col span={4} style={{ textAlign: 'right' }}>
            Ruta:
          </Col>
          <Col>
            <TextArea
              rows={2}
              value={inputRuta}
              onChange={(e) => {
                setinputRuta(e.target.value);
              }}
              placeholder={
                'Indique la ruta ' +
                (valueRequerimientoAcceso == 2
                  ? 'de la Carpeta'
                  : 'del Archivo') +
                ' .'
              }
              style={{ width: 880 }}
            />
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: 20, marginLeft: -40 }}>
          <Col span={4} style={{ textAlign: 'right' }}>
            Justificación:
          </Col>
          <Col>
            <TextArea
              rows={2}
              value={inputJustificacion}
              onChange={(e) => {
                setinputJustificacion(e.target.value);
              }}
              maxLength={240}
              placeholder={
                valGuardaEdita === 1
                  ? '*Especifique las actividades en el sistema. *En caso de permiso adicional, especifique el permiso que solicita, en que módulo y motivo.'
                  : 'Describa nuevamente el motivo de la solicitud, especificando las actividades que necesita realizar en el Sistema o Elemento seleccionado.'
              }
              style={{ width: 880 }}
            />
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: 20, marginLeft: -40 }}>
          <Col
            span={4}
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            Dueño del activo:
          </Col>
          <Col>
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Seleccione una opción"
              options={optionUsuarios}
              value={formUsuarios}
              onChange={(e, option) => {
                handleDuenoChange(e, option);
              }}
              disabled
              filterOption={UtilsClass.filterOption}
              style={{ textAlign: 'left', width: 400 }}
            />
          </Col>

          <Col
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            Correo:
          </Col>
          <Col>
            <Input
              maxLength={60}
              style={{ width: 400 }}
              onChange={handleInputCorreo}
              value={inputCorreo}
              disabled
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
