import React from 'react';

import { Button, Tag, Row, Col } from 'antd';

import { PaperClipOutlined } from '@ant-design/icons';

const RegresaCard = (
  element,
  fecha,
  color,
  fecha_desde,
  color_tag,
  text_tag,
  colorBar,
  handleModalAttachementOpen
) => {
  fecha_desde = fecha_desde.replace('ago', '');
  fecha_desde = fecha_desde.replace('days', 'dias');
  fecha_desde = fecha_desde.replace('hours', 'horas');
  fecha_desde = fecha_desde.replace('minutes', 'minutos');
  fecha_desde = fecha_desde.replace('seconds', 'segundos');
  fecha_desde = fecha_desde.replace('a day', 'un día');
  fecha_desde = fecha_desde.replace('a month', 'un mes');
  fecha_desde = fecha_desde.replace('a minute', 'un minuto');
  fecha_desde = fecha_desde.replace('a few segundos', 'unos segundos');
  fecha_desde = fecha_desde.replace('a year', 'un año');
  fecha_desde = fecha_desde.replace('an hour', 'una hora');
  fecha_desde = fecha_desde.replace('months', 'meses');

  console.log(element);

  return (
    <div style={{ backgroundColor: color, borderRadius: 15, width: '100%' }}>
      <Row
        style={{
          marginBottom: 10,
          backgroundColor: colorBar,
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
        }}
      >
        <Col
          flex="1 1 200px"
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginLeft: 10,
          }}
        >
          <div style={{ fontSize: 11, height: 27, marginTop: 10 }}>
            &nbsp;{' '}
            <b>
              {element.tipo}: {element.nombre_corto}, &nbsp; Fecha: {fecha},
              Transcurrido: {fecha_desde}
            </b>
          </div>
        </Col>
        <Col
          flex="0 1 200px"
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: 10,
          }}
        >
          <div style={{ fontSize: 11, height: 20, marginTop: 8 }}>
            <b>Status:</b>{' '}
            <Tag style={{ width: 100, textAlign: 'center' }} color={color_tag}>
              {text_tag.toUpperCase()}
            </Tag>
          </div>
        </Col>
      </Row>
      <Row>
        <Col flex="1 1 200px">
          <div
            style={{
              fontSize: 13,
              overflow: 'clip',
              textAlign: 'left',
              marginLeft: 30,
            }}
            dangerouslySetInnerHTML={{ __html: element.mensaje }}
          ></div>
        </Col>
        <Col flex="0 1 100px">
          <Button
            style={{ height: 30, visibility: element.adjuntos_visibility }}
            hidden={true}
            icon={<PaperClipOutlined />}
            onClick={() => handleModalAttachementOpen(element.id, 1)}
          ></Button>
        </Col>
      </Row>
    </div>
  );
};

const returnCardsClass = {
  RegresaCard,
};

export default returnCardsClass;
