const ReturnStatusTag = (status) => {
  let tag = {};
  switch (status) {
    case '0':
      tag = { color: '#FF7929', text: 'No asignado' };
      break;
    case '1':
      tag = { color: '#ffc300', text: 'Asignado' };
      break;
    case '2':
      tag = { color: '#0088ff', text: 'Trabajando' };
      break;
    case '3':
      tag = { color: '#16d0a7', text: 'Terminado' };
      break;
    case '4':
      tag = { color: '#00BD04', text: 'Finalizado' };
      break;
    case '5':
      tag = { color: '#ff0000', text: 'Cancelado' };
      break;
    default:
      tag = { color: '#ff00bb', text: 'Otro' };
  }
  return tag;
};
export default ReturnStatusTag;
