import React from 'react';
import Login from './pages/auth/Login';
import DashBoard from './pages/DashBoard';
import ListaTickets from './pages/usuarios/ListaTickets';
import UserChangePassword from './pages/auth/UserChangePassword';
import ListaTicketsAdmin from './pages/soporte/ListaTickets';
import DetalleTicketAdmin from './pages/soporte/DetalleTicket';
import CreaTicket from './pages/usuarios/CrearTicket';
import DetalleTicket from './pages/usuarios/DetalleTicket';

import Solicitudes from './pages/usuarios/Solicitudes';

import SolicitudesSoporte from './pages/soporte/Solicitudes';

import Catalogos from './pages/soporte/Catalogos';
import BaseConocimiento from './pages/soporte/BaseConocimiento';

import SolicitudAutorizada from './pages/SolicitudAutorizada';

import SolicitudMensaje from './pages/SolicitudMensaje';

import SolicitudRechazada from './pages/SolicitudRechazada';
import SolicitudRechazo from './pages/SolicitudRechazo';

import RespuestaHardwareSoftware from './pages/RespuestaHardwareSoftware';

import { Routes, Route, Redirect, Navigate } from 'react-router-dom';

const App = () => {
  console.error = (error) => {};
  console.warn = (warning) => {};

  function PrivateRoute({ component: Component, ...rest }) {
    // useAuth es un hook personalizado para obtener el estado de autenticación del usuario actual

    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<DashBoard />} />
        <Route exact path="/login" element={<Login />}></Route>

        <Route
          exact
          path="/solicitud-autorizada"
          element={<SolicitudAutorizada />}
        ></Route>

        <Route
          exact
          path="/solicitud-mensaje"
          element={<SolicitudMensaje />}
        ></Route>

        <Route
          exact
          path="/respuesta-solicitud/:token"
          element={<RespuestaHardwareSoftware />}
        ></Route>

        <Route
          path="/respuesta-solicitud"
          element={<Navigate to="/respuesta-solicitud/token" />}
        />

        <Route
          exact
          path="/solicitud-rechazada/:parametro"
          element={<SolicitudRechazada />}
        ></Route>

        <Route
          exact
          path="/solicitud-rechazo"
          element={<SolicitudRechazo />}
        ></Route>

        <Route
          path="/userchangepassword/:token"
          element={<UserChangePassword />}
        ></Route>
        <Route exact path="/home" element={<DashBoard />}></Route>

        <Route exact path="/crea-ticket" element={<CreaTicket />}></Route>

        <Route exact path="/detalle-ticket" element={<DetalleTicket />}></Route>

        <Route exact path="/lista-tickets" element={<ListaTickets />}></Route>

        <Route
          exact
          path="/lista-tickets-soporte"
          element={<ListaTicketsAdmin />}
        ></Route>
        <Route
          exact
          path="/detalle-ticket-soporte"
          element={<DetalleTicketAdmin />}
        ></Route>

        <Route exact path="/catalogos" element={<Catalogos />}></Route>

        {/* USUARIOS */}
        <Route exact path="/solicitudes" element={<Solicitudes />}></Route>

        <Route
          exact
          path="/solicitudes-soporte/:opcion/:subopcion/:token"
          element={<SolicitudesSoporte />}
        ></Route>

        <Route
          path="/solicitudes-soporte"
          element={<Navigate to="/solicitudes-soporte/acceso/alta/valor" />}
        />

        <Route
          path="/solicitudes-soporte/:opcion"
          element={<Navigate to="/solicitudes-soporte/acceso/alta/valor" />}
        />

        <Route
          path="/solicitudes-soporte/:opcion/:subopcion"
          element={<Navigate to="/solicitudes-soporte/acceso/alta/valor" />}
        />

        <Route
          exact
          path="/base-conocimientos"
          element={<BaseConocimiento />}
        ></Route>
      </Routes>
    </div>
  );
};
export default App;
