import moment from 'moment';

import returnDateFormatClass from '../clases/returnDateFormat.class';

const RegresaTiempoTranscurrido = (fecha) => {
  let fecha_desde =
    returnDateFormatClass.regresaMomentFromNowTimezoneLocal(fecha);

  fecha_desde = fecha_desde.replace('ago', '');
  fecha_desde = fecha_desde.replace('days', 'días');
  fecha_desde = fecha_desde.replace('hours', 'horas');
  fecha_desde = fecha_desde.replace('minutes', 'minutos');
  fecha_desde = fecha_desde.replace('seconds', 'segundos');
  fecha_desde = fecha_desde.replace('a day', 'un día');
  fecha_desde = fecha_desde.replace('a month', 'un mes');
  fecha_desde = fecha_desde.replace('a minute', 'un minuto');
  fecha_desde = fecha_desde.replace('an hour', 'una hora');
  fecha_desde = fecha_desde.replace('a few', 'unos');
  fecha_desde = fecha_desde.replace('a year', 'un año');
  fecha_desde = fecha_desde.replace('in', 'en');
  fecha_desde = fecha_desde.replace('minute', 'minuto');
  fecha_desde = fecha_desde.replace('hour', 'hora');
  fecha_desde = fecha_desde.replace('menutos', 'minutos');
  fecha_desde = fecha_desde.replace('menuto', 'minuto');
  fecha_desde = fecha_desde.replace('months', 'meses');
  return fecha_desde;
};
export default RegresaTiempoTranscurrido;
