import React, { useState, useEffect } from 'react';
import AuthService from '../services/auth.service';
import { useNavigate } from 'react-router-dom';

import {
  Card,
  Col,
  Row,
  Typography,
  Layout,
  ConfigProvider,
  Space,
  Button,
} from 'antd';
import Logo from '../componentes/Logo';

import { LoginOutlined } from '@ant-design/icons';

const { Header, Footer, Sider, Content } = Layout;

const { Meta } = Card;

const headerStyle = {
  textAlign: 'right',
  color: '#fff',
  height: 75,
  paddingInline: 50,
  paddingTop: 4,
  lineHeight: '45px',
  backgroundColor: '#061928',
};

const contentStyle = {
  textAlign: 'center',
  minHeight: 600,
  minWidth: 980,
  lineHeight: '120px',
  color: '#000000',
  backgroundColor: '#dedede',
  padding: 60,
};

const RespuestaSolicitud = () => {
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const [divHeight, setdivHeight] = useState(300);

  const handleLogin = () => {
    navigate('/');
  };

  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    //console.log('USER HOOK', usr);
    setUser(usr);

    setdivHeight(window.innerHeight - 100);

    const handleResize = () => {
      setdivHeight(window.innerHeight - 100);
    };

    // if (usr === null || usr === undefined) {
    //   AuthService.logout();
    //   navigate("/login");
    // }
    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#04142b',
        },
      }}
    >
      <Space
        direction="vertical"
        style={{
          width: '100%',
        }}
        size={[0, 48]}
      >
        <Layout>
          <Header style={headerStyle}>
            <Logo />
          </Header>
          <Layout style={{ height: window.innerHeight - 80 }}>
            <Content style={contentStyle}>
              <div
                id="contenedor-admin"
                className="content-main"
                style={{
                  backgroundColor: 'white',
                  height: '100%',
                  padding: 20,
                  minWidth: 800,
                  display: 'flex',
                  justifyContent: 'center',
                  maxHeight: divHeight,
                  overflow: 'auto',
                }}
              >
                <Row>
                  <Col>
                    <Typography.Title
                      level={1}
                      style={{ color: '#081b3e', marginTop: 150 }}
                    >
                      La solicitud se autorizó correctamente.
                    </Typography.Title>
                    <Button
                      style={{ width: 190, height: 60 }}
                      type="primary"
                      onClick={() => handleLogin()}
                    >
                      Iniciar Sesión <LoginOutlined />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Content>
          </Layout>
        </Layout>
      </Space>
    </ConfigProvider>
  );
};
export default RespuestaSolicitud;
