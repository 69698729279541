import React, { useState, useEffect, useContext } from 'react';
import AuthService from '../services/auth.service';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  Col,
  Row,
  Typography,
  Layout,
  ConfigProvider,
  Space,
  Button,
  Input,
} from 'antd';
import Logo from '../componentes/Logo';

import { ComponentesContext } from '../contextos/componentesContext';

import SolicitudesService from '../services/solicitudes.Service';

import { CloseCircleOutlined, LoginOutlined } from '@ant-design/icons';

const { Header, Footer, Sider, Content } = Layout;
const { TextArea } = Input;
const { Meta } = Card;

const headerStyle = {
  textAlign: 'right',
  color: '#fff',
  height: 75,
  paddingInline: 50,
  paddingTop: 4,
  lineHeight: '45px',
  backgroundColor: '#061928',
};

const contentStyle = {
  textAlign: 'center',
  minHeight: 600,
  minWidth: 980,
  lineHeight: '120px',
  color: '#000000',
  backgroundColor: '#dedede',
  padding: 60,
};

const SolicitudRechazada = ({ match }) => {
  const {
    ShowLoading,
    notificationsCounter,
    UpdateNotificationsCounter,
    UpdateDataLista,
    UpdatesettableHeight,
    ModalMessage,
  } = useContext(ComponentesContext);

  const { parametro } = useParams();
  const [motivoRechazo, setmotivoRechazo] = useState('');
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  const [divHeight, setdivHeight] = useState(300);

  const [divMotivo, setdivMotivo] = useState('block');
  const [divMensaje, setdivMensaje] = useState('none');

  const [mensaje, setmensaje] = useState(
    'La solicitud se rechazo correctamente.'
  );

  const handleGuardaRechazo = async () => {
    const jsonPost = {
      motivo_rechazo: motivoRechazo,
      token_solicitud: parametro,
    };

    //console.log(jsonPost);
    let respuesta =
      await SolicitudesService.postGuardaRechazoSolicitud(jsonPost);
    //console.log(respuesta);

    if (respuesta.estado == 200) {
      setdivMotivo('none');
      setdivMensaje('block');
    } else {
      ModalMessage('Mensaje', 'Error al guardar el rechazo de la solicitud.');
    }
  };

  const handleLogin = () => {
    navigate('/');
  };

  const handleCargaFormulario = async () => {
    const jsonPost = { token_solicitud: parametro };
    let respuesta =
      await SolicitudesService.postRegresaStatusSolicitud(jsonPost);
    //console.log(respuesta);

    if (respuesta.estado == 200) {
      setmensaje('La solicitud se rechazo correctamente.');
      if (respuesta.status == 2) {
        setdivMotivo('none');
        setdivMensaje('block');
      } else {
        setdivMotivo('block');
        setdivMensaje('none');
      }
    } else {
      setmensaje('La solicitud proporcionada no existe.');
      setdivMotivo('none');
      setdivMensaje('block');
    }
  };

  useEffect(() => {
    let usr = AuthService.getCurrentUser();
    //console.log('USER HOOK', usr);
    setUser(usr);

    if (typeof parametro != 'undefined') {
      //console.log(parametro);
    }

    setdivHeight(window.innerHeight - 100);

    const handleResize = () => {
      setdivHeight(window.innerHeight - 100);
    };

    handleCargaFormulario();

    window.addEventListener('resize', handleResize);
    // if (usr === null || usr === undefined) {
    //   AuthService.logout();
    //   navigate("/login");
    // }
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#04142b',
        },
      }}
    >
      <Space
        direction="vertical"
        style={{
          width: '100%',
        }}
        size={[0, 48]}
      >
        <Layout>
          <Header style={headerStyle}>
            <Logo />
          </Header>
          <Layout style={{ height: window.innerHeight - 80 }}>
            <Content style={contentStyle}>
              <div
                id="contenedor-admin"
                className="content-main"
                style={{
                  backgroundColor: 'white',
                  height: '100%',
                  padding: 20,
                  minWidth: 800,
                  display: 'flex',
                  justifyContent: 'center',
                  maxHeight: divHeight,
                  overflow: 'auto',
                }}
              >
                <div style={{ display: divMotivo }}>
                  <Typography.Title
                    level={2}
                    style={{ color: '#081b3e', marginTop: 120 }}
                  >
                    Capture el motivo del rechazo de la solicitud:
                  </Typography.Title>

                  <div
                    style={{
                      width: '100%',
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                  >
                    <TextArea
                      onChange={(e) => {
                        setmotivoRechazo(e.target.value);
                      }}
                      rows={4}
                      placeholder="Justificación del rechazo de la solicitud."
                      style={{ resize: 'none', width: '100%' }}
                    />
                  </div>

                  <Button
                    style={{ width: 190, height: 60, fontWeight: 'bold' }}
                    type="primary"
                    danger
                    onClick={() => handleGuardaRechazo()}
                    icon={<CloseCircleOutlined />}
                  >
                    Rechazar solicitud
                  </Button>
                </div>

                <div style={{ display: divMensaje }}>
                  <Typography.Title
                    level={1}
                    style={{ color: '#081b3e', marginTop: 150 }}
                  >
                    {mensaje}
                  </Typography.Title>

                  <Button
                    style={{ width: 190, height: 60 }}
                    type="primary"
                    onClick={() => handleLogin()}
                  >
                    Iniciar Sesión <LoginOutlined />
                  </Button>
                </div>
              </div>
            </Content>
          </Layout>
        </Layout>
      </Space>
    </ConfigProvider>
  );
};
export default SolicitudRechazada;
