import React, { useState, useEffect, useContext, useRef } from 'react';

import { Button, Select, Input, Row, Col, Divider, Table, Modal } from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import moment from 'moment';
const { TextArea } = Input;
import UtilsClass from '../../clases/Utils.class';
import { ComponentesContext } from '../../contextos/componentesContext';
import SolicitudesService from '../../services/solicitudes.Service';
import TicketsService from '../../services/tickets.Service';
import CatalogosService from '../../services/catalogos.Service';
import getColumnSearchProps from '../SearchPropsCustom';

import returnDateFormatClass from '../../clases/returnDateFormat.class';
import returnTagClass from '../../clases/returnTags.class';

export default function SolicitudAltaHardware(prop) {
  const tableRefSolicitudes = useRef();

  const { tableHeight, ShowLoading, ModalMessage } =
    useContext(ComponentesContext);

  const [flagFolioVisible, setflagFolioVisible] = useState(false);
  const [inputFolio, setinputFolio] = useState('');
  const [inputCorreoBeneficio, setinputCorreoBeneficio] = useState('');
  const [inputEspecifique, setinputEspecifique] = useState('');
  const [inputJustificacion, setinputJustificacion] = useState('');
  const [DataSolicitudes, setDataSolicitudes] = useState([]);
  const [formBeneficiario, setformBeneficiario] = useState('');
  const [formPuesto, setformPuesto] = useState('');
  const [formComponente, setformComponente] = useState('');
  const [optionComponentes, setoptionComponentes] = useState('');
  const [optionPuestos, setoptionPuestos] = useState('');
  const [optionUsuarios, setoptionUsuarios] = useState('');
  const divInternoVisible = '';
  const divSistemas = '';
  const [divExternoVisible, setdivExternoVisible] = useState('none');
  const [isModalOpen, setisModalOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const [idSolicitud, setidSolicitud] = useState(null);
  const searchInput = useRef(null);

  const [disableButton, setdisableButton] = useState(false);

  useEffect(() => {
    if (prop.selectedTab == 'alta') {
      InicializaFormulario();
    }
  }, [prop.selectedTab]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const InicializaFormulario = async () => {
    getUsuariosData();
    getComponentesData();
    getPuestosData();
  };

  const getUsuariosData = async () => {
    let UsuariosData = await TicketsService.getListaDatosUsuarios();

    if (UsuariosData.estado != 200) {
      ModalMessage(
        'Mensaje',
        'Se produjo un error al cargar los datos, favor de reportar al area de sistemas.'
      );
      return false;
    }

    setformBeneficiario(UsuariosData.usuarioDefault);

    let solicitudesData = await SolicitudesService.postListSolicitudesHardware({
      id_usuario_beneficiario: UsuariosData.usuarioDefault,
      tipo_solicitud: 2,
    });

    setDataSolicitudes(solicitudesData.data);

    const filteredDataBeneficiario = UsuariosData.data.filter(
      (item) => parseInt(item.id) === UsuariosData.usuarioDefault
    );

    if (filteredDataBeneficiario.length > 0) {
      setformPuesto(filteredDataBeneficiario[0].id_puesto);
      setinputCorreoBeneficio(filteredDataBeneficiario[0].correo);
    }

    setoptionUsuarios(
      UsuariosData.data.map((item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.nombre_completo}</div>,
        nombre: item.nombre_completo,
        correo: item.correo,
        puesto: item.id_puesto,
      }))
    );
  };

  const getComponentesData = async () => {
    let ComponentesData = await CatalogosService.getListaComponentes(2);

    setoptionComponentes(
      ComponentesData.data.map((item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.nombre}</div>,
        nombre: item.nombre,
      }))
    );
  };

  const getPuestosData = async () => {
    let puestosData = await CatalogosService.getListaPuestos();

    setoptionPuestos(
      puestosData.data.map((item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.descripcion}</div>,
        nombre: item.descripcion,
      }))
    );
  };

  const handleBeneficiarioChange = (e, option) => {
    setformBeneficiario(e);
    setformPuesto(option.puesto);
    setinputCorreoBeneficio(option.correo);
  };

  const handleSelectChange = (value) => {
    setSelectedValue(value.toString());
  };

  const HandleAgregarSolicitud = async () => {
    if (formComponente == '') {
      ModalMessage('Mensaje', 'El Componente no puede estar en blanco.');
      setdisableButton(false);
      return false;
    }

    if (inputJustificacion == '') {
      ModalMessage('Mensaje', 'La justificación no puede estar en blanco.');
      setdisableButton(false);
      return false;
    }

    ShowLoading(true);
    const solicitudes = {
      id_solicitud: idSolicitud,
      id_usuario_beneficiario: formBeneficiario,
      tipo_solicitud: 2,
      id_elemento: formComponente,
      otro: inputEspecifique,
      justificacion: inputJustificacion,
    };

    let SolicitudesData =
      await SolicitudesService.postRegistrarSolicitudHardware(solicitudes);

    if (SolicitudesData.estado == 200) {
      getUsuariosData();
      ModalMessage('Mensaje', 'La solicitud se registro correctamente.');
      setisModalOpen(false);
    } else {
      ModalMessage(
        'Mensaje',
        'Error al registrar la solicitud, favor de reportar a el area de sistemas.'
      );
    }

    setdisableButton(false);
    ShowLoading(false);
  };

  const handleOpenModal = async (record) => {
    ShowLoading(true);

    let UsuariosData = await TicketsService.getListaDatosUsuarios();

    if (UsuariosData.estado != 200) {
      ModalMessage(
        'Mensaje',
        'Se produjo un error al cargar los datos, favor de reportar al area de sistemas.'
      );
      return false;
    }

    setidSolicitud(record.id);

    setinputFolio(String(record.id).padStart(5, '0'));
    setformBeneficiario(record.id_usuario);

    const filteredDataBeneficiario = UsuariosData.data.filter(
      (item) => parseInt(item.id) === record.id_usuario
    );

    if (filteredDataBeneficiario.length > 0) {
      setformPuesto(filteredDataBeneficiario[0].id_puesto);
      setinputCorreoBeneficio(filteredDataBeneficiario[0].correo);
    }

    setformComponente(record.id_componente);
    setinputJustificacion(record.justificacion.toString().toUpperCase());

    ShowLoading(false);

    setflagFolioVisible(true);

    setisModalOpen(true);
  };

  const columnsSolicitudes = [
    {
      title: 'Opciones',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 100,
      render: (_, record) => (
        <div>
          {record.id_status == 0 ? (
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => {
                handleOpenModal(record);
              }}
              style={{ width: 40, height: 34 }}
            ></Button>
          ) : (
            <></>
          )}
        </div>
      ),
    },

    {
      width: 110,
      title: 'Folio',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => <div>{String(record.id).padStart(5, '0')}</div>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      width: 170,
      title: 'Estatus',
      key: 'status',
      dataIndex: 'status',
      filters: [
        {
          text: 'Pendiente',
          value: 'Pendiente',
        },
        {
          text: 'Verificado',
          value: 'Verificado',
        },
        {
          text: 'Autorizado',
          value: 'Autorizado',
        },
        {
          text: 'Entregado',
          value: 'Entregado',
        },
        {
          text: 'Rechazado',
          value: 'Rechazado',
        },
        {
          text: 'Cancelado',
          value: 'Cancelado',
        },
        {
          text: 'Otro',
          value: 'Otro',
        },
      ],
      onFilter: (value, record) => record.status.startsWith(value),
      sorter: (a, b) => a.status.length - b.status.length,
      render: (_, record) => (
        <>
          &nbsp;&nbsp;&nbsp;&nbsp;
          {returnTagClass.ReturnTipoSolicitudHardwareSoftwareTag(record.status)}
        </>
      ),
    },
    {
      width: 400,
      title: 'Software',
      dataIndex: 'nombre',
      key: 'nombre',
      ...getColumnSearchProps({
        dataIndex: 'nombre',
        searchInput,
        handleSearch,
        handleReset,
        setSearchText,
        setSearchedColumn,
        searchText,
        searchedColumn,
      }),
    },
    {
      title: 'No. Ticket',
      dataIndex: 'id_ticket',
      key: 'id_ticket',
      align: 'center',

      render: (_, record) => (
        <div>
          <labler>
            {record.tipo_requerimiento != 1 ? record.elemento : record.sistema}
          </labler>
        </div>
      ),
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha_registro',
      key: 'fecha_registro',
      width: 120,
      defaultSortOrder: 'descend',
      sorter: (a, b) =>
        moment(a.fecha_registro).unix() - moment(b.fecha_registro).unix(),
      render: (_, record) => (
        <>
          {returnDateFormatClass.regresaFormatoFechaTimeZone(
            record.fecha_registro
          )}
        </>
      ),
    },
  ];

  return (
    <div
      style={{
        width: '98%',
        minWidth: 1050,
      }}
    >
      <Modal
        closable={false}
        destroyOnClose={true}
        open={isModalOpen}
        width={950}
        style={{ minWidth: 950 }}
        title={
          <div>
            <EditOutlined /> &nbsp; Registrar solicitud de Software
          </div>
        }
        footer={[
          <Button
            key={'btnGuardar'}
            type="primary"
            onClick={() => {
              setdisableButton(true);
              HandleAgregarSolicitud();
            }}
            disabled={disableButton}
          >
            <SaveOutlined />
            Guardar
          </Button>,
          <Button
            key={'btnCerrar'}
            type="primary"
            onClick={() => {
              setformBeneficiario('');
              setformPuesto('');
              setinputJustificacion('');
              setisModalOpen(false);
            }}
            danger
          >
            <CloseOutlined />
            Cerrar
          </Button>,
        ]}
      >
        <Divider orientation="left" style={{ fontWeight: 'bold' }}>
          Datos del beneficiario
        </Divider>

        <Row
          gutter={16}
          style={{
            marginTop: 20,
            display: flagFolioVisible ? 'flex' : 'none',
            justifyContent: 'flex-end',
            marginLeft: -40,
            marginRight: 50,
          }}
        >
          <Col
            span={4}
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            Folio:
          </Col>
          <Col>
            <Input
              maxLength={60}
              style={{ width: 100 }}
              onChange={(e) => {
                setinputFolio(e.target.value);
              }}
              disabled
              value={inputFolio}
            />
          </Col>
        </Row>

        <Row
          gutter={16}
          style={{ marginTop: 20, display: divInternoVisible, marginLeft: -40 }}
        >
          <Col
            span={4}
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            Nombre:
          </Col>
          <Col>
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Seleccione una opción"
              options={optionUsuarios}
              value={formBeneficiario}
              onChange={(e, option) => {
                handleBeneficiarioChange(e, option);
              }}
              disabled
              filterOption={UtilsClass.filterOption}
              style={{ textAlign: 'left', width: 720 }}
            />
          </Col>
        </Row>

        <Row
          gutter={16}
          style={{ marginTop: 20, display: divInternoVisible, marginLeft: -40 }}
        >
          <Col
            span={4}
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            Puesto:
          </Col>
          <Col>
            <Select
              disabled
              style={{ textAlign: 'left', width: 320 }}
              options={optionPuestos}
              onChange={handleSelectChange}
              value={formPuesto}
            />
          </Col>

          <Col
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            Correo:
          </Col>
          <Col>
            <Input
              maxLength={60}
              style={{ width: 320 }}
              onChange={(e) => {
                setinputCorreoBeneficio(e.target.value);
              }}
              disabled
              value={inputCorreoBeneficio}
            />
          </Col>
        </Row>

        <Divider orientation="left" style={{ fontWeight: 'bold' }}>
          Datos del Software
        </Divider>

        <Row
          gutter={16}
          style={{ marginTop: 20, display: divSistemas, marginLeft: -40 }}
        >
          <Col
            span={4}
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            Software:
          </Col>
          <Col>
            <Select
              style={{ textAlign: 'left', width: 720 }}
              options={optionComponentes}
              onChange={(e, val) => {
                setformComponente(e);

                if (val.nombre == 'OTRO') {
                  setdivExternoVisible('');
                } else {
                  setdivExternoVisible('none');
                }
              }}
              value={formComponente}
            />
          </Col>
        </Row>

        <Row
          gutter={16}
          style={{ marginTop: 20, display: divExternoVisible, marginLeft: -40 }}
        >
          <Col
            span={4}
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            Especifique:
          </Col>
          <Col>
            <Input
              maxLength={60}
              style={{ width: 720 }}
              onChange={(e) => {
                setinputEspecifique(e.target.value);
              }}
              value={inputEspecifique}
            />
          </Col>
        </Row>

        <Row
          gutter={16}
          style={{ marginTop: 20, marginLeft: -40, marginBottom: 40 }}
        >
          <Col span={4} style={{ textAlign: 'right' }}>
            Justificación:
          </Col>
          <Col>
            <TextArea
              rows={3}
              value={inputJustificacion}
              onChange={(e) => {
                setinputJustificacion(e.target.value);
              }}
              style={{ width: 720 }}
            />
          </Col>
        </Row>
      </Modal>

      <div
        style={{
          marginTop: 50,
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 15,
        }}
      >
        <Button
          style={{ width: 200, height: 50 }}
          type="primary"
          onClick={() => {
            setidSolicitud(null);
            getUsuariosData();
            setformComponente('');
            setinputJustificacion('');
            setdivExternoVisible('none');

            setflagFolioVisible(false);
            setisModalOpen(true);
          }}
          icon={<PlusOutlined />}
        >
          Registrar Solicitud
        </Button>
      </div>

      <Table
        rowKey="id"
        columns={columnsSolicitudes}
        dataSource={DataSolicitudes}
        pagination={false}
        ref={tableRefSolicitudes}
        style={{ height: tableHeight }}
        scroll={{
          y: tableHeight,
        }}
      />
    </div>
  );
}
