import React from 'react';

import { Badge } from 'antd';

const returnNotificationCount = (cantidad) => {
  return <Badge count={cantidad} />;
};

const returnNotificationsClass = {
  returnNotificationCount,
};

export default returnNotificationsClass;
