
const returnValidUploadFile = async (fileType) => {
    switch (fileType) {
      case "image/jpeg":
      case "image/avif":
      case "image/gif":
      case "image/jpg":
      case "image/jpeg":
      case "image/jfif":
      case "image/pjpeg":
      case "image/pjp":
      case "image/png":
      case "image/svg":
      case "image/webp":
      case "image/bmp":
      case "image/ico":
      case "image/cur":
      case "image/tif":
      case "image/tiff":
      case "image/x-icon":
      case "application/pdf":
      case "text/csv":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      case "application/vnd.ms-powerpoint":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "application/vnd.ms-excel":
        return true;
        break;
      default:
        return false;
    }
  }


  const returnImageValidUploadFile = async (fileType) => {
    switch (fileType) {
      case "image/jpeg":
      case "image/avif":
      case "image/gif":
      case "image/jpg":
      case "image/jpeg":
      case "image/jfif":
      case "image/pjpeg":
      case "image/pjp":
      case "image/png":
      case "image/svg":
      case "image/webp":
      case "image/bmp":
      case "image/ico":
      case "image/cur":
      case "image/tif":
      case "image/tiff":
      case "image/x-icon":
        return true;
        break;
      default:
        return false;
    }
  }

  const returnPdfValidUploadFile = async (fileType) => {
    switch (fileType) {
      case "application/pdf":
        return true;
        break;
      default:
        return false;
    }
  }

  const returnValidUploadFileClass = {
    returnValidUploadFile,
    returnImageValidUploadFile,
    returnPdfValidUploadFile
  };
  
  export default returnValidUploadFileClass;