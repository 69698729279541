import React, { useState, useEffect, useContext, useRef } from 'react';
import AuthService from '../../services/auth.service';
import NotificationsService from '../../services/nofications.Service';
import BaseConocimientoService from '../../services/baseConocimiento.Service';

import { useNavigate, useLocation } from 'react-router-dom';
import './DashBoard.css';
import ModalMessageCustom from '../../componentes/ModalMessageCustom';
import ModalDocumentViewer from '../../componentes/ModalDocumentViewer';
import ReturnStatusTag from '../../componentes/ReturnStatusTag';

import ReturnUserTypeClass from '../../clases/returnUserType.class';
import returnValidUploadFileClass from '../../clases/returnValidUploadFile.class';

import returnDateFormatClass from '../../clases/returnDateFormat.class';
import returnCardsClass from '../../clases/returnCards.class';

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import {
  Card,
  Button,
  Upload,
  Typography,
  Tag,
  Avatar,
  Modal,
  Timeline,
  Modal as ModalAttachement,
  Modal as ModalPreview,
  Select,
  Skeleton,
  Spin,
  Row,
  Col,
  ConfigProvider,
  Empty,
  Tooltip,
} from 'antd';
import {
  FileImageOutlined,
  ContainerOutlined,
  PaperClipOutlined,
  PlusOutlined,
  CloseOutlined,
  CommentOutlined,
  UserOutlined,
  RobotOutlined,
  FolderViewOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import Structure from '../../componentes/StructureAdmin';
import { ComponentesContext } from '../../contextos/componentesContext';
import moment from 'moment';
import TicketsService from '../../services/tickets.Service';
import { Editor } from '@tinymce/tinymce-react';

const { Meta } = Card;

const DetalleTicket = () => {
  const { confirm } = Modal;

  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const {
    openNotification,
    UpdateNotificationsCounter,
    ModalMessage,
    divContentWidth,
    ShowLoading,
    tableHeight,
  } = useContext(ComponentesContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [color, setcolor] = useState('');
  const [text, settext] = useState('');
  const [folio, setfolio] = useState('');
  const [fecha, setfecha] = useState('');
  const [status_ticket, setstatus_ticket] = useState('');
  const [nombreCompleto, setnombreCompleto] = useState('');
  const [canAnswerButtonHidden, setcanAnswerButtonHidden] = useState('');
  const [ValueStatus, setValueStatus] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingModal, setloadingModal] = useState(false);
  const [canRemove, setcanRemove] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalAttachementOpen, setisModalAttachementOpen] = useState(false);
  const [modalTitle, setmodalTitle] = useState('');
  const [detalleTicket, setdetalleTicket] = useState('');
  const [TituloTicket, setTituloTicket] = useState('');

  const [isModalEvidenciasOpen, setisModalEvidenciasOpen] = useState(false);

  const [flagAdjuntarModal, setflagAdjuntarModal] = useState(false);

  const IdTicket =
    typeof location.state.record.id_ticket != 'undefined' ||
    location.state.record.id_ticket != null
      ? location.state.record.id_ticket
      : '';

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setfileList] = useState([]);

  const [ModelAttachementType, setModelAttachementType] = useState(0);
  const editorRef = useRef(null);
  const [isResponseModalOpen, setisResponseModalOpen] = useState(false);
  const [OpenModelDocument, setOpenModelDocument] = useState(false);
  const [pathDocument, setpathDocument] = useState([]);
  const [messageList, setmessageList] = useState([]);

  const [optionBaseConocimiento, setoptionBaseConocimiento] = useState([]);
  const [enableOptionBaseConocimiento, setenableOptionBaseConocimiento] =
    useState(true);
  const [ValueBaseConocimiento, setValueBaseConocimiento] = useState('0');

  const [disableButton, setdisableButton] = useState(false);

  const ObtieneComboBaseConocimiento = async () => {
    let respuesta = await BaseConocimientoService.getComboBaseConocimiento();
    setoptionBaseConocimiento(respuesta.data);
    return true;
  };

  const getDetalleTicketsData = async (id_ticket) => {
    const ticket = {
      id_ticket: id_ticket,
    };

    let notificationsData =
      await NotificationsService.postUpdateNotifications(ticket);

    if (notificationsData.estado == 500) {
      ModalMessage('Mensaje', 'Error al obtener los datos del ticket.');
      return false;
    }

    UpdateNotificationsCounter(notificationsData.data.length);

    let ticketsData = await TicketsService.getDetalleTickets(ticket);
    let tag = ReturnStatusTag(ticketsData.data[0].status);
    setcolor(tag.color);
    settext(tag.text);
    setstatus_ticket(ticketsData.data[0].status);
    setTituloTicket(ticketsData.data[0].titulo);
    setfolio(ticketsData.data[0].ticket);
    setfecha(
      returnDateFormatClass.regresaFormatoFechaTimeZone(
        ticketsData.data[0].fecha
      )
    );
    setnombreCompleto(ticketsData.data[0].nombre_completo);
    setdetalleTicket(ticketsData.data[0].descripcion);

    if (ticketsData.data[0].status == '4') {
      setcanAnswerButtonHidden('none');
    } else {
      setcanAnswerButtonHidden('');
    }

    return true;
  };

  useEffect(() => {
    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate('/login');
    }

    moment.locale('es-mx');

    InicializaFormulario();

    setTimeout(function () {}, 400);
  }, []);

  const InicializaFormulario = async () => {
    await getDetalleTicketsData(IdTicket);
    await handleGetTicketsDetail();
    await ObtieneComboBaseConocimiento();

    setLoading(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const ShowModalDocument = () => {
    setmodalTitle('Datos del ticket');
    setIsModalOpen(true);
  };

  const OpenResponseModal = () => {
    setValueStatus(1);
    setisResponseModalOpen(true);
    setcanRemove(true);
  };

  const handleModalResponseClose = () => {
    setflagAdjuntarModal(false);
    setisResponseModalOpen(false);
  };

  const handleSaveDetail = async () => {
    ShowLoading(true);
    let tipo_usuario = await ReturnUserTypeClass.ReturnUserType();

    if (editorRef.current.getContent() == '') {
      ModalMessage(
        'Mensaje',
        'La respuesta del ticket no puede estar en blanco.'
      );
      setdisableButton(false);
      ShowLoading(false);
      return false;
    }

    const ticket = {
      id_ticket: IdTicket,
      mensaje: editorRef.current.getContent(),
      tipo_usuario: tipo_usuario.data.tipo,
      status: ValueStatus,
      status_ticket: status_ticket,
      rate: 0,
      id_base_conocimiento: ValueBaseConocimiento,
    };

    let ticketsData = await TicketsService.postCreateDetailTicket(ticket);

    const notificacion = {
      usuario_envia: ticketsData.usuario_envia,
      usuario_a_enviar: ticketsData.usuario_enviar,
      estatus_ticket: ticketsData.estatus_ticket,
      id_ticket: IdTicket,
    };

    await NotificationsService.postSendNotificacionMail(notificacion);

    editorRef.current.setContent('');

    await handleGetTicketsDetail();
    setisResponseModalOpen(false);
    await getDetalleTicketsData(IdTicket);
    setValueBaseConocimiento('0');

    setdisableButton(false);

    if (ValueStatus == 3) {
      let respuesta = await TicketsService.getEvidencias(IdTicket);

      if (respuesta.estado != 200) {
        showConfirm();
      } else {
        openNotification('Se registro la respuesta del ticket', 'top');
      }
    } else {
      openNotification('Se registro la respuesta del ticket', 'top');
    }

    ShowLoading(false);
  };

  const handleModalAttachementOpen = async (id_ticket_detalle, tipo) => {
    let AdjuntosData = await TicketsService.postAdjuntosTicket({
      id_ticket: IdTicket,
      tipo: tipo,
      id_ticket_detalle: id_ticket_detalle,
      id_base_conocimiento: 0,
    });
    setfileList(AdjuntosData.data);
    setloadingModal(false);
    setcanRemove(false);

    setModelAttachementType(0);
    setisModalAttachementOpen(true);
  };

  const handleModalResponseAttachementOpen = async () => {
    let DetalleAdjuntosData = await TicketsService.postRegresaTemporales({
      id_ticket: IdTicket,
      tipo: 1,
      id_ticket_detalle: 0,
      id_base_conocimiento: 0,
    });
    setfileList(DetalleAdjuntosData.data);
    setcanRemove(true);
    setModelAttachementType(1);
    setisModalAttachementOpen(true);
    setloadingModal(false);
  };

  const handleModalAttachementClose = () => {
    setisModalAttachementOpen(false);
  };

  const handlePreview = async (file) => {
    const fileExtension = file.nombre_archivo.split('.').pop();

    switch (fileExtension.toLowerCase()) {
      case 'apng':
      case 'avif':
      case 'gif':
      case 'jpg':
      case 'jpeg':
      case 'jfif':
      case 'pjpeg':
      case 'pjp':
      case 'png':
      case 'svg':
      case 'webp':
      case 'bmp':
      case 'ico':
      case 'cur':
      case 'tif':
      case 'tiff':
        file.preview = file.url;
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        break;
      case 'pdf':
        setpathDocument(file.url);
        setOpenModelDocument(true);
        break;
    }
  };

  const handleModalDocument = () => {
    setOpenModelDocument(false);
  };

  const handeDownload = async (file) => {
    const urlDownload = `${process.env.REACT_APP_URL_API_SOPORTE}download?ticket=${encodeURIComponent(IdTicket)}&filename=${encodeURIComponent(file.name)}&token=${encodeURIComponent(AuthService.getCurrentToken())}`;
    const response = await fetch(urlDownload);
    if (!response.ok) {
      ModalMessage('Mensaje', 'Se produjo un error al descargar el archivo.');
      return false;
    }
    window.location.href = urlDownload;
  };

  const handleRemove = async (file) => {
    let nombre_archivo = file.name;

    if (file.name == undefined) {
      nombre_archivo = file.nombre_archivo;
    }

    setloadingModal(true);
    let ticketsData = await TicketsService.postDeleteTemporal({
      nombre_archivo,
    });
    if (ticketsData.estado == 200) {
      const DetalleAdjuntosData = await TicketsService.postRegresaTemporales({
        id_ticket: IdTicket,
        tipo: 1,
        id_ticket_detalle: 0,
        id_base_conocimiento: 0,
      });

      if (DetalleAdjuntosData.estado == 200) {
        setfileList(DetalleAdjuntosData.data);
        setcanRemove(true);
        setModelAttachementType(1);
        setisModalAttachementOpen(true);
      }
    }
    setloadingModal(false);
  };

  const handleBeforeUpload = async (file) => {
    let flagArchivoValido =
      await returnValidUploadFileClass.returnValidUploadFile(file.type);
    if (!flagArchivoValido) {
      ModalMessage(
        'Mensaje',
        'El tipo de archivo que se desea adjuntar no es valido.'
      );
      return false;
    }

    const fileSizeLimit = 10 * 1024 * 1024; // 5MB

    if (file.size > fileSizeLimit) {
      ModalMessage('Mensaje', 'El archivo no puede pesar mas de 10MB.');
      return false; // Cancela la carga del archivo
    }

    setloadingModal(true);
    return true;
  };

  const handleGetTicketsDetail = async () => {
    const ticket = {
      id_ticket: IdTicket,
    };
    let ticketsData = await TicketsService.postGetTicketsMessage(ticket);

    let flagColor = 1;
    let color;
    let coloBar;
    let newList = [];

    for (const element of ticketsData.data) {
      if (flagColor > 0) {
        color = '#f5f5f5';
        coloBar = '#e3e3e3';
      } else {
        color = '#dbdbdb';
        coloBar = '#c4c4c4';
      }

      let tag = ReturnStatusTag(element.status.toString());
      flagColor = flagColor * -1;

      newList = newList.concat({
        dot: (
          <Avatar
            src={element.imagen_usuario}
            size="large"
            icon={
              element.tipo == 'Soporte' ? <RobotOutlined /> : <UserOutlined />
            }
            style={{ backgroundColor: '#3c5c7c', marginTop: 0 }}
          />
        ),
        children: returnCardsClass.RegresaCard(
          element,
          returnDateFormatClass.regresaFormatoFechaTimeZone(
            element.fecha_registro
          ),
          color,
          returnDateFormatClass.regresaMomentFromNowTimezoneLocal(
            element.fecha_registro
          ),
          tag.color,
          tag.text,
          coloBar,
          handleModalAttachementOpen
        ),
      });
    }
    setmessageList(newList);

    return true;
  };

  const handleCancel = () => setPreviewOpen(false);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Adjuntar imagen
      </div>
    </div>
  );

  function handleChangeStatus(value) {
    if (value == 3) {
      setenableOptionBaseConocimiento(false);
    } else {
      setenableOptionBaseConocimiento(true);
    }

    setValueStatus(value);
  }

  function handleChangeBaseConocimiento(value) {
    setValueBaseConocimiento(value.toString());
  }

  const handleGuardarEvidencias = async (files, comentarios) => {
    const json = {
      id_ticket: IdTicket,
      files,
      comentarios,
    };

    let respuesta = await TicketsService.postGuardarEvidencia(json);

    if (respuesta.estado != 200) {
      ModalMessage('Mensaje', 'Se produjo un error al guardar la evidencia.');
    }

    setfileList([]);
    editorRef.current.setContent('');
    setisModalEvidenciasOpen(false);

    ModalMessage('Mensaje', 'La evidencia se guardo correctamente.');
  };

  const handleObtenerEvidencias = async () => {
    setloadingModal(true);

    let respuesta = await TicketsService.getEvidencias(IdTicket);

    if (respuesta.estado === 201) {
      setloadingModal(false);
      return false;
    }

    setTimeout(function () {
      const evidencias = JSON.parse(respuesta.data);
      editorRef.current.setContent(evidencias.comentarios);
      setfileList(evidencias.adjuntos);
      setloadingModal(false);
    }, 1000);
  };

  const handleRemoveEvidencia = async (file) => {
    confirm({
      title: 'Confirmación',
      icon: <ExclamationCircleFilled />,
      content: '¿Estás seguro/a de borrar la evidencia?',
      okText: 'Sí',
      cancelText: 'No',
      async onOk() {
        setloadingModal(true);
        let ticketsData = await TicketsService.postBorrarEvidencia({
          id_ticket: IdTicket,
          nombre_archivo: file.nombre_archivo,
          fileList,
        });

        setfileList(ticketsData.data);

        setloadingModal(false);
      },
      onCancel() {},
    });
  };

  const showConfirm = () => {
    confirm({
      title: 'Confirmación',
      icon: <ExclamationCircleFilled />,
      content: '¿Deseas capturar las evidencias?',
      okText: 'Sí',
      cancelText: 'No',
      onOk() {
        TicketsService.getResetTemporales();
        setfileList([]);
        handleObtenerEvidencias();
        setisModalEvidenciasOpen(true);
      },
      onCancel() {},
    });
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: '#3c5c7c',
            colorPrimaryHover: '#517da9',
            colorPrimaryActive: '#517da9',
          },
          Card: {
            headerBg: '#3c5c7c',
          },
        },
      }}
    >
      <Structure>
        <div
          style={{
            minHeight: tableHeight + 260,
            maxHeight: tableHeight + 260,
            overflow: 'auto',
            marginBottom: -20,
            marginTop: 10,
            minWidth: 800,
          }}
        >
          <center>
            {/* <Typography.Title level={2} style={{ margin: 0, color: "#081b3e" }}>
          Detalle del Ticket
        </Typography.Title>
        <Divider style={{ marginTop: 5 }} /> */}
            <div>
              <Typography.Title
                level={5}
                style={{ marginTop: -5, color: '#081b3e' }}
              >
                Datos del Ticket:
              </Typography.Title>
            </div>
            <Skeleton loading={loading} active={true}>
              <Card
                headStyle={{ height: 70 }}
                bodyStyle={{
                  height: 70,
                  overflow: 'hidden',
                  overflowY: 'scroll',
                  padding: 0,
                  margin: 0,
                  maxWidth: divContentWidth + 27,
                  minWidth: 1020,
                }}
                title={
                  <div>
                    <p style={{ lineHeight: 0.2, fontSize: 15 }}>
                      <b>Folio:</b> &nbsp;{folio} &nbsp;{' '}
                      <b>Usuario Solicitó:</b>
                      &nbsp;{nombreCompleto}
                    </p>{' '}
                    <p style={{ lineHeight: 0.2, fontSize: 15 }}>
                      <b>Titulo:</b>"{TituloTicket}" &nbsp; <b>Status:</b>&nbsp;
                      <Tag color={color}>{text.toUpperCase()}</Tag>{' '}
                      <b>Fecha:</b>
                      &nbsp;{fecha}
                      &nbsp;&nbsp;&nbsp;
                      <Button
                        style={{ height: 30 }}
                        type="primary"
                        danger
                        icon={<PaperClipOutlined />}
                        onClick={() => handleModalAttachementOpen(0, 0)}
                      ></Button>
                      &nbsp;&nbsp;
                      <Tooltip title={'Evidencias'}>
                        <Button
                          style={{ height: 30 }}
                          type="primary"
                          icon={<FolderViewOutlined />}
                          onClick={async () => {
                            await TicketsService.getResetTemporales();
                            setfileList([]);
                            handleObtenerEvidencias();
                            setisModalEvidenciasOpen(true);
                          }}
                        ></Button>
                      </Tooltip>
                    </p>{' '}
                  </div>
                }
                bordered={false}
                style={{ backgroundColor: '#f5f5f5' }}
                type="inner"
              >
                <div
                  style={{
                    margin: 0,
                    padding: 0,
                    overflow: 'hidden',
                    cursor: 'zoom-in',
                  }}
                  dangerouslySetInnerHTML={{ __html: detalleTicket }}
                  onClick={ShowModalDocument}
                ></div>
              </Card>
            </Skeleton>
          </center>

          <Typography.Title
            level={5}
            style={{ marginTop: 10, color: '#081b3e' }}
          >
            Mensajes:
          </Typography.Title>
          <Skeleton loading={loading} active={true}>
            <div
              style={{
                maxHeight:
                  window.innerHeight - (canAnswerButtonHidden ? 340 : 440),
                minHeight: 100,
                overflow: 'auto',
                paddingLeft: 20,
                paddingRight: 20,
                marginBottom: -20,
                marginTop: -10,
                minWidth: 980,
                width: divContentWidth - 20,
              }}
            >
              {messageList.length == 0 ? (
                <Empty
                  style={{ marginTop: 60, marginBottom: 60 }}
                  description={
                    <Typography.Text>
                      Sin mensajes por el momento.
                    </Typography.Text>
                  }
                ></Empty>
              ) : (
                <Timeline style={{ marginTop: 20 }} items={messageList} />
              )}
            </div>
            <Button
              style={{ width: 200, height: 50, display: canAnswerButtonHidden }}
              type="primary"
              onClick={() => OpenResponseModal()}
              icon={<CommentOutlined />}
            >
              Responder
            </Button>
          </Skeleton>
        </div>
        <Modal
          closable={false}
          destroyOnClose={true}
          open={isResponseModalOpen}
          width={1000}
          title={
            <div>
              <ContainerOutlined /> &nbsp; Responder Ticket
            </div>
          }
          footer={[
            <Row
              key={'RowModal'}
              gutter={6}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignContent: 'center',
              }}
            >
              <Col>
                <b>Estatus:</b>
              </Col>
              <Col>
                <Select
                  onChange={handleChangeStatus}
                  style={{
                    textAlign: 'left',
                    width: 110,
                    marginLeft: 10,
                    marginRight: 5,
                  }}
                  options={[
                    { value: 2, label: 'Trabajando' },
                    { value: 3, label: 'Finalizar' },
                    { value: 5, label: 'Cancelar' },
                  ]}
                />
              </Col>
              <Col>
                <b>Solución:</b>
                <Select
                  onChange={handleChangeBaseConocimiento}
                  style={{ textAlign: 'left', width: 230, marginLeft: 10 }}
                  options={optionBaseConocimiento}
                  disabled={enableOptionBaseConocimiento}
                />
              </Col>
              <Col>
                <Button
                  style={{ height: 42, marginLeft: 20 }}
                  type="primary"
                  danger
                  icon={<PaperClipOutlined />}
                  onClick={() => {
                    handleModalResponseAttachementOpen();
                    setflagAdjuntarModal(true);
                  }}
                >
                  Archivos
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  onClick={handleModalResponseClose}
                  style={{ height: 40 }}
                >
                  <CloseOutlined />
                  Cerrar
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    setdisableButton(true);
                    handleSaveDetail();
                  }}
                  style={{ height: 40 }}
                  disabled={disableButton}
                >
                  <CommentOutlined />
                  Responder
                </Button>
              </Col>
            </Row>,
          ]}
        >
          <Editor
            tinymceScriptSrc={
              process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'
            }
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue="<p></p>"
            init={{
              height: 200,
              resize: false,
              language: 'es_MX',
              language_url: process.env.PUBLIC_URL + '/tinymce/langs/es_MX.js',
              menubar: false,
              plugins: ['lists'],
              toolbar:
                'undo redo | blocks | ' +
                'bold italic forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent ',
              content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
          />
        </Modal>

        <ModalMessageCustom
          closable={false}
          destroyOnClose={true}
          title={
            <div>
              <ContainerOutlined /> &nbsp; {modalTitle}
            </div>
          }
          open={isModalOpen}
          onCancel={handleModalClose}
          width={1100}
          style={{ top: 60 }}
          footer={[
            <Button
              key={'btnCerrarModal'}
              type="primary"
              onClick={handleModalClose}
            >
              Cerrar
            </Button>,
          ]}
        >
          <center>
            <Card
              headStyle={{ height: 70 }}
              title={
                <div>
                  <p style={{ lineHeight: 0.2, fontSize: 15 }}>
                    <b>Folio:</b> &nbsp;{folio} &nbsp; <b>Usuario Solicitó:</b>
                    &nbsp;{nombreCompleto}
                  </p>{' '}
                  <p style={{ lineHeight: 0.2, fontSize: 15 }}>
                    <b>Titulo:</b>"{TituloTicket}" &nbsp; <b>Status:</b>&nbsp;
                    <Tag color={color}>{text.toUpperCase()}</Tag> <b>Fecha:</b>
                    &nbsp;{fecha}
                  </p>{' '}
                </div>
              }
              bordered={false}
              type="inner"
              style={{ backgroundColor: '#f5f5f5' }}
            >
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  overflow: 'hidden',
                }}
                dangerouslySetInnerHTML={{ __html: detalleTicket }}
              ></div>

              <Button
                style={{ height: 40, marginTop: 20 }}
                type="primary"
                danger
                icon={<PaperClipOutlined />}
                onClick={() => handleModalAttachementOpen(0, 0)}
              >
                Archivos Adjuntos
              </Button>
            </Card>
          </center>
        </ModalMessageCustom>

        <ModalAttachement
          destroyOnClose={true}
          closable={false}
          title={
            <div>
              <PaperClipOutlined /> &nbsp; Archivos adjuntos
            </div>
          }
          open={isModalAttachementOpen}
          onCancel={handleModalAttachementClose}
          footer={[
            <Button
              key={1}
              type="primary"
              onClick={handleModalAttachementClose}
            >
              Cerrar
            </Button>,
          ]}
        >
          <Spin spinning={loadingModal} active={true}>
            <div style={{ backgroundColor: '#f5f5f5', borderRadius: 10 }}>
              <Upload
                name="archivo"
                customRequest={({ file, onSuccess, onError }) => {
                  setloadingModal(true);
                  const formData = new FormData();
                  formData.append('archivo', file);
                  fetch(
                    `${URL}guarda-archivo?token=${AuthService.getCurrentToken()}&id_ticket=${IdTicket}&tipo=1`,
                    {
                      method: 'POST',
                      body: formData,
                    }
                  )
                    .then((response) => response.json())
                    .then((data) => {
                      setfileList(data.data);
                      setloadingModal(false);
                    })
                    .catch((error) => {
                      setloadingModal(false);
                      ModalMessage(
                        'Error',
                        'Se produjo un error al guardar el documento.'
                      );
                    });
                }}
                listType="picture-card"
                fileList={fileList}
                showUploadList={{
                  showRemoveIcon: canRemove,
                  showDownloadIcon: true,
                }}
                onPreview={handlePreview}
                onRemove={handleRemove}
                onDownload={handeDownload}
                beforeUpload={handleBeforeUpload}
                disabled={!flagAdjuntarModal}
              >
                {ModelAttachementType == 0 ? (
                  fileList.length == 0 ? (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={false}
                    />
                  ) : null
                ) : (
                  uploadButton
                )}
              </Upload>
            </div>
          </Spin>

          <ModalPreview
            open={previewOpen}
            title={
              <div>
                <FileImageOutlined /> &nbsp; Vista previa de imagen
              </div>
            }
            footer={[
              <Button
                key={'btnOkModalPreview'}
                type="primary"
                onClick={handleCancel}
              >
                OK
              </Button>,
            ]}
            closable={false}
            width={divContentWidth - 150}
            onCancel={handleCancel}
            style={{ marginTop: -40 }}
          >
            <div
              style={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <TransformWrapper>
                <TransformComponent>
                  <img
                    alt="example"
                    style={{
                      width: '100%',
                    }}
                    src={previewImage}
                  />
                </TransformComponent>
              </TransformWrapper>
            </div>
          </ModalPreview>

          <ModalDocumentViewer
            open={OpenModelDocument}
            titulo={'Vista previa del documento'}
            url={pathDocument}
            closable={false}
            width={divContentWidth - 50}
            footer={[
              <Button
                key={'btnOkModalViewer'}
                type="primary"
                onClick={handleModalDocument}
              >
                OK
              </Button>,
            ]}
          />
        </ModalAttachement>

        <Modal
          open={isModalEvidenciasOpen}
          title={
            <div>
              <FolderViewOutlined /> &nbsp; Evidencias
            </div>
          }
          footer={[
            <Button
              key={'btnOkModalEvidencia'}
              type="primary"
              onClick={() => {
                handleGuardarEvidencias(
                  fileList,
                  editorRef.current.getContent()
                );
              }}
            >
              Guardar
            </Button>,
            <Button
              key={'btnOkModalEvidencia'}
              type="primary"
              onClick={() => {
                setisModalEvidenciasOpen(false);
              }}
              danger
            >
              Cerrar
            </Button>,
          ]}
          closable={false}
          width={780}
          onCancel={handleCancel}
          style={{ marginTop: -40 }}
        >
          <Spin spinning={loadingModal} active={true}>
            <div
              style={{
                width: '100%',
                height: 'auto',

                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <Row style={{ marginTop: 10, marginLeft: 10 }}>
                <Col>
                  <div style={{ fontSize: 16 }}>
                    Capture los detalles de la evidencia:
                  </div>
                </Col>
              </Row>
              <Editor
                style={{ minWidth: 1000 }}
                tinymceScriptSrc={
                  process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'
                }
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue="<p></p>"
                init={{
                  height: 200,
                  resize: false,
                  language: 'es_MX',
                  language_url:
                    process.env.PUBLIC_URL + '/tinymce/langs/es_MX.js',
                  menubar: false,
                  plugins: ['lists'],
                  toolbar:
                    'undo redo | blocks | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent ',
                  content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                }}
              />
            </div>

            <div style={{ fontSize: 16, marginTop: 20, marginLeft: 10 }}>
              Imagenes:
            </div>
            <div
              style={{
                backgroundColor: '#f5f5f5',
                borderRadius: 10,
                marginBottom: 20,
              }}
            >
              <Upload
                name="archivo"
                customRequest={({ file, onSuccess, onError }) => {
                  setloadingModal(true);
                  const formData = new FormData();
                  formData.append('archivo', file);
                  fetch(
                    `${URL}guarda-archivo-evidencia?token=${AuthService.getCurrentToken()}&id_ticket=${IdTicket}&tipo=1`,
                    {
                      method: 'POST',
                      body: formData,
                    }
                  )
                    .then((response) => response.json())
                    .then((data) => {
                      setfileList(data.data);
                      setloadingModal(false);
                    })
                    .catch((error) => {
                      setloadingModal(false);
                      ModalMessage(
                        'Error',
                        'Se produjo un error al guardar el documento.'
                      );
                    });
                }}
                listType="picture-card"
                fileList={fileList}
                showUploadList={{
                  showRemoveIcon: canRemove,
                  showDownloadIcon: true,
                }}
                onPreview={handlePreview}
                onRemove={handleRemoveEvidencia}
                onDownload={handeDownload}
                beforeUpload={handleBeforeUpload}
              >
                {uploadButton}
              </Upload>

              <ModalPreview
                open={previewOpen}
                title={
                  <div>
                    <FileImageOutlined /> &nbsp; Vista previa de imagen
                  </div>
                }
                footer={[
                  <Button
                    key={'btnOkModalPreview'}
                    type="primary"
                    onClick={handleCancel}
                  >
                    OK
                  </Button>,
                ]}
                closable={false}
                width={divContentWidth - 150}
                onCancel={handleCancel}
                style={{ marginTop: -40 }}
              >
                <div
                  style={{
                    width: '100%',
                    height: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <TransformWrapper>
                    <TransformComponent>
                      <img
                        alt="example"
                        style={{
                          width: '100%',
                        }}
                        src={previewImage}
                      />
                    </TransformComponent>
                  </TransformWrapper>
                </div>
              </ModalPreview>
            </div>
          </Spin>
        </Modal>
      </Structure>
    </ConfigProvider>
  );
};
export default DetalleTicket;
