

 function getItem(key) {
  try {
    const value =  localStorage.getItem(key)
    return value != null ? JSON.parse(value) : null;
  } catch(e) {
    // error reading value
  }
}

function save(key,defaultValue) {
    try {
      localStorage.setItem(key, JSON.stringify(defaultValue));
      } catch (e) {
        // saving error
      }
  }

function clearAll() {
    try {
      localStorage.clear()
      } catch (e) {
        // saving error
      }
  }




const LocalData = {
    save,
    getItem,
    clearAll

}


export default  LocalData ;