import moment from 'moment';

const regresaMomentFromNowTimezoneLocal = (fecha) => {
  const serverTimezoneOffset = 0; // Ajusta esto si sabes el offset de la zona horaria del servidor, en minutos (UTC = 0)
  const localTimezoneOffset = new Date().getTimezoneOffset(); // Offset de la zona horaria local en minutos

  const correctedDate = moment(fecha)
    .subtract(serverTimezoneOffset, 'minutes') // Ajusta desde la zona horaria del servidor
    .add(localTimezoneOffset, 'minutes')
    .fromNow(); // Ajusta a la zona horaria local

  return correctedDate;
};

const regresaFormatoFechaTimeZone = (fecha) => {
  const serverTimezoneOffset = 0; // Ajusta esto si sabes el offset de la zona horaria del servidor, en minutos (UTC = 0)
  const localTimezoneOffset = new Date().getTimezoneOffset(); // Offset de la zona horaria local en minutos

  const correctedDate = moment(fecha)
    .subtract(serverTimezoneOffset, 'minutes') // Ajusta desde la zona horaria del servidor
    .add(localTimezoneOffset, 'minutes'); // Ajusta a la zona horaria local

  const formattedDate = correctedDate.format('YYYY-MM-DD, h:mm:ss a');

  return formattedDate;
};

const returnDateFormatClass = {
  regresaMomentFromNowTimezoneLocal,
  regresaFormatoFechaTimeZone,
};

export default returnDateFormatClass;
