import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';

import {
  Button,
  Select,
  Input,
  Row,
  Col,
  Divider,
  Table,
  Tag,
  Modal,
  InputNumber,
} from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import moment from 'moment';
const { TextArea } = Input;
import UtilsClass from '../../clases/Utils.class';
import { ComponentesContext } from '../../contextos/componentesContext';
import SolicitudesService from '../../services/solicitudes.Service';
import TicketsService from '../../services/tickets.Service';
import CatalogosService from '../../services/catalogos.Service';
import getColumnSearchProps from '../SearchPropsCustom';

export default function SolicitudConfiguracionSoftware(props) {
  const tableRefSolicitudes = useRef();
  const { confirm } = Modal;
  const { tableHeight, ShowLoading, ModalMessage } =
    useContext(ComponentesContext);

  const [flagImporteVisible, setflagImporteVisible] = useState(false);
  const [flagBloquearFormulario, setflagBloquearFormulario] = useState(false);
  const [inputFolio, setinputFolio] = useState('');
  const [inputCorreo, setinputCorreo] = useState('');
  const [inputCorreoBeneficio, setinputCorreoBeneficio] = useState('');
  const [inputEspecifique, setinputEspecifique] = useState('');
  const [inputImporte, setinputImporte] = useState(0);
  const [DataSolicitudes, setDataSolicitudes] = useState([]);
  const [formBeneficiario, setformBeneficiario] = useState('');
  const [formPuesto, setformPuesto] = useState('');
  const [formTipo, setformTipo] = useState('');

  const [optionPuestos, setoptionPuestos] = useState('');
  const [optionUsuarios, setoptionUsuarios] = useState('');
  const [formElemento, setformElemento] = useState('');
  const [divInternoVisible, setdivInternoVisible] = useState('');
  const [divSistemas, setdivSistemas] = useState('');
  const [isModalOpen, setisModalOpen] = useState(false);
  const [DataUsuarios, setDataUsuarios] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const [optionNotificaciones, setoptionNotificaciones] = useState('');
  const [formNotificacion, setformNotificacion] = useState(1);

  const [idRegistro, setidRegistro] = useState(null);

  useEffect(() => {
    InicializaFormulario();
  }, []);

  const InicializaFormulario = async () => {
    getTableData();
    getUsuariosData();
    getPuestosData();
    postRegresaConfiguracion();
  };

  const postRegresaConfiguracion = async () => {
    let configuracion = await CatalogosService.postRegresaConfiguracion({
      nombreConfiguracion: 'notificacionesSoftware',
    });
    //console.log('configuracion tabla', TableData);
    if (configuracion.estado != 200) {
      ModalMessage(
        'Mensaje',
        'Se produjo un error al cargar los datos, favor de reportar al area de sistemas.'
      );
      return false;
    }

    //console.log('configuracion', configuracion.data.data);

    setoptionNotificaciones(configuracion.data.data);
    setformNotificacion(configuracion.data.value);
    //setDataSolicitudes(TableData.data);
  };

  const getTableData = async () => {
    let TableData = await SolicitudesService.postListaConfiguracionHS({
      tipo_solicitud: 2,
    });
    //console.log('configuracion tabla', TableData);
    if (TableData.estado != 200) {
      ModalMessage(
        'Mensaje',
        'Se produjo un error al cargar los datos, favor de reportar al area de sistemas.'
      );
      return false;
    }
    setDataSolicitudes(TableData.data);
  };

  const getUsuariosData = async () => {
    let UsuariosData = await TicketsService.getListaDatosUsuarios();
    setDataUsuarios(UsuariosData.data);

    if (UsuariosData.estado != 200) {
      ModalMessage(
        'Mensaje',
        'Se produjo un error al cargar los datos, favor de reportar al area de sistemas.'
      );
      return false;
    }

    setoptionUsuarios(
      UsuariosData.data.map((item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.nombre_completo}</div>,
        nombre: item.nombre_completo,
        correo: item.correo,
        puesto: item.id_puesto,
      }))
    );
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getPuestosData = async () => {
    let puestosData = await CatalogosService.getListaPuestos();

    setoptionPuestos(
      puestosData.data.map((item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.descripcion}</div>,
        nombre: item.descripcion,
      }))
    );
  };

  const handleBeneficiarioChange = (e, option) => {
    //console.log(e);
    //console.log(option);
    setformBeneficiario(e);
    setformPuesto(option.puesto);
    setinputCorreoBeneficio(option.correo);
  };

  const handleSelectChange = (value) => {
    setSelectedValue(value.toString());
  };

  const HandleAgregarRegistro = async (id_registro) => {
    ShowLoading(true);

    if (formBeneficiario == '') {
      ModalMessage(
        'Mensaje',
        'El Nombre del usuario no puede estar en blanco.'
      );
      return false;
    }

    if (formTipo == '') {
      ModalMessage('Mensaje', 'El campo tipo no puede estar en blanco.');
      return false;
    }
    const json = {
      id_registro,
      tipo_solicitud: 2,
      id_usuario: formBeneficiario,
      tipo_autorizacion: formTipo,
      importe_maximo: inputImporte,
    };

    let SolicitudesData =
      await SolicitudesService.postRegistrarConfiguracionHS(json);

    //console.log(SolicitudesData);

    if (SolicitudesData.estado == 203) {
      ModalMessage(
        'Mensaje',
        'El usuario seleccionado ya esta registrado, favor de seleccionar otro.'
      );
      ShowLoading(false);
      return false;
    }

    if (SolicitudesData.estado == 200) {
      getTableData();
      setformBeneficiario('');
      setformTipo('');
      setinputImporte(0);

      ModalMessage(
        'Mensaje',
        'El usuario que autoriza se ' +
          (id_registro == null ? 'registró' : 'actualizó') +
          ' correctamente.'
      );
      setisModalOpen(false);
    } else {
      ModalMessage(
        'Mensaje',
        'Error al ' +
          (id_registro == null ? 'registrar' : 'actualizar') +
          ' el Usuario que autoriza, favor de reportar a el area de sistema.'
      );
    }

    ShowLoading(false);
  };

  const ReturnTipoSolicitudTag = (valor) => {
    let color = '';
    let text = '';
    switch (valor) {
      case 1:
        color = '#ff8000';
        text = 'Jefe Soporte(Revisa solicitud)';
        break;
      case 2:
        color = '#0090f0';
        text = 'Gerente sistemas';
        break;
      case 3:
        color = '#00c792';
        text = 'Directivo Autoriza';
        break;
    }
    return (
      <Tag
        color={color}
        key={valor}
        style={{
          width: 300,
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          height: 25,
        }}
      >
        {text.toUpperCase()}
      </Tag>
    );
  };

  const handleOpenModal = async (record) => {
    ShowLoading(true);

    //console.log('registro', record);
    let UsuariosData = await TicketsService.getListaDatosUsuarios();
    setDataUsuarios(UsuariosData.data);

    if (UsuariosData.estado != 200) {
      ModalMessage(
        'Mensaje',
        'Se produjo un error al cargar los datos, favor de reportar al area de sistemas.'
      );
      return false;
    }

    setinputFolio(String(record.id).padStart(5, '0'));
    setformBeneficiario(record.id_usuario);

    const filteredDataBeneficiario = UsuariosData.data.filter(
      (item) => parseInt(item.id) === record.id_usuario
    );

    if (filteredDataBeneficiario.length > 0) {
      setformPuesto(filteredDataBeneficiario[0].id_puesto);
      setinputCorreoBeneficio(filteredDataBeneficiario[0].correo);
    }

    setformTipo(record.tipo_autorizacion);
    setinputImporte(record.importe_maximo);
    //setinputJustificacion(record.justificacion.toString().toUpperCase());

    ShowLoading(false);

    setflagImporteVisible(true);
    if (record.tipo_autorizacion != 3) {
      setflagImporteVisible(false);
    }
    setflagBloquearFormulario(false);
    setidRegistro(record.id);
    setisModalOpen(true);
  };

  const handleConfirmarBorrarRegistro = async (record) => {
    confirm({
      title: 'Confirmación',
      icon: <ExclamationCircleFilled />,
      content: 'Esta segur@ de borrar el registro seleccionado?',
      okText: 'Sí, borrar',
      cancelText: 'No, cancelar',
      onOk() {
        handleBorrarRegistro(record);
      },
      onCancel() {},
    });
  };

  const handleBorrarRegistro = async (record) => {
    let SolicitudesData = await SolicitudesService.postBorrarConfiguracion({
      id_registro: record.id,
    });

    if (SolicitudesData.estado != 200) {
      ModalMessage(
        'Mensaje',
        'Se produjo un error al borrar el registro, favor de reportar al area de sistemas.'
      );
    }

    getTableData();

    ModalMessage('Mensaje', 'El registro se borro correctamente.');
  };

  const columnsSolicitudes = [
    {
      title: 'Opciones',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 159,
      render: (_, record) => (
        <div>
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              handleOpenModal(record);
            }}
            style={{ width: 40, height: 34 }}
          ></Button>
          &nbsp;
          <Button
            type="primary"
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
              handleConfirmarBorrarRegistro(record);
            }}
            style={{ width: 40, height: 34 }}
          ></Button>
        </div>
      ),
    },
    {
      width: 275,
      title: 'Tipo Autorización',
      key: 'tipo_autorizacion',
      dataIndex: 'tipo_autorizacion',
      filters: [
        {
          text: 'Jefe Soporte(Revisa solicitud)',
          value: 1,
        },
        {
          text: 'Gerente sistemas',
          value: 2,
        },
        {
          text: 'Directivo Autoriza',
          value: 3,
        },
      ],
      onFilter: (value, record) =>
        record.tipo_autorizacion
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
      sorter: (a, b) => a.tipo_autorizacion.length - b.tipo_autorizacion.length,
      render: (_, record) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;
          {ReturnTipoSolicitudTag(record.tipo_autorizacion)}
        </div>
      ),
    },
    {
      width: 400,
      title: 'Nombre usuario',
      dataIndex: 'nombre_usuario',
      key: 'nombre_usuario',
      ...getColumnSearchProps({
        dataIndex: 'nombre_usuario',
        searchInput,
        handleSearch,
        handleReset,
        setSearchText,
        setSearchedColumn,
        searchText,
        searchedColumn,
      }),
    },
    {
      title: 'Importe',
      dataIndex: 'importe_maximo',
      key: 'importe_maximo',
      render: (_, record) => (
        <>
          {parseInt(record.importe_maximo).toLocaleString('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </>
      ),
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha_registro',
      key: 'fecha_registro',
      width: 120,
      defaultSortOrder: 'descend',
      sorter: (a, b) =>
        moment(a.fecha_registro).unix() - moment(b.fecha_registro).unix(),
      render: (_, record) => (
        <>{moment(record.fecha_registro).format('YYYY-MM-DD, h:mm:ss a')}</>
      ),
    },
  ];

  const handleNotificacion = async (valor) => {
    ShowLoading(true);
    let configuracion = await CatalogosService.postActualizaConfiguracion({
      nombreConfiguracion: 'notificacionesSoftware',
      valor,
    });
    //console.log('configuracion tabla', TableData);
    if (configuracion.estado != 200) {
      ModalMessage('Mensaje', 'Se produjo un error al actualizar el valor.');
      return false;
    }
    ShowLoading(false);
    setformNotificacion(valor);
  };

  return (
    <div
      style={{
        width: '98%',
        minWidth: 1050,
      }}
    >
      <Modal
        closable={false}
        destroyOnClose={true}
        open={isModalOpen}
        width={950}
        style={{ minWidth: 950 }}
        title={
          <div>
            <EditOutlined /> &nbsp; Registrar usuario que autoriza solicitudes
            de Software
          </div>
        }
        footer={[
          <Button
            type="primary"
            onClick={() => {
              HandleAgregarRegistro(idRegistro);
            }}
          >
            <SaveOutlined />
            Guardar
          </Button>,
          <Button
            type="primary"
            onClick={() => {
              setinputCorreo('');
              setformBeneficiario('');
              setformPuesto('');
              setformElemento('');
              //setinputJustificacion('');
              setisModalOpen(false);
            }}
            danger
          >
            <CloseOutlined />
            Cerrar
          </Button>,
        ]}
      >
        <Divider orientation="left" style={{ fontWeight: 'bold' }}>
          Datos del usuario
        </Divider>

        <Row
          gutter={16}
          style={{ marginTop: 20, display: divInternoVisible, marginLeft: -40 }}
        >
          <Col
            span={4}
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <label>Nombre: </label>
          </Col>
          <Col>
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Seleccione una opción"
              options={optionUsuarios}
              value={formBeneficiario}
              onChange={(e, option) => {
                handleBeneficiarioChange(e, option);
              }}
              disabled={flagBloquearFormulario}
              filterOption={UtilsClass.filterOption}
              style={{ textAlign: 'left', width: 720 }}
            />
          </Col>
        </Row>

        <Row
          gutter={16}
          style={{ marginTop: 20, display: divInternoVisible, marginLeft: -40 }}
        >
          <Col
            span={4}
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <label>Puesto: </label>
          </Col>
          <Col>
            <Select
              disabled
              style={{ textAlign: 'left', width: 320 }}
              options={optionPuestos}
              onChange={handleSelectChange}
              value={formPuesto}
            />
          </Col>

          <Col
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <label>Correo: </label>
          </Col>
          <Col>
            <Input
              maxLength={60}
              style={{ width: 320 }}
              onChange={(e) => {
                setinputCorreoBeneficio(e.target.value);
              }}
              disabled
              value={inputCorreoBeneficio}
            />
          </Col>
        </Row>

        <Divider orientation="left" style={{ fontWeight: 'bold' }}>
          Datos de autorización
        </Divider>

        <Row
          gutter={16}
          style={{
            marginTop: 20,
            display: divSistemas,
            marginLeft: -40,
            marginBottom: flagImporteVisible == true ? 0 : 30,
          }}
        >
          <Col
            span={4}
            style={{
              display: 'flex',
              alignContent: 'baseline',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <label>Tipo: </label>
          </Col>
          <Col>
            <Select
              style={{ textAlign: 'left', width: 720 }}
              options={[
                {
                  value: 1, // Convierte el ID a una cadena
                  label: (
                    <div className="lblOptionCombo">
                      Jefe Soporte(Revisa solicitud)
                    </div>
                  ),
                  nombre: 'Jefe Soporte(Revisa solicitud)',
                },
                {
                  value: 2, // Convierte el ID a una cadena
                  label: <div className="lblOptionCombo">Gerente sistemas</div>,
                  nombre: 'Gerente sistemas',
                },
                {
                  value: 3, // Convierte el ID a una cadena
                  label: (
                    <div className="lblOptionCombo">Directivo Autoriza</div>
                  ),
                  nombre: 'Directivo Autoriza',
                },
              ]}
              onChange={(e) => {
                if (e != 3) {
                  setflagImporteVisible(false);
                } else {
                  setflagImporteVisible(true);
                }
                setformTipo(e);
              }}
              value={formTipo}
            />
          </Col>
        </Row>

        <Row
          gutter={16}
          style={{
            marginTop: 20,
            marginLeft: -40,
            marginBottom: 40,
            display: flagImporteVisible == true ? 'flex' : 'none',
          }}
        >
          <Col span={4} style={{ textAlign: 'right' }}>
            <label>Importe maximo: </label>
          </Col>
          <Col>
            <InputNumber
              style={{ textAlign: 'left', width: 720 }}
              value={inputImporte}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
              onChange={(e) => {
                setinputImporte(e);
              }}
            />
          </Col>
        </Row>
      </Modal>

      <div
        style={{
          marginTop: 50,

          marginBottom: 15,
        }}
      >
        <Row
          style={{
            display: 'flex',
            alignItems: 'baseline',
          }}
        >
          <Col
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'baseline',
              width: '50%',
              paddingLeft: 10,
            }}
          >
            <b>Enviar notificaciones a directores:</b>
            &nbsp;&nbsp;&nbsp;
            <Select
              style={{ textAlign: 'left', width: 200 }}
              options={optionNotificaciones}
              onChange={(e) => {
                handleNotificacion(e);
                //setformNotificacion(e);
              }}
              value={formNotificacion}
            />
          </Col>
          <Col
            style={{
              display: 'flex',
              justifyContent: 'flex-end',

              width: '50%',
            }}
          >
            <Button
              style={{ width: 150, height: 50 }}
              type="primary"
              onClick={() => {
                setidRegistro(null);
                getUsuariosData();
                setformTipo('');
                setinputCorreoBeneficio('');
                setflagBloquearFormulario(false);
                setflagImporteVisible(false);
                setisModalOpen(true);
              }}
              icon={<PlusOutlined />}
            >
              Nuevo Usuario
            </Button>
          </Col>
        </Row>
      </div>

      <Table
        rowKey="id"
        columns={columnsSolicitudes}
        dataSource={DataSolicitudes}
        pagination={false}
        ref={tableRefSolicitudes}
        style={{ height: window.innerHeight - 380 }}
        scroll={{
          y: window.innerHeight - 380,
        }}
      />
    </div>
  );
}
