import TicketsService from "../services/tickets.Service";

const ReturnUserType = async () =>{

  let userType = await TicketsService.getUserType();
  return userType;
}
const ReturnUserTypeClass = {
  ReturnUserType,
};

export default ReturnUserTypeClass;