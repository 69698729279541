import React, { useState, useEffect, useContext, useRef } from 'react';
import AuthService from '../../services/auth.service';
import TicketsService from '../../services/tickets.Service';
import { useNavigate } from 'react-router-dom';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import './DashBoard.css';
import {
  Col,
  Row,
  Typography,
  Input,
  Divider,
  Form,
  Button,
  Skeleton,
  Select,
  Upload,
  Modal,
  Spin,
  ConfigProvider,
} from 'antd';
import {
  PlusOutlined,
  SaveOutlined,
  PaperClipOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import Structure from '../../componentes/StructureUser';
import { ComponentesContext } from '../../contextos/componentesContext';
import { Editor } from '@tinymce/tinymce-react';
import returnValidUploadFileClass from '../../clases/returnValidUploadFile.class';
import ModalDocumentViewer from '../../componentes/ModalDocumentViewer';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(new Error(error));
  });

const CrearTicket = () => {
  const [form] = Form.useForm();
  const {
    openNotification,
    ModalMessage,
    ShowLoading,
    tableHeight,
    divContentWidth,
  } = useContext(ComponentesContext);
  const [pathDocument, setpathDocument] = useState([]);
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const [OpenModelDocument, setOpenModelDocument] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const URL = process.env.REACT_APP_URL_API_SOPORTE;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [loadingModal, setloadingModal] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [optionClasificacion, setoptionClasificacion] = useState([]);
  const [optionPlazas, setoptionPlazas] = useState([]);
  const [optionEmpresas, setoptionEmpresas] = useState([]);

  const [disableButton, setdisableButton] = useState(false);

  const FormValidateMessages = {
    required: '${label} es requerido!',
    types: {
      email: '${label} no es valido!',
      number: '${label} no es un numero valido!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const FontStyle = {
    textAlign: 'left',
    fontWeight: 'bold',
    color: '#404b5e',
  };

  const layout = {
    labelCol: {
      span: 3,
    },
    wrapperCol: {
      span: 23,
    },
  };

  const loadingPage = () => {
    setTimeout(function () {
      setLoading(false);
    }, 400);
  };

  const onFinish = async (values) => {
    setdisableButton(true);
    ShowLoading(true);

    if (editorRef.current.getContent() == '') {
      ModalMessage(
        'Mensaje',
        'La descripción del ticket no puede estar en blando.'
      );
      setdisableButton(false);
      ShowLoading(false);
      return false;
    }

    const ticket = {
      id_clasificacion: values.ticket.id_clasificacion,
      id_empresa: values.ticket.id_empresa,
      id_plaza:
        values.ticket.id_plaza === undefined || values.ticket.id_plaza == ''
          ? 0
          : values.ticket.id_plaza,
      titulo: values.ticket.titulo,
      descripcion: editorRef.current.getContent(),
      status: 0,
    };

    let ticketsData = await TicketsService.postCreateTicket(ticket);

    if (ticketsData.estado == 500) {
      openNotification(
        'Se produjo un error al registrar el ticket; El error se pudo haber producido si pego alguna imagen en el editor de texto del campo descripción; Para evitar esto puede usar el botón "Adjuntar archivos" y adjuntar dicha imagen.',
        'top'
      );
      setdisableButton(false);
      ShowLoading(false);
      return false;
    }

    setTimeout(function () {
      form.setFieldsValue({
        ticket: {
          titulo: '',
          id_clasificacion: '',
          id_empresa: '',
          id_plaza: '',
        },
      });
    }, 0);

    editorRef.current.setContent('');

    openNotification('El ticket se registro correctamente', 'top');
    setdisableButton(false);
    ShowLoading(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const Resetattachments = async () => {
    await TicketsService.getResetTemporales();
  };

  const ObtieneClasificaciones = async () => {
    let clasificaciones = await TicketsService.getClasificacionTicket();
    setoptionClasificacion(clasificaciones.data);
  };

  const ObtienePlazas = async () => {
    let plazas = await TicketsService.getPlazasTicket();
    setoptionPlazas(plazas.data);
  };

  const ObtieneEmpresas = async () => {
    let empresas = await TicketsService.getEmpresasTicket();
    setoptionEmpresas(empresas.data);
  };

  useEffect(() => {
    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate('/login');
    }

    ObtieneClasificaciones();
    ObtienePlazas();
    ObtieneEmpresas();
    loadingPage();
    Resetattachments();
  }, []);

  const handlePreview = async (file) => {
    console.log(file);

    const fileExtension = file.nombre_archivo.split('.').pop();

    switch (fileExtension.toLowerCase()) {
      case 'apng':
      case 'avif':
      case 'gif':
      case 'jpg':
      case 'jpeg':
      case 'jfif':
      case 'pjpeg':
      case 'pjp':
      case 'png':
      case 'svg':
      case 'webp':
      case 'bmp':
      case 'ico':
      case 'cur':
      case 'tif':
      case 'tiff':
        file.preview = file.url;
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(
          <div>
            <EyeOutlined /> Vista previa
          </div>
        );
        break;
      case 'pdf':
        setpathDocument(file.url);
        setOpenModelDocument(true);
        break;
    }

    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      <div>
        <EyeOutlined /> Vista previa
      </div>
    );
  };

  const handleBeforeUpload = async (file) => {
    let flagArchivoValido =
      await returnValidUploadFileClass.returnValidUploadFile(file.type);
    if (!flagArchivoValido) {
      ModalMessage(
        'Mensaje',
        'El tipo de archivo que se desea adjuntar no es valido.'
      );
      return false;
    }

    const fileSizeLimit = 10 * 1024 * 1024; // 5MB

    if (file.size > fileSizeLimit) {
      ModalMessage('Mensaje', 'El archivo no puede pesar mas de 10MB.');
      return false; // Cancela la carga del archivo
    }

    setloadingModal(true);
    return true;
  };

  const handleRemove = async (file) => {
    setloadingModal(true);

    console.log(file);
    let ticketsData = await TicketsService.postDeleteTemporal({
      nombre_archivo: file.nombre_archivo,
    });

    if (ticketsData.estado != '200') {
      ModalMessage('Mensaje', 'Se produjo un error quitar el archivo.');
      setloadingModal(false);
      return false;
    }
  };

  const handleChange = async ({ fileList: newFileList }) => {
    setTimeout(async function () {
      let DetalleAdjuntosData = await TicketsService.postRegresaTemporales();

      setFileList(DetalleAdjuntosData.data);
      setloadingModal(false);
    }, 6000);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Adjuntar archivo
      </div>
    </div>
  );

  const handleCancel = () => setPreviewOpen(false);

  const handleModalDocument = () => {
    setOpenModelDocument(false);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: '#3c5c7c',
            colorPrimaryHover: '#517da9',
            colorPrimaryActive: '#517da9',
          },
          Card: {
            headerBg: '#3c5c7c',
          },
        },
      }}
    >
      <Structure
        breadcrumb={'Bienvenido al sistema Fondeadora Finamo'}
        menuOption={'3'}
      >
        <div
          style={{
            minHeight: 100,
            maxHeight: tableHeight + 260,
            overflow: 'auto',
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: -20,
            marginTop: 10,
            minWidth: 980,
            width: divContentWidth - 20,
          }}
        >
          <center>
            <Typography.Title
              level={2}
              style={{ marginTop: -5, marginBottom: -20, color: '#081b3e' }}
            >
              Crear Ticket
            </Typography.Title>
            <Divider />

            <Skeleton loading={loading} active={true}>
              <Form
                form={form}
                {...layout}
                style={{ marginTop: -10 }}
                name="horizontal_login"
                onFinish={onFinish}
                validateMessages={FormValidateMessages}
              >
                <Form.Item
                  name={['ticket', 'titulo']}
                  label={<div style={FontStyle}>Título</div>}
                  rules={[
                    { required: true, message: 'Debe capturar un Titulo' },
                  ]}
                >
                  <Input maxLength={60} />
                </Form.Item>

                <Form.Item
                  name={['ticket', 'id_clasificacion']}
                  label={<div style={FontStyle}>Clasificación</div>}
                  rules={[
                    { required: true, message: 'Seleccione una Clasificación' },
                  ]}
                >
                  <Select
                    style={{ textAlign: 'left' }}
                    options={optionClasificacion}
                  />
                </Form.Item>

                <Row>
                  <Col span={12}>
                    <Form.Item
                      name={['ticket', 'id_empresa']}
                      label={<div style={FontStyle}>Empresa</div>}
                      labelCol={{ span: 6 }}
                      rules={[
                        { required: true, message: 'Seleccione una Empresa' },
                      ]}
                    >
                      <Select
                        style={{ textAlign: 'left' }}
                        options={optionEmpresas}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name={['ticket', 'id_plaza']}
                      label={<div style={FontStyle}>Plaza</div>}
                      labelCol={{ span: 3 }}
                    >
                      <Select
                        style={{ textAlign: 'left' }}
                        options={optionPlazas}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <div
                  style={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    color: '#404b5e',
                    marginTop: -10,
                  }}
                >
                  Descripción:
                </div>

                <Editor
                  tinymceScriptSrc={
                    process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'
                  }
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue="<p></p>"
                  init={{
                    height: 180,
                    resize: false,
                    language: 'es_MX',
                    language_url:
                      process.env.PUBLIC_URL + '/tinymce/langs/es_MX.js',
                    menubar: false,
                    plugins: ['lists'],
                    toolbar:
                      'undo redo | blocks | ' +
                      'bold italic forecolor | alignleft aligncenter ',
                    content_style:
                      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  }}
                />

                <table
                  aria-hidden="true"
                  style={{ width: '100%', marginTop: 10 }}
                >
                  <tbody>
                    <tr>
                      <td style={{ textAlign: 'right', width: '50%' }}>
                        <Button
                          style={{ height: 40 }}
                          type="primary"
                          danger
                          icon={<PaperClipOutlined />}
                          onClick={handleModalOpen}
                        >
                          Adjuntar Archivos
                        </Button>
                      </td>
                      <td>&nbsp;</td>
                      <td style={{ textAlign: 'left', width: '50%' }}>
                        <Button
                          style={{ width: '50%', height: 38, marginTop: 0 }}
                          type="primary"
                          htmlType="submit"
                          icon={<SaveOutlined />}
                          disabled={disableButton}
                        >
                          Crear ticket
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Form>
            </Skeleton>
          </center>
        </div>

        <Modal
          destroyOnClose={true}
          title={
            <div>
              <PaperClipOutlined /> &nbsp; Adjuntar archivos
            </div>
          }
          open={isModalOpen}
          onCancel={handleModalClose}
          footer={[
            <Button key={1} type="primary" onClick={handleModalClose}>
              OK
            </Button>,
          ]}
        >
          <Spin spinning={loadingModal} active={true}>
            <div style={{ backgroundColor: '#f5f5f5', borderRadius: 10 }}>
              <Upload
                name="archivo"
                action={`${URL}guarda-archivo?token=${AuthService.getCurrentToken()}&id_ticket=0&tipo=0`}
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onRemove={handleRemove}
                onChange={handleChange}
                beforeUpload={handleBeforeUpload}
              >
                {fileList.length >= 8 ? null : uploadButton}
              </Upload>
            </div>
          </Spin>
        </Modal>

        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          destroyOnClose={true}
          onCancel={handleCancel}
          width={divContentWidth - 50}
          style={{ marginTop: -50 }}
        >
          <div style={{ width: '100%', height: 'auto' }}>
            <TransformWrapper>
              <TransformComponent>
                <img
                  alt="example"
                  style={{
                    width: '100%',
                  }}
                  src={previewImage}
                />
              </TransformComponent>
            </TransformWrapper>
          </div>
        </Modal>

        <ModalDocumentViewer
          open={OpenModelDocument}
          titulo={'Vista previa del documento'}
          url={pathDocument}
          destroyOnClose={true}
          footer={[
            <Button
              key={'btnOkViewer'}
              type="primary"
              onClick={handleModalDocument}
            >
              OK
            </Button>,
          ]}
        />
      </Structure>
    </ConfigProvider>
  );
};
export default CrearTicket;
