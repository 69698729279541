import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  Tabs,
  Input,
  Table as TableSolicitudes,
  Typography,
  Button,
  Modal,
  Select,
  Tag,
  Space,
  Divider,
  Row,
  Col,
  Radio,
  Checkbox,
  Tooltip,
} from 'antd';
import {
  MinusCircleOutlined,
  UnlockOutlined,
  FolderOutlined,
  FileOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import moment from 'moment';
const { TextArea } = Input;

import UtilsClass from '../../clases/Utils.class';

import { ComponentesContext } from '../../contextos/componentesContext';
import SolicitudesService from '../../services/solicitudes.Service';
import TicketsService from '../../services/tickets.Service';
import CatalogosService from '../../services/catalogos.Service';
import AuthService from '../../services/auth.service';

export default function SolicitudesBajas(props) {
  const inputMotivoBajaRef = useRef(null);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [idSolicitud, setidSolicitud] = useState('');

  const [inputCorreo, setinputCorreo] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const tableRefSolicitudes = useRef();

  const [inputCorreoBeneficio, setinputCorreoBeneficio] = useState('');
  const [inputBeneficiarioEspecifique, setinputBeneficiarioEspecifique] =
    useState('');
  const [inputNombreElemento, setinputNombreElemento] = useState('');
  const [inputJustificacion, setinputJustificacion] = useState('');

  const [formUsuarios, setformUsuarios] = useState('');
  const [formSolicitante, setformSolicitante] = useState('');
  const [formBeneficiario, setformBeneficiario] = useState('');
  const [formPuesto, setformPuesto] = useState('');
  const [formSistema, setformSistema] = useState('');
  const [formDescarga, setformDescarga] = useState(0);
  const [formTemporal, setformTemporal] = useState('');

  const [optionUsuarios, setoptionUsuarios] = useState('');
  const [optionSistemas, setoptionSistemas] = useState('');
  const [optionPuestos, setoptionPuestos] = useState('');

  const [valueTipoUsuario, setvalueTipoUsuario] = useState(1);
  const [valueRequerimientoAcceso, setvalueRequerimientoAcceso] = useState(1);
  const [valueDescarga, setvalueDescarga] = useState(1);

  const [formChecked, setformChecked] = useState(false);

  const [valueTipoPermiso, setvalueTipoPermiso] = useState(1);
  const [valueTipoAcceso, setvalueTipoAcceso] = useState(1);

  const [divInternoVisible, setdivInternoVisible] = useState('');
  const [divExternoVisible, setdivExternoVisible] = useState('none');
  const [divPeriodoVisible, setdivPeriodoVisible] = useState('');

  const [descargaVisible, setdescargaVisible] = useState('none');
  const [archivoVisible, setarchivoVisible] = useState('none');

  const [divSistemas, setdivSistemas] = useState('');
  const [divElemento, setdivElemento] = useState('none');

  const [divEnableFormulario, setdivEnableFormulario] = useState('none');
  const [divFormularioOpacity, setdivFormularioOpacity] = useState(0.6);

  const { tableHeight, ShowLoading, ModalMessage } =
    useContext(ComponentesContext);

  const [DataSolicitudes, setDataSolicitudes] = useState([]);
  const [DataUsuarios, setDataUsuarios] = useState([]);

  const styleIcons = { fontSize: 20 };

  const ReturnTipoAccesoTag = (valor) => {
    let color = '';
    let text = '';
    let icon = null;
    switch (valor) {
      case 1:
        color = '#1dd381';
        text = 'Acceso a Sistema';
        icon = <UnlockOutlined style={styleIcons} />;
        break;
      case 2:
        color = '#ffc800';
        text = 'Acceso a Carpeta';
        icon = <FolderOutlined style={styleIcons} />;
        break;
      case 3:
        color = '#05cdf0';
        text = 'Acceso a Archivo';
        icon = <FileOutlined style={styleIcons} />;
        break;
    }
    return (
      <Tooltip title={text}>
        <Tag color={color} key={valor}>
          <div>{icon}</div>
        </Tag>
      </Tooltip>
    );
  };

  const ReturnTipoSolicitudTag = (valor) => {
    let color = '';
    let text = '';
    switch (valor) {
      case 'Alta':
        color = '#0886b4';
        text = 'Alta';
        break;
      case 'Cambio':
        color = '#7208b4';
        text = 'Cambio';
        break;
    }
    return (
      <Tag color={color} key={valor} style={{ width: 65, textAlign: 'center' }}>
        {text.toUpperCase()}
      </Tag>
    );
  };

  const ReturnStatusSolicitudTag = (valor) => {
    let color = '';
    let text = '';
    switch (valor) {
      case 'PENDIENTE':
        color = '#ffa600';
        text = 'PENDIENTE';
        break;
      case 'AUTORIZADO':
        color = '#1dd381';
        text = 'AUTORIZADO';
        break;
      case 'RECHAZADO':
        color = '#ff0062';
        text = 'RECHAZADO';
        break;
      case 'BAJA':
        color = '#05cdf0';
        text = 'BAJA';
        break;
      case 'OTRO':
        color = '#7208b4';
        text = 'OTRO';
        break;
    }
    return (
      <Tag color={color} key={valor} style={{ width: 90, textAlign: 'center' }}>
        {text.toUpperCase()}
      </Tag>
    );
  };

  useEffect(() => {
    //console.log("entro solicitudes inicializar");
    InicializaFormulario();
  }, []);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Limpiar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const InicializaFormulario = async () => {
    getUsuariosData();
    getSistemasData();
    getPuestosData();
    //getSolicitudesData();
  };

  const getSolicitudesData = async (id_usuario) => {
    const json = { id_usuario_solicito: id_usuario };
    let solicitudesData =
      await SolicitudesService.postListSolicitudesUsuario(json);
    setDataSolicitudes(solicitudesData.data);

    //console.log(solicitudesData);
  };

  const getSolicitudesDataCorreo = async (correo) => {
    var regEXCorreo = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;

    if (!regEXCorreo.test(correo)) {
      ModalMessage(
        'Mensaje',
        'El correo no tiene un formato valido, por favor verifique.'
      );
      return false;
    }

    const json = { correo };
    let solicitudesData =
      await SolicitudesService.postListSolicitudesCorreo(json);
    setDataSolicitudes(solicitudesData.data);

    //console.log('solicitudes', solicitudesData.data.length);

    if (solicitudesData.data.length == 0) {
      ModalMessage(
        'Mensaje',
        'No se encontraron solicitudes asociadas con el correo proporcionado.'
      );
    }
  };

  const getUsuariosData = async () => {
    let UsuariosData = await TicketsService.getListaDatosUsuarios();
    setDataUsuarios(UsuariosData.data);

    let usr = await AuthService.getCurrentUser();

    //console.log('usuario', usr);

    //console.log(UsuariosData);

    setoptionUsuarios(
      UsuariosData.data.map((item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.nombre_completo}</div>,
        nombre: item.nombre_completo,
        correo: item.correo,
        puesto: item.id_puesto,
      }))
    );
  };

  const getSistemasData = async () => {
    let sistemasData = await CatalogosService.getListaSistemas();
    //setDataUsuarios(sistemasData.data);

    //console.log("sistemas",sistemasData);
    setoptionSistemas(
      sistemasData.data.map((item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.descripcion}</div>,
        nombre: item.descripcion,
        tipo: item.tipo,
        id_usuario_propietario: item.id_usuario_propietario,
        correo: item.correo,
      }))
    );
  };

  const getPuestosData = async () => {
    let puestosData = await CatalogosService.getListaPuestos();
    //setDataUsuarios(sistemasData.data);

    //console.log(puestosData);

    setoptionPuestos(
      puestosData.data.map((item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.descripcion}</div>,
        nombre: item.descripcion,
      }))
    );
  };

  const handleTipoUsuarioChange = (e) => {
    setvalueTipoUsuario(e.target.value);
    setdivInternoVisible(e.target.value == 1 ? '' : 'none');
    setdivExternoVisible(e.target.value == 2 ? '' : 'none');
    setinputCorreoBeneficio('');

    setformBeneficiario('');
    setformPuesto('');
  };

  const handleBeneficiarioChange = (e, option) => {
    //console.log(e);
    //console.log(option);
    setformSolicitante(e);
    setformPuesto(option.puesto);
    setinputCorreoBeneficio(option.correo);
    getSolicitudesData(e);
  };

  const handleDuenoChange = (e, option) => {
    //console.log(e);
    //console.log(option);
    setformUsuarios(e);
    setinputCorreo(option.correo);
  };

  const handleSelectChange = (value) => {
    setSelectedValue(value.toString());
  };

  const handleRequerimientoAccesoChange = (e) => {
    setvalueRequerimientoAcceso(e);

    if (e != 1) {
      setarchivoVisible('');
      setdescargaVisible('none');

      setdivSistemas('none');
      setdivElemento('');

      if (formSistema != '') {
        const newArray = [...optionSistemas];
        const findValue = newArray.find(
          (item) => item.value === parseInt(formSistema)
        );

        //console.log(findValue);
        if (findValue.tipo == 2) {
          setdescargaVisible('');
        } else {
          setdescargaVisible('none');
        }
      } else {
        setdescargaVisible('none');
      }
    } else {
      setdivSistemas('');
      setdivElemento('none');

      setarchivoVisible('none');
    }
  };

  const handleSelectSistemasChange = (e, option) => {
    //console.log(option);

    if (option.tipo == 2) {
      setdescargaVisible('');
    } else {
      setdescargaVisible('none');
    }

    setformSistema(e);

    setformUsuarios(option.id_usuario_propietario);
    setinputCorreo(option.correo);
  };

  const onChange = (key) => {
    //console.log(key.target.checked);
    if (key.target.checked) {
      setformChecked(true);
      setformDescarga(1);
    } else {
      setformChecked(false);
      setformDescarga(0);
    }
  };

  const handleInputCorreo = (e) => {
    setinputCorreo(e.target.value);
  };

  const optionsTemporal = [
    {
      value: '',
      label: '',
    },
    {
      value: '1',
      label: '1 Semana',
    },
    {
      value: '2',
      label: '2 Semanas',
    },
    {
      value: '3',
      label: '3 Semanas',
    },
    {
      value: '4',
      label: '4 Semanas',
    },
    {
      value: '5',
      label: '1 Mes',
    },
    {
      value: '6',
      label: '2 Meses',
    },
    {
      value: '7',
      label: '3 Meses',
    },
  ];

  const handleClearForm = async () => {
    setformSolicitante('');
    setvalueTipoUsuario(1);
    setformBeneficiario('');
    setinputBeneficiarioEspecifique('');
    setinputCorreoBeneficio('');
    setvalueRequerimientoAcceso(1);
    setformSistema('');
    setinputNombreElemento('');
    setvalueTipoPermiso(1);
    setformDescarga('');
    setvalueTipoAcceso(1);
    setformTemporal('');
    setinputJustificacion('');
    setformUsuarios('');
    setformUsuarios('');

    setformPuesto('');

    setdivExternoVisible('none');
    setdescargaVisible('none');
    setarchivoVisible('none');

    setdivInternoVisible('');
    setdivPeriodoVisible('');

    setinputCorreo('');

    setdivEnableFormulario('none');
    setdivFormularioOpacity(0.6);

    //let solicitudesData = await SolicitudesService.getListSolicitudes();
    setDataSolicitudes(null);
  };

  const HandleAgregarSolicitud = async () => {
    var regEXCorreo = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;

    if (formSolicitante == '') {
      ModalMessage('Mensaje', 'El solicitante no puede estar en blanco.');
      return false;
    }

    if (valueTipoUsuario == 1) {
      if (formBeneficiario == '') {
        ModalMessage('Mensaje', 'El beneficiario no puede estar en blanco.');
        return false;
      }
    } else {
      if (inputBeneficiarioEspecifique == '') {
        ModalMessage('Mensaje', 'El beneficiario no puede estar en blanco.');
        return false;
      }

      if (inputCorreoBeneficio == '') {
        ModalMessage(
          'Mensaje',
          'El correo del beneficiario no puede estar en blanco.'
        );
        return false;
      }

      if (!regEXCorreo.test(inputCorreoBeneficio)) {
        ModalMessage(
          'Mensaje',
          'El correo del beneficiario no tiene un formato valido, por favor verifique.'
        );
        return false;
      }
    }

    if (valueRequerimientoAcceso == 1) {
      if (formSistema == '') {
        ModalMessage('Mensaje', 'El sistema no puede estar en blanco.');
        return false;
      }
    } else {
      if (inputNombreElemento == '') {
        ModalMessage(
          'Mensaje',
          'El nombre del elemento no puede estar en blanco.'
        );
        return false;
      }
    }

    if (valueTipoAcceso == 1) {
      if (formTemporal == '') {
        ModalMessage('Mensaje', 'El periodo no puede estar en blanco.');
        return false;
      }
    }

    if (inputJustificacion == '') {
      ModalMessage('Mensaje', 'La justificación no puede estar en blanco.');
      return false;
    }

    if (formUsuarios == '') {
      ModalMessage('Mensaje', 'El dueño del activo no puede estar en blanco.');
      return false;
    }

    if (inputCorreo == '') {
      ModalMessage(
        'Mensaje',
        'El correo del dueño del activo no puede estar en blanco.'
      );
      return false;
    }

    if (!regEXCorreo.test(inputCorreo)) {
      ModalMessage(
        'Mensaje',
        'El correo del dueño del activo no tiene un formato valido, por favor verifique.'
      );
      return false;
    }

    setSelectedRowKeys([]);

    const solicitudes = {
      id_solicitud: idSolicitud,
      id_usuario_solicitante: formSolicitante,
      tipo_usuario: valueTipoUsuario,
      id_usuario_beneficiario: formBeneficiario,
      beneficiario_especifique: inputBeneficiarioEspecifique,
      correo_beneficiario: inputCorreoBeneficio,
      tipo_requerimiento: valueRequerimientoAcceso,
      id_sistema: formSistema,
      elemento: inputNombreElemento,
      tipo_permiso: valueTipoPermiso,
      flag_descarga: formDescarga,
      tipo_acceso: valueTipoAcceso,
      periodo_temporal: formTemporal,
      justificacion: inputJustificacion,
      id_usuario_permiso: formUsuarios,
      correo_permiso: inputCorreo,
    };

    //console.log(solicitudes);

    ShowLoading(true);
    // const postEmpresa = { empresa: inputValue };
    let SolicitudesData =
      await SolicitudesService.postRegistrarBajaSolicitud(solicitudes);

    //console.log(SolicitudesData);

    if (SolicitudesData.estado == 200) {
      handleClearForm();
      ModalMessage('Mensaje', 'Se registro la baja correctamente.');
    } else {
      ModalMessage(
        'Mensaje',
        'Error al registrar la baja de solicitud, favor de reportar a el area de sistema.'
      );
    }

    // let ListaData = await TicketsService.getEmpresasTicket();
    // setDataEmpresas(ListaData.data);

    // setTimeout(function () {
    //   scrollToBottomEmpresa();
    // }, 1000);

    // setInputValue("");
    ShowLoading(false);
  };

  const columnsSolicitudes = [
    // {
    //   title: "Opciones",
    //   dataIndex: "id",
    //   key: "id",
    //   align: "center",
    //   width: 100,
    //   render: (_, record) => (
    //     <div>
    //       <a
    //         className="button_ordenar"
    //         onClick={() => {
    //           handleOpenModalCambio(record.id);
    //         }}
    //       >
    //         <EditOutlined />
    //       </a>
    //     </div>
    //   ),
    // },

    {
      width: 60,
      title: 'Folio',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => <div>{String(record.id).padStart(5, '0')}</div>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      width: 100,
      title: 'Acceso',
      key: 'tipo_requerimiento',
      dataIndex: 'tipo_requerimiento',
      filters: [
        {
          text: 'Sistema',
          value: 1,
        },
        {
          text: 'Carpeta',
          value: 2,
        },
        {
          text: 'Archivo',
          value: 3,
        },
      ],
      onFilter: (value, record) => record.tipo_requerimiento === value,
      sorter: (a, b) => a.tipo_requerimiento - b.tipo_requerimiento,
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {ReturnTipoAccesoTag(record.tipo_requerimiento)}
        </div>
      ),
    },
    {
      width: 150,
      title: 'Beneficiario',
      dataIndex: 'beneficiario',
      key: 'beneficiario',
      ...getColumnSearchProps('beneficiario'),
    },

    {
      title: 'Sistema/Elemento',
      dataIndex: 'sistema',
      key: 'sistema',
      align: 'center',

      render: (_, record) => (
        <div>
          <labler>{record.sistema + ' ' + record.elemento}</labler>
        </div>
      ),
    },

    {
      width: 125,
      title: 'Estatus',
      key: 'status',
      dataIndex: 'status',
      filters: [
        {
          text: 'PENDIENTE',
          value: 'PENDIENTE',
        },
        {
          text: 'AUTORIZADO',
          value: 'AUTORIZADO',
        },
        {
          text: 'RECHAZADO',
          value: 'RECHAZADO',
        },
        {
          text: 'BAJA',
          value: 'BAJA',
        },
        {
          text: 'OTRO',
          value: 'OTRO',
        },
      ],
      onFilter: (value, record) => record.status.startsWith(value),

      sorter: (a, b) => a.status.length - b.status.length,
      render: (_, record) => (
        <div style={{ textAlign: 'center' }}>
          {ReturnStatusSolicitudTag(record.status)}
        </div>
      ),
    },

    {
      title: 'Fecha',
      dataIndex: 'fecha_movimiento',
      key: 'fecha_movimiento',
      width: 120,
      sorter: (a, b) =>
        moment(a.fecha_movimiento).unix() - moment(b.fecha_movimiento).unix(),
      render: (_, record) => (
        <>{moment(record.fecha_movimiento).format('YYYY-MM-DD, h:mm:ss a')}</>
      ),
    },

    {
      width: 125,
      title: 'Movimiento',
      key: 'tipo',
      dataIndex: 'tipo',
      filters: [
        {
          text: 'Alta',
          value: 'Alta',
        },
        {
          text: 'Cambio',
          value: 'Cambio',
        },
      ],
      onFilter: (value, record) => record.tipo.startsWith(value),
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.tipo.length - b.tipo.length,
      render: (_, record) => (
        <>&nbsp;&nbsp;&nbsp;&nbsp;{ReturnTipoSolicitudTag(record.tipo)}</>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   'selectedRows: ',
      //   selectedRows[0]
      // );

      setSelectedRowKeys(selectedRowKeys);

      setidSolicitud(selectedRows[0].id);
      setdivEnableFormulario('');
      setdivFormularioOpacity(1);

      setformSolicitante(selectedRows[0].id_usuario_solicitante);

      if (selectedRows[0].beneficiario_especifique == '') {
        setvalueTipoUsuario(1);
        setformBeneficiario(selectedRows[0].id_usuario_beneficiario);
        setformPuesto(selectedRows[0].id_puesto);
        setinputCorreoBeneficio(selectedRows[0].correo_beneficiario);
      } else {
        setvalueTipoUsuario(2);
        setinputBeneficiarioEspecifique(
          selectedRows[0].beneficiario_especifique
        );
      }

      setdivInternoVisible(
        selectedRows[0].beneficiario_especifique == '' ? '' : 'none'
      );
      setdivExternoVisible(
        selectedRows[0].beneficiario_especifique != '' ? '' : 'none'
      );

      setvalueRequerimientoAcceso(selectedRows[0].tipo_requerimiento);

      if (selectedRows[0].tipo_requerimiento != 1) {
        setarchivoVisible('');
        setdescargaVisible('none');

        setdivSistemas('none');
        setdivElemento('');

        setinputNombreElemento(selectedRows[0].elemento);

        if (formSistema != '') {
          const newArray = [...optionSistemas];
          const findValue = newArray.find(
            (item) => item.value === parseInt(formSistema)
          );

          //console.log(findValue);
          if (findValue.tipo == 2) {
            setdescargaVisible('');
          } else {
            setdescargaVisible('none');
          }
        } else {
          setdescargaVisible('none');
        }
      } else {
        setdivSistemas('');
        setdivElemento('none');

        setarchivoVisible('none');

        setformSistema(selectedRows[0].id_sistema);
      }

      setvalueTipoAcceso(selectedRows[0].tipo_acceso);

      if (selectedRows[0].tipo_acceso == 1) {
        setformTemporal(selectedRows[0].periodo_temporal.toString());
        setdivPeriodoVisible('');
      } else {
        setformTemporal('');
        setdivPeriodoVisible('none');
      }

      //console.log(selectedRows[0].periodo_temporal);

      setvalueTipoPermiso(selectedRows[0].tipo_permiso);

      setformDescarga(selectedRows[0].flag_descarga);

      if (parseInt(selectedRows[0].flag_descarga) == 1) {
        setformChecked(true);
      } else {
        setformChecked(false);
      }

      setformUsuarios(selectedRows[0].id_usuario_permiso);
      setinputCorreo(selectedRows[0].correo_permiso);

      if (inputMotivoBajaRef.current) {
        inputMotivoBajaRef.current.focus();
      }
    },
    onSelect: (record, selected, selectedRows) => {
      //console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      //console.log(selected, selectedRows, changeRows);
    },
  };

  return (
    <div style={{ width: '95%', minWidth: 1050 }}>
      {/* <Divider orientation="left" style={{fontWeight:"bold", marginTop:15}} orientationMargin="0">Datos del solicitante</Divider>
          <Row>
          <Col span={4} style={{textAlign:"right"}} >
              <label >Solicitante: &nbsp;&nbsp;&nbsp;</label>
              
            </Col>
            <Col>
              
            <Select
                showSearch
                optionFilterProp="children"
                placeholder="Seleccione una opción"
                options={optionUsuarios}
                value={formSolicitante}
                onChange={setformSolicitante}
                filterOption={UtilsClass.filterOption}
                style={{ textAlign: "left", width: 350 }}
                disabled={true}
              />
            </Col>
      </Row> */}

      <Divider
        orientation="left"
        style={{ fontWeight: 'bold' }}
        orientationMargin="0"
      >
        Datos del beneficiario
      </Divider>

      <Row style={{ marginLeft: -30 }}>
        <Col span={4} style={{ textAlign: 'right' }}>
          <label>Tipo de usuario: &nbsp;&nbsp;&nbsp;</label>
        </Col>
        <Col>
          <Radio.Group
            onChange={(e) => {
              handleTipoUsuarioChange(e);
            }}
            value={valueTipoUsuario}
          >
            <Radio value={1}>Interno</Radio>
            <Radio value={2}>Externo</Radio>
          </Radio.Group>
        </Col>
      </Row>

      <Row
        gutter={16}
        style={{ marginTop: 20, display: divInternoVisible, marginLeft: -40 }}
      >
        <Col span={4} style={{ textAlign: 'right' }}>
          <label>Nombre: </label>
        </Col>
        <Col>
          <Select
            showSearch
            optionFilterProp="children"
            placeholder="Seleccione una opción"
            options={optionUsuarios}
            value={formSolicitante}
            onChange={(e, option) => {
              handleBeneficiarioChange(e, option);
            }}
            filterOption={UtilsClass.filterOption}
            style={{ textAlign: 'left', width: 400 }}
          />
        </Col>
      </Row>

      <Row
        gutter={16}
        style={{ marginTop: 20, display: divInternoVisible, marginLeft: -40 }}
      >
        <Col span={4} style={{ textAlign: 'right' }}>
          <label>Puesto: </label>
        </Col>
        <Col>
          <Select
            disabled
            style={{ textAlign: 'left', width: 400 }}
            options={optionPuestos}
            onChange={handleSelectChange}
            value={formPuesto}
          />
        </Col>

        <Col>
          <label>Correo: </label>
        </Col>
        <Col>
          <Input
            maxLength={60}
            style={{ width: 400 }}
            onChange={(e) => {
              setinputCorreoBeneficio(e.target.value);
            }}
            value={inputCorreoBeneficio}
            placeholder="Username@finamo.mx"
            disabled
          />
        </Col>
      </Row>

      <Row
        gutter={16}
        style={{ marginTop: 20, display: divExternoVisible, marginLeft: 0 }}
      >
        <div style={{ display: 'none' }}>
          <Col span={4} style={{ textAlign: 'right' }}>
            <label>Especifique: </label>
          </Col>
          <Col>
            <Input
              maxLength={60}
              style={{ width: 400 }}
              onChange={(e) => {
                setinputBeneficiarioEspecifique(e.target.value);
              }}
              value={inputBeneficiarioEspecifique}
            />
          </Col>
        </div>

        <Col>
          <label>Correo: </label>
        </Col>
        <Col>
          <Input
            maxLength={60}
            style={{ width: 400 }}
            onChange={(e) => {
              setinputCorreoBeneficio(e.target.value);
            }}
            value={inputCorreoBeneficio}
            placeholder="Ingrese el correo del beneficiario: correo@finamo.mx"
          />
        </Col>
        <Col>
          <Button
            style={{ width: 150 }}
            type="primary"
            onClick={() => {
              getSolicitudesDataCorreo(inputCorreoBeneficio);
            }}
            icon={<SearchOutlined />}
          >
            Buscar
          </Button>
        </Col>
      </Row>

      <div
        style={{
          width: '100%',
          textAlign: 'left',
          fontSize: 17,
          fontWeight: 'bold',
          marginBottom: 10,
          marginTop: 10,
        }}
      >
        Seleccione una solicitud:
      </div>
      <TableSolicitudes
        rowKey="id"
        size="small"
        columns={columnsSolicitudes}
        dataSource={DataSolicitudes}
        pagination={false}
        ref={tableRefSolicitudes}
        scroll={{
          y: 110,
        }}
        rowSelection={{
          ...rowSelection,
          type: 'radio',
          columnWidth: 48,
        }}
      />

      <div
        style={{
          pointerEvents: divEnableFormulario,
          opacity: divFormularioOpacity,
        }}
      >
        <Divider orientation="left" style={{ fontWeight: 'bold' }}>
          Datos del permiso
        </Divider>

        <Row gutter={16} style={{ marginLeft: -40 }}>
          <Col span={4} style={{ textAlign: 'right' }}>
            <label>Requerimiento de Acceso: </label>
          </Col>
          <Col>
            <Radio.Group
              onChange={(e) => {
                handleRequerimientoAccesoChange(e.target.value);
              }}
              value={valueRequerimientoAcceso}
              disabled
            >
              <Radio value={1}>Sistema</Radio>
              <Radio value={2}>Carpeta</Radio>
              <Radio value={3}>Archivos</Radio>
            </Radio.Group>
          </Col>
        </Row>

        <Row
          gutter={16}
          style={{ marginTop: 20, display: divSistemas, marginLeft: -40 }}
        >
          <Col span={4} style={{ textAlign: 'right' }}>
            <label>Sistema: </label>
          </Col>
          <Col>
            <Select
              style={{ textAlign: 'left', width: 400 }}
              options={optionSistemas}
              onChange={handleSelectSistemasChange}
              value={formSistema}
              disabled
            />
          </Col>
        </Row>

        <Row
          gutter={16}
          style={{ marginTop: 20, display: divElemento, marginLeft: -40 }}
        >
          <Col span={4} style={{ textAlign: 'right' }}>
            <label>Nombre del Elemento: </label>
          </Col>
          <Col>
            <Input
              maxLength={60}
              style={{ width: 348 }}
              onChange={(e) => {
                setinputNombreElemento(e.target.value);
              }}
              value={inputNombreElemento}
              disabled
            />
          </Col>
        </Row>

        <Row
          gutter={16}
          style={{
            marginTop: 20,
            display:
              archivoVisible == ''
                ? 'none'
                : descargaVisible == ''
                  ? ''
                  : 'none',
            marginLeft: -40,
          }}
        >
          <Col span={4} style={{ textAlign: 'right' }}>
            <label>Tipo permiso: </label>
          </Col>

          <Col style={{ display: descargaVisible }}>
            <Checkbox onChange={onChange} checked={formChecked} disabled>
              Descarga
            </Checkbox>
          </Col>
        </Row>

        <Row
          gutter={16}
          style={{ marginTop: 20, display: archivoVisible, marginLeft: -40 }}
        >
          <Col span={4} style={{ textAlign: 'right' }}>
            <label>Tipo permiso: </label>
          </Col>
          <Col>
            <Radio.Group
              onChange={(e) => {
                setvalueTipoPermiso(e.target.value);
              }}
              value={valueTipoPermiso}
              disabled
            >
              <Radio value={1}>Solo lectura</Radio>
              <Radio value={2}>Escritura/Lectura</Radio>
            </Radio.Group>
          </Col>

          <Col>
            <Checkbox onChange={onChange} checked={formChecked} disabled>
              Descarga
            </Checkbox>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: 20, marginLeft: -40 }}>
          <Col span={4} style={{ textAlign: 'right' }}>
            <label>Tipo Acceso: </label>
          </Col>
          <Col>
            <Radio.Group
              onChange={(e) => {
                setvalueTipoAcceso(e.target.value);
                setdivPeriodoVisible(e.target.value == 1 ? '' : 'none');
              }}
              value={valueTipoAcceso}
              disabled
            >
              <Radio value={1}>Temporal</Radio>
              <Radio value={2}>Permanente</Radio>
            </Radio.Group>
          </Col>

          <Col
            span={3}
            style={{ textAlign: 'right', display: divPeriodoVisible }}
          >
            <Select
              style={{ textAlign: 'left', width: 165 }}
              options={optionsTemporal}
              value={formTemporal}
              onChange={setformTemporal}
              disabled
            />
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: 20, marginLeft: -40 }}>
          <Col span={4} style={{ textAlign: 'right' }}>
            <label>Motivo Baja: </label>
          </Col>
          <Col>
            <TextArea
              rows={3}
              value={inputJustificacion}
              onChange={(e) => {
                setinputJustificacion(e.target.value);
              }}
              placeholder="Especifique el motivo por el que quiere dar de baja este acceso."
              style={{ width: 880 }}
              ref={inputMotivoBajaRef}
            />
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: 20, marginLeft: -40 }}>
          <Col span={4} style={{ textAlign: 'right' }}>
            <label>Dueño del activo: </label>
          </Col>
          <Col>
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Seleccione una opción"
              options={optionUsuarios}
              value={formUsuarios}
              onChange={(e, option) => {
                handleDuenoChange(e, option);
              }}
              filterOption={UtilsClass.filterOption}
              style={{ textAlign: 'left', width: 400 }}
              disabled
            />
          </Col>

          <Col style={{ textAlign: 'right' }}>
            <label>Correo: </label>
          </Col>
          <Col>
            <Input
              maxLength={60}
              style={{ width: 400 }}
              onChange={handleInputCorreo}
              value={inputCorreo}
              placeholder="Username@finamo.mx"
              disabled
            />
          </Col>
        </Row>

        <div style={{ marginTop: 30 }}>
          <center>
            <Button
              style={{ width: 300, height: 50 }}
              type="primary"
              onClick={HandleAgregarSolicitud}
              icon={<MinusCircleOutlined />}
            >
              Dar de baja la solicitud
            </Button>
          </center>
        </div>
      </div>
    </div>
  );
}
