import React from 'react';
import {useParams  } from 'react-router-dom';
import {
  UserOutlined
} from '@ant-design/icons';
import Logo from '../../componentes/Logo';
import ParticlesBackground from '../../componentes/ParticlesBackground';
import { 
    Button,  
    Input,
    Layout,Card,Form,} from 'antd';
    const {  Content, Footer } = Layout;





const UserChangePassword = () => {
  const token = useParams();
  console.log("token",token);



    return(
      <Layout  
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        background: '#051D49',
        backgroundImage: `linear-gradient(rgb(20,9,9), rgb(5,29,73))`,
        height: '50vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
               <div
          style={{
            height: 50,
          }}
        />
        <ParticlesBackground/>
         
          
         

      <Content>
        
            <Card bordered={true} 
                style={{ maxWidth: 600 }}>
            <Form name="normal_login"
                      className="login-form"
                      initialValues={{
                          remember: true,
                        }}
                        onFinish={()=>null
                        }
                      >
            <Form.Item style={{textAlign: 'center', justifyContent:'center', alignItems:"center"}}>
                  <Logo/>
                </Form.Item> 
                
                <Form.Item  
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese el usuario!',
                    },
                  ]}
                >
                   
                      <Input type='email'
                      size="large"
                      prefix={<UserOutlined className="site-form-item-icon" />} 
                      placeholder="Username@finamo.mx"
                      defaultValue={token.token} />
                 
                </Form.Item>
                    
              
                    <Form.Item>        
                        <Button type="primary"  style={{ width: '100%'}}>Registrar</Button>
                    </Form.Item>
                    <Form.Item>
                        <a style={{
                             display: 'flex',
                             justifyContent: 'right',
                             alignItems: 'right',
                            
                        }} href="/" >
                            volver al inicio
                        </a>
                 </Form.Item>
                
                </Form>

            </Card>
            
      </Content>

            <Footer
                style={{
                    textAlign: 'center',
                    justifyContent:'center',
                    width:'100%',
                    display: 'flex',  
                    alignItems: 'center',
                    minHeight: '5vh',
                    height:'2%',
                    background: '#051D49',
                    color:"#fff"
                }}
                >
                2023 Finamo. Todos los derechos reservados ©
                </Footer>
             
        </Layout>
            
    )
};
export default UserChangePassword;