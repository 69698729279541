import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Structure from '../../componentes/StructureAdmin';
import AuthService from '../../services/auth.service';
import TicketsService from '../../services/tickets.Service';

import SolicitudesService from '../../services/solicitudes.Service';
import CatalogosService from '../../services/catalogos.Service';

import SolicitudesAlta from '../../componentes/Solicitudes/SolicitudesAlta';

import SolicitudesBajas from '../../componentes/Solicitudes/SolicitudesBajas';

import HardwareAlta from '../../componentes/Solicitudes/HardwareAlta';
import HardwareCondiguracion from '../../componentes/Solicitudes/HardwareConfiguracion';
import HardwareRevision from '../../componentes/Solicitudes/HardwareRevision';

import SoftwareAlta from '../../componentes/Solicitudes/SoftwareAlta';
import SoftwareCondiguracion from '../../componentes/Solicitudes/SoftwareConfiguracion';
import SoftwareRevision from '../../componentes/Solicitudes/SoftwareRevision';

import { Tabs, Button, Modal, ConfigProvider } from 'antd';

import { UserOutlined, CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { ComponentesContext } from '../../contextos/componentesContext';

const SolicitudesSoporte = () => {
  const navigate = useNavigate();
  const { opcion, subopcion, token } = useParams();

  const {
    ShowLoading,
    ModalMessage,
    UpdateUrlAutoriza,
    tableHeight,
    divContentWidth,
  } = useContext(ComponentesContext);
  const tableRefSolicitudes = useRef();
  const [tabAcceso, settabAcceso] = useState('alta');
  const [tabHardware, settabHardware] = useState('alta');
  const [tabSoftware, settabSoftware] = useState('alta');

  const [inputPrimerNombre, setinputPrimerNombre] = useState('');
  const [inputSegundoNombre, setinputSegundoNombre] = useState('');
  const [inputApellidoMaterno, setinputApellidoMaterno] = useState('');
  const [inputApellidoPaterno, setinputApellidoPaterno] = useState('');

  const [inputContrasena, setinputContrasena] = useState('');

  const [inputCorreo, setinputCorreo] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [isResponseModalOpen, setisResponseModalOpen] = useState(false);

  //EVENTOS CLASIFICACIÓN---------------------------------------------------------------------------------------------------

  const scrollToBottomSolicitudes = () => {
    if (tableRefSolicitudes.current) {
      const tableBody =
        tableRefSolicitudes.current.querySelector('.ant-table-body');
      tableBody.scrollTop = tableBody.scrollHeight;
    }
  };

  //---------------------------------------------------------------------------------------------------

  const getSolicitudesData = async () => {
    await SolicitudesService.getListSolicitudes();
  };

  //---------------------------------------------------------------------------------------------------
  const handleLimpiaFormulario = () => {
    setinputPrimerNombre('');
    setinputSegundoNombre('');
    setinputApellidoPaterno('');
    setinputApellidoMaterno('');
    setinputCorreo('');
    setinputContrasena('');
    setSelectedValue('');
  };

  const validaFormulario = () => {
    if (inputPrimerNombre == '') {
      ModalMessage('Mensaje', 'El primer nombre no puede estar en blanco.');
      return false;
    }

    if (inputSegundoNombre == '') {
      ModalMessage('Mensaje', 'El segundo nombre no puede estar en blanco.');
      return false;
    }

    if (inputApellidoPaterno == '') {
      ModalMessage('Mensaje', 'El apellido paterno no puede estar en blanco.');
      return false;
    }

    if (inputApellidoMaterno == '') {
      ModalMessage('Mensaje', 'El apellido materno no puede estar en blanco.');
      return false;
    }

    if (inputCorreo == '') {
      ModalMessage('Mensaje', 'El correo no puede estar en blanco.');
      return false;
    }

    if (inputContrasena == '') {
      ModalMessage('Mensaje', 'La contraseña no puede estar en blanco.');
      return false;
    }

    if (selectedValue == null) {
      ModalMessage(
        'Mensaje',
        'El combo tipo usuario no puede estar en blanco.'
      );
      return false;
    }

    return true;
  };

  useEffect(() => {
    let usr = AuthService.getCurrentUser();

    if (usr === null || usr === undefined) {
      UpdateUrlAutoriza(window.location.href);

      AuthService.logout();
      navigate('/login');
    }

    getSolicitudesData();

    return () => {};
  }, []);

  const tab2Style = {
    fontSize: 17,
    fontWeight: 'bold',
    marginTop: -10,
  };

  const itemsHardwarwe = [
    {
      key: 'alta',
      label: <div style={tab2Style}>Alta</div>,
      children: (
        <div
          style={{
            overflow: 'auto',
            width: '100%',
            marginTop: -15,
            maxHeight: tableHeight + 175,
            height: tableHeight + 175,
            backgroundColor: '#fff',
            marginLeft: 0,
            borderRadius: '10px 10px 10px 10px',
          }}
        >
          <HardwareAlta selectedTab={tabHardware}></HardwareAlta>
        </div>
      ),
    },

    {
      key: 'configuracion',
      label: <div style={tab2Style}>Configuración</div>,
      children: (
        <div
          style={{
            overflow: 'auto',
            width: '100%',
            marginTop: -15,
            maxHeight: tableHeight + 175,
            height: tableHeight + 175,
            backgroundColor: '#fff',
            marginLeft: 0,
            borderRadius: '10px 10px 10px 10px',
          }}
        >
          <HardwareCondiguracion></HardwareCondiguracion>
        </div>
      ),
    },
    {
      key: 'revision',
      label: <div style={tab2Style}>Revisión</div>,
      children: (
        <div
          style={{
            overflow: 'auto',
            width: '100%',
            marginTop: -15,
            maxHeight: tableHeight + 175,
            height: tableHeight + 175,
            backgroundColor: '#fff',
            marginLeft: 0,
            borderRadius: '10px 10px 10px 10px',
          }}
        >
          <HardwareRevision
            selectedTab={tabHardware}
            token={token}
          ></HardwareRevision>
        </div>
      ),
    },
  ];

  const itemsSoftware = [
    {
      key: 'alta',
      label: <div style={tab2Style}>Alta</div>,
      children: (
        <div
          style={{
            overflow: 'auto',
            width: '100%',
            marginTop: -15,
            maxHeight: tableHeight + 175,
            height: tableHeight + 175,
            backgroundColor: '#fff',
            marginLeft: 0,
            borderRadius: '10px 10px 10px 10px',
          }}
        >
          <SoftwareAlta selectedTab={tabSoftware}></SoftwareAlta>
        </div>
      ),
    },
    {
      key: 'configuracion',
      label: <div style={tab2Style}>Configuración</div>,
      children: (
        <div
          style={{
            overflow: 'auto',
            width: '100%',
            marginTop: -15,
            maxHeight: tableHeight + 175,
            height: tableHeight + 175,
            backgroundColor: '#fff',
            marginLeft: 0,
            borderRadius: '10px 10px 10px 10px',
          }}
        >
          <SoftwareCondiguracion></SoftwareCondiguracion>
        </div>
      ),
    },
    {
      key: 'revision',
      label: <div style={tab2Style}>Revisión</div>,
      children: (
        <div
          style={{
            overflow: 'auto',
            width: '100%',
            marginTop: -15,
            maxHeight: tableHeight + 175,
            height: tableHeight + 175,
            backgroundColor: '#fff',
            marginLeft: 0,
            borderRadius: '10px 10px 10px 10px',
          }}
        >
          <SoftwareRevision
            selectedTab={tabSoftware}
            token={token}
          ></SoftwareRevision>
        </div>
      ),
    },
  ];

  const items2 = [
    {
      key: 'alta',
      label: <div style={tab2Style}>Alta</div>,
      children: (
        <div
          style={{
            overflow: 'auto',
            width: '100%',
            marginTop: -15,
            maxHeight: tableHeight + 175,
            backgroundColor: '#fff',
            marginLeft: 0,
            borderRadius: '10px 10px 10px 10px',
          }}
        >
          <SolicitudesAlta></SolicitudesAlta>
        </div>
      ),
    },
    // {
    //   key: 'cambio',
    //   label: <div style={tab2Style}>Cambios</div>,
    //   children: (
    //     <div
    //       style={{
    //         overflow: 'auto',
    //         width: '100%',
    //         marginTop: -17,
    //         maxHeight: tableHeight + 175,
    //       }}
    //     >
    //       <SolicitudesCambios selectedTab={tabAcceso}></SolicitudesCambios>
    //     </div>
    //   ),
    // },
    {
      key: 'baja',
      label: <div style={tab2Style}>Bajas</div>,
      children: (
        <div
          style={{
            overflow: 'auto',
            width: '99%',
            marginTop: -15,
            maxHeight: tableHeight + 175,
            //backgroundColor: '#f5f5f5',
            marginLeft: -18,
            borderRadius: '10px 10px 10px 10px',
          }}
        >
          <SolicitudesBajas></SolicitudesBajas>
        </div>
      ),
    },
  ];

  const tabStyle = {
    fontSize: 21,
    fontWeight: 'bold',
  };

  const items = [
    {
      key: 'acceso',
      label: <div style={tabStyle}>Solicitud de Acceso</div>,
      children: (
        <ConfigProvider
          theme={{ tabs: { inkBarColor: 'red', itemActiveColor: '#f0f0f0' } }}
        >
          <div
            style={{
              width: divContentWidth + 28,
              marginLeft: 0,
              marginTop: -17,
              borderRadius: '0px 10px 10px 10px',
              paddingBottom: 8,
              borderLeft: '1px solid #f5f5f5',
              borderRight: '1px solid #f5f5f5',
              borderBottom: '1px solid #f5f5f5',
              minWidth: 1000,
            }}
          >
            <center>
              <Tabs
                defaultActiveKey={
                  subopcion == 'alta' ||
                  subopcion == 'cambio' ||
                  subopcion == 'baja'
                    ? subopcion
                    : 'alta'
                }
                style={{ marginLeft: 10 }}
                items={items2}
                onChange={(e) => {
                  navigate('/solicitudes-soporte/acceso/' + e + '/valor');
                  settabAcceso(e);
                }}
              />
            </center>
          </div>
        </ConfigProvider>
      ),
    },
    {
      key: 'hardware',
      label: <div style={tabStyle}>Solicitud de Hardware</div>,
      children: (
        <ConfigProvider
          theme={{ tabs: { inkBarColor: 'red', itemActiveColor: '#f0f0f0' } }}
        >
          <div
            style={{
              width: divContentWidth + 28,
              marginLeft: 0,
              marginTop: -17,
              borderRadius: '0px 10px 10px 10px',
              paddingBottom: 8,
              borderLeft: '1px solid #f5f5f5',
              borderRight: '1px solid #f5f5f5',
              borderBottom: '1px solid #f5f5f5',
              minWidth: 1000,
            }}
          >
            <center>
              <Tabs
                defaultActiveKey={
                  subopcion == 'alta' ||
                  subopcion == 'configuracion' ||
                  subopcion == 'revision' ||
                  subopcion == 'autorizacion'
                    ? subopcion
                    : 'alta'
                }
                style={{ marginLeft: 10 }}
                items={itemsHardwarwe}
                onChange={(e) => {
                  navigate('/solicitudes-soporte/hardware/' + e + '/valor');
                  settabHardware(e);
                }}
              />
            </center>
          </div>
        </ConfigProvider>
      ),
    },

    {
      key: 'software',
      label: <div style={tabStyle}>Solicitud de Software</div>,
      children: (
        <ConfigProvider
          theme={{ tabs: { inkBarColor: 'red', itemActiveColor: '#f0f0f0' } }}
        >
          <div
            style={{
              width: divContentWidth + 28,
              marginLeft: 0,
              marginTop: -17,
              borderRadius: '0px 10px 10px 10px',
              paddingBottom: 8,
              borderLeft: '1px solid #f5f5f5',
              borderRight: '1px solid #f5f5f5',
              borderBottom: '1px solid #f5f5f5',
              minWidth: 1000,
            }}
          >
            <center>
              <Tabs
                defaultActiveKey={
                  subopcion == 'alta' ||
                  subopcion == 'configuracion' ||
                  subopcion == 'revision' ||
                  subopcion == 'autorizacion'
                    ? subopcion
                    : 'alta'
                }
                style={{ marginLeft: 10 }}
                items={itemsSoftware}
                onChange={(e) => {
                  navigate('/solicitudes-soporte/software/' + e + '/valor');
                  settabSoftware(e);
                }}
              />
            </center>
          </div>
        </ConfigProvider>
      ),
    },
  ];

  const HandleUpdateUser = async () => {
    let resultadoValidacion = validaFormulario();

    if (!resultadoValidacion) {
      return false;
    }

    ShowLoading(true);
    const postUsuario = {
      id: idUsuarioUpdate,
      primerNombre: inputPrimerNombre,
      segundoNombre: inputSegundoNombre,
      apellidoPaterno: inputApellidoPaterno,
      apellidoMaterno: inputApellidoMaterno,
      correo: inputCorreo,
      contrasena: inputContrasena,
      tipoUsuario: selectedValue,
    };

    let usuarioData = await CatalogosService.postActualizarUsuario(postUsuario);

    if (usuarioData.estado == 500) {
      ModalMessage('Mensaje', 'Se produjo un error al agregar el registro.');
      ShowLoading(false);
      return false;
    }

    await TicketsService.getListaDatosUsuarios();

    setTimeout(function () {
      scrollToBottomSolicitudes();
    }, 1000);

    handleLimpiaFormulario();
    HandleCloseModal();

    ShowLoading(false);
    ModalMessage('Mensaje', 'El usuario se actualizo correctamente.');
  };

  const HandleCloseModal = async () => {
    handleLimpiaFormulario();
    setisResponseModalOpen(false);
  };

  const idUsuarioUpdate = '';

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: '#3c5c7c',
            colorPrimaryHover: '#517da9',
            colorPrimaryActive: '#517da9',
          },
        },
      }}
    >
      <Structure
        breadcrumb={'Bienvenido al sistema Fondeadora Finamo'}
        menuOption={'4'}
      >
        <center>
          <Tabs
            defaultActiveKey={
              opcion == 'acceso' || opcion == 'hardware' || opcion == 'software'
                ? opcion
                : 'acceso'
            }
            type="card"
            items={items}
            size="small"
            style={{ marginTop: 0 }}
            onChange={(e) => {
              switch (e) {
                case 'acceso':
                  navigate(
                    '/solicitudes-soporte/' + e + '/' + tabAcceso + '/valor'
                  );
                  break;
                case 'hardware':
                  navigate(
                    '/solicitudes-soporte/' + e + '/' + tabHardware + '/valor'
                  );
                  break;
                case 'software':
                  navigate(
                    '/solicitudes-soporte/' + e + '/' + tabSoftware + '/valor'
                  );
                  break;
                default:
                  break;
              }
            }}
          />
        </center>
        <Modal
          closable={false}
          style={{ marginTop: -50 }}
          destroyOnClose={true}
          open={isResponseModalOpen}
          width={1100}
          title={
            <div>
              <UserOutlined /> &nbsp; Solicitud de cambio de Acceso.
            </div>
          }
          footer={[
            <Button key={'btnCerrar'} type="primary" onClick={HandleCloseModal}>
              <CloseOutlined />
              Cerrar
            </Button>,
            <Button
              key={'btnGuardar'}
              type="primary"
              onClick={() => {
                HandleUpdateUser();
              }}
            >
              <SaveOutlined />
              Guardar
            </Button>,
          ]}
        >
          <SolicitudesAlta></SolicitudesAlta>
        </Modal>
      </Structure>
    </ConfigProvider>
  );
};

export default SolicitudesSoporte;
