import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  Layout,
  theme,
  ConfigProvider,
  Space,
  Menu,
  Typography,
  Avatar,
  Badge,
  notification,
  Modal,
  Card,
  Button,
  Upload,
  Tooltip,
  Modal as ModalContrasena,
  Input,
  Spin,
  Tour,
  Row,
  Col,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/auth.service';
import NotificationsService from '../services/nofications.Service';
import TicketsService from '../services/tickets.Service';
import ReturnUserTypeClass from '../clases/returnUserType.class';
import UsuariosService from '../services/usuarios.Service';
import returnValidUploadFileClass from '../clases/returnValidUploadFile.class';
import LocalData from '../persistencia/LocalData';

import logoTickets from '../asset/images/LogoTicketsBlanco.png';

import './StructureUser.css';
import {
  ProfileOutlined,
  LogoutOutlined,
  UserOutlined,
  HomeOutlined,
  ToolOutlined,
  BellOutlined,
  SettingOutlined,
  ReadOutlined,
  SaveOutlined,
  CloseOutlined,
  BulbOutlined,
  FileDoneOutlined,
  ContainerOutlined,
  MenuOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { ComponentesContext } from '../contextos/componentesContext';

export default function Structure({ children, breadcrumb, menuOption }) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const URL = process.env.REACT_APP_URL_API_SOPORTE;

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const [open, setOpen] = useState(false);

  const steps = [
    {
      title: 'Cambiar foto de perfil.',
      description:
        'Dar click en la foto del perfil actual y seleccionar la nueva imagen.',
      cover: (
        <img
          alt="tour.png"
          src="https://soportearchivos.s3.us-west-2.amazonaws.com/rect177.png"
        />
      ),
      nextButtonProps: { description: 'nuevo' },
      target: () => ref1.current,
    },
    {
      title: 'Cambiar contraseña',
      description:
        'Dar click al botón Cambiar contraseña, posteriormente ingresar la contraseña actual y la nueva contraseña.',
      cover: (
        <img
          alt="tour.png"
          src="https://soportearchivos.s3.us-west-2.amazonaws.com/rect178.png"
        />
      ),
      target: () => ref1.current,
    },
  ];

  const {
    notificationsCounter,
    UpdateNotificationsCounter,
    UpdateDataLista,
    UpdatesettableHeight,
    ModalMessage,
    userImagenUrl,
    UpdatesetuserImagenUrl,
    UpdatesetdivContentWidth,
    tableHeight,
  } = useContext(ComponentesContext);
  const [user, setUser] = useState({});
  const [name, setName] = useState('?');
  const [nombreUsuario, setNombreUsuario] = useState(user.fullname);
  const [Correo, setCorreo] = useState(user.correo);
  const [loadingModal, setloadingModal] = useState(false);
  const [imagenUsuario, setimagenUsuario] = useState(user.imagen_usuario);
  const [contentWidth, setcontentWidth] = useState(1050);
  //const [imagenUsuario, setimagenUsuario] = useState(user.imagen_usuario);

  const { Header, Footer, Sider, Content } = Layout;
  const navigate = useNavigate();
  let notificationsInterval = useRef();
  const { Title } = Typography;
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const [imageUrl, setImageUrl] = useState('');
  const [modal1Open, setModal1Open] = useState(false);
  const [messageAvatar, setmessageAvatar] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [OpenmodalContrasena, setOpenmodalContrasena] = useState(false);

  const headerStyle = {
    textAlign: 'right',
    color: '#fff',
    height: 50,
    paddingInline: 50,
    paddingTop: 4,
    lineHeight: '45px',
    backgroundColor: '#2b455a',
  };
  const contentStyle = {
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '120px',
    color: '#000000',
    padding: 20,
  };

  const openNotificationAlerts = (count) => {
    let strMensaje = '';
    if (count == 1) {
      strMensaje = 'Tienes ' + count + ' notificación pendiente de ver.';
    } else {
      strMensaje = 'Tienes ' + count + ' notificaciones pendiente de ver.';
    }

    notification.open({
      message: 'Notificación',
      description: (
        <Typography.Title level={3} style={{ margin: 0, color: '#081b3e' }}>
          <BellOutlined style={{ fontSize: 30, color: '#f9dc06' }} />{' '}
          {strMensaje}
        </Typography.Title>
      ),
      className: 'custom-class',
      style: {
        width: 600,
      },
      duration: 4,
    });
  };

  const getTicketsData = async () => {
    let ticketsData = await TicketsService.getTicketsSoporte();
    UpdateDataLista(ticketsData.data);
  };

  const getNotificacions = async () => {
    let notificationsData = await NotificationsService.getNotifications();

    if (notificationsData.estado == 500) {
      return false;
    }

    let totalNotificaciones = 0;
    for (let index = 0; index < notificationsData.data.length; index++) {
      if (notificationsData.data[index].mostrar == 1) {
        totalNotificaciones++;
      }
    }
    UpdateNotificationsCounter(notificationsData.data.length);

    if (totalNotificaciones > 0) {
      openNotificationAlerts(totalNotificaciones);
      getTicketsData();
    }
  };

  let NotificationBeginInterval = () => {
    notificationsInterval.current = setInterval(async () => {
      let usr = AuthService.getCurrentUser();
      if (usr === null || usr === undefined) {
        clearInterval(notificationsInterval.current);
        return false;
      }
      getNotificacions();
    }, 60000);
  };

  const handlePreview = async (file) => {
    const fileExtension = file.name.split('.').pop();

    switch (fileExtension.toLowerCase()) {
      case 'apng':
      case 'avif':
      case 'gif':
      case 'jpg':
      case 'jpeg':
      case 'jfif':
      case 'pjpeg':
      case 'pjp':
      case 'png':
      case 'svg':
      case 'webp':
      case 'bmp':
      case 'ico':
      case 'cur':
      case 'tif':
      case 'tiff':
        file.preview = file.url;
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(
          file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        );
        break;
    }
  };

  useEffect(() => {
    UpdatesettableHeight(window.innerHeight - 400);
    UpdatesetdivContentWidth(window.innerWidth - 410);
    //console.log(tableHeight);
    setcontentWidth(window.innerWidth * 0.75);
    const handleResize = () => {
      UpdatesettableHeight(window.innerHeight - 400);
      UpdatesetdivContentWidth(window.innerWidth - 410);
      setcontentWidth(window.innerWidth * 0.75);
      //console.log(tableHeight);
    };

    //let divHeight = document.getElementById('contenedor-admin').clientHeight;
    //UpdatesettableHeight(divHeight);
    let usr = AuthService.getCurrentUser();
    //console.log(usr);
    if (usr === null || usr === undefined) {
      setUser({});
      setName(' ');
      setmessageAvatar('');
      setCorreo('');
    } else {
      setUser(usr);
      setName(usr.primerNombre);
      setNombreUsuario(usr.fullname);
      setCorreo(usr.correo);

      //console.log('Entro ADMINISTRADOR');

      //validaUsuarioSoporte();

      //let imagen_avatar = UsuariosService.postRegresaImagenAvatar();
      //console.log(imagen_avatar);
      setimagenUsuario('');
      UpdatesetuserImagenUrl('');

      //console.log(imagenUsuario);
      //setimagenUsuario(usr.imagen_usuario);
      setmessageAvatar('V');

      window.addEventListener('resize', handleResize);

      //console.log('usuario', user);

      // const cachedImageUrl = localStorage.getItem("avatarImageUrl");
      // console.log(cachedImageUrl);
      // if (cachedImageUrl) {
      //   setImageUrl(cachedImageUrl);
      // } else {
      //   // Si no está almacenada en caché, cargar la imagen y guardar la URL en localStorage
      //   const newImageUrl = user.imagen_usuario; // Reemplaza con la URL de la imagen
      //   setImageUrl(newImageUrl);
      //   localStorage.setItem("avatarImageUrl", newImageUrl);
      // }
    }

    // let tipoUsuario = await ReturnUserTypeClass.ReturnUserType();
    // console.log(tipoUsuario);
    // if (tipoUsuario.estado != 500) {
    //   if (tipoUsuario.data.tipo == "2") {
    //     NotificationBeginInterval();
    //   }
    // }
    NotificationBeginInterval();

    return () => {
      //window.removeEventListener("scroll", handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const validaUsuarioSoporte = async () => {
    let tipoUsuario = await UsuariosService.getValidaUsuarioSoporte();

    //console.log('Usuario Soporte', tipoUsuario);

    if (tipoUsuario.estado == 200) {
      if (!tipoUsuario.usuarioSoporte) {
        navigate('/');
      }
    }
  };

  const handleNext = (option) => {
    navigate(option);
  };

  const logOut = () => {
    AuthService.logout();
    navigate('/login');
  };

  const itemsMenu = [
    getItem(
      <a onClick={() => handleNext('/')}>Inicio</a>,
      '1',
      <HomeOutlined />
    ),
    getItem(
      <a onClick={() => handleNext('/lista-tickets')}>Lista de Tickets</a>,
      '2',
      <ProfileOutlined />
    ),
    getItem(
      <a onClick={() => handleNext('/crea-ticket')}>Crear Ticket</a>,
      '3',
      <PlusOutlined />
    ),

    getItem(
      <a onClick={() => handleNext('/solicitudes')}>Solicitudes</a>,
      '4',
      <FileDoneOutlined />
    ),

    getItem(
      <a onClick={() => logOut()}>Cerrar Sesión</a>,
      '5',
      <LogoutOutlined />
    ),
  ];

  const handleAvatar = () => {
    setModal1Open(true);
  };

  const handleBeforeUpload = async (file) => {
    setloadingModal(true);

    let flagArchivoValido =
      await returnValidUploadFileClass.returnImageValidUploadFile(file.type);
    if (flagArchivoValido == false) {
      ModalMessage(
        'Mensaje',
        'El tipo de archivo que se desea adjuntar no es valido.'
      );
      return false;
    }

    const fileSizeLimit = 1 * 1024 * 1024; // 5MB

    if (file.size > fileSizeLimit) {
      ModalMessage('Mensaje', 'El archivo no puede pesar mas de 1MB.');
      return false; // Cancela la carga del archivo
    }
    //setloadingModal(true);
    return true;
  };

  const handleChange = async () => {
    //setloadingModal(true);
    setTimeout(async function () {
      let UsuariosData = await TicketsService.getDatosUsuarios();
      setimagenUsuario(UsuariosData.dataEmp.imagen_usuario);
      //console.log('Usuarios data', UsuariosData.dataEmp);
      LocalData.save('user', UsuariosData.dataEmp);
      setUser(UsuariosData.dataEmp);
      //setFileList(DetalleAdjuntosData.data);
      setloadingModal(false);
    }, 8000);
    //setloadingModal(false);
  };

  const handleOpenModalContra = () => {
    setactualpassword('');
    setPassword('');
    setrepassword('');
    setOpenmodalContrasena(true);
  };

  const handleCloseModalContra = () => {
    setOpenmodalContrasena(false);
  };

  const uploadButton = (
    <div>
      <Tooltip placement="bottom" title={'Click para cambiar imagen.'}>
        <Avatar
          src={imagenUsuario}
          shape="square"
          size={84}
          icon={<UserOutlined />}
          style={{ backgroundColor: '#04142b' }}
        />
      </Tooltip>
    </div>
  );

  const [password, setPassword] = useState('');
  const [repassword, setrepassword] = useState('');
  const [actualpassword, setactualpassword] = useState('');

  const handleActualPasswordChange = (event) => {
    const inputPassword = event.target.value;
    if (!inputPassword.includes(' ')) {
      setactualpassword(inputPassword);
    }
  };

  const handlePasswordChange = (event) => {
    const inputPassword = event.target.value;
    if (!inputPassword.includes(' ')) {
      setPassword(inputPassword);
    }
  };

  const handleRePasswordChange = (event) => {
    const inputPassword = event.target.value;
    if (!inputPassword.includes(' ')) {
      setrepassword(inputPassword);
    }
  };

  const handleGuardarContrasena = async () => {
    if (password == '' || repassword == '' || actualpassword == '') {
      ModalMessage('Mensaje', 'Las contraseñas no pueden estar en blanco.');

      return false;
    }

    if (password != repassword) {
      ModalMessage(
        'Mensaje',
        'Las contraseñas que ingresaste no coinciden. Asegúrate de escribir la misma contraseña en ambos campos para poder continuar.'
      );
      return false;
    }
    const jsonPost = {
      contrasena_actual: actualpassword,
      contrasena: password,
    };
    let respuesta = await UsuariosService.postCambioContrasena(jsonPost);
    //console.log(respuesta);

    ModalMessage('Mensaje', respuesta.mensaje);

    if (respuesta.estado != 403) {
      setactualpassword('');
      setPassword('');
      setrepassword('');
      setOpenmodalContrasena(false);
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#04142b',
        },
      }}
    >
      <div style={{ backgroundColor: '#dedede', minWidth: 1400 }}>
        <Row>
          <Col style={{ width: '100%' }}>
            <div style={headerStyle}>
              <Row>
                <Col
                  style={{ width: '30%', textAlign: 'left', paddingLeft: 27 }}
                >
                  {' '}
                  <img src={logoTickets} style={{ width: 140 }}></img>{' '}
                </Col>
                <Col style={{ width: '70%' }}>
                  {/* <Tooltip title="Novedades.">
                  <Button
                    type="primary"
                    shape="circle"
                    danger
                    icon={<BulbOutlined />}
                    onClick={() => setOpen(true)}
                  />
                </Tooltip> */}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; Bienvenido,{' '}
                  {nombreUsuario} &nbsp;{' '}
                  <a onClick={(e) => e.preventDefault()}>
                    <Badge size="small" count={notificationsCounter}>
                      <Avatar
                        onClick={handleAvatar}
                        src={user.imagen_usuario}
                        size="large"
                        icon={<UserOutlined />}
                        ref={ref1}
                      />
                    </Badge>
                  </a>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row
          style={{ minWidth: 1400, height: tableHeight + 345 }}
          className="custom-row"
        >
          <Col
            style={{
              minWidth: 300,
              maxWidth: 300,
              widows: '20%',
              backgroundColor: '#ebeaea',
              display: 'flex',
              justifyContent: 'center',
              paddingTop: 20,
              minHeight: 500,
            }}
            className="custom-col"
            flex="1 0 auto"
          >
            <div
              style={{
                borderRadius: 10,
                paddingTop: 20,
                width: 256,
              }}
            >
              <div
                style={{
                  backgroundColor: '#2c445c',
                  borderTopRightRadius: 10,
                  borderTopLeftRadius: 10,

                  height: 70,
                  display: 'flex',
                  alignContent: 'baseline',
                  zIndex: 1,
                }}
              >
                <Title style={{ color: '#fff', marginLeft: 35 }} level={4}>
                  <MenuOutlined /> &nbsp; Menu
                </Title>
              </div>

              <Menu
                style={{
                  width: 256,
                  fontWeight: 'bold',
                  borderRadius: 0,
                  borderWidth: 0,
                  color: 'black',
                  paddingTop: 10,
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10,
                }}
                selectedKeys={[menuOption]}
                mode="inline"
                items={itemsMenu}
              />
            </div>
          </Col>
          <Col
            style={{
              backgroundColor: '#dedede',
              minHeight: 500,
            }}
            className="custom-col"
            flex="1 0 auto"
          >
            <div style={contentStyle}>
              <div
                className="content-main"
                style={{
                  backgroundColor: 'white',

                  padding: 20,
                  height: tableHeight + 260,
                  minHeight: tableHeight + 260,
                }}
              >
                {children}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        style={{ top: 15, left: window.innerWidth / 2 - 280 }}
        open={modal1Open}
        onOk={() => setModal1Open(false)}
        onCancel={() => setModal1Open(false)}
        footer={<></>}
        maskClosable={false}
        closeIcon={
          <CloseOutlined
            style={{
              backgroundColor: '#e3e3e3',
              borderRadius: 5,
              fontSize: 20,
              padding: 5,
            }}
          />
        }
      >
        <Spin spinning={loadingModal} active={true}>
          <input
            type="file"
            id="target"
            style={{ display: 'none' }}
            accept="image/png, image/gif, image/jpeg"
          />
          <Card
            style={{
              width: '100%',
              marginTop: 35,
            }}
            actions={[
              <Button
                style={{ color: '#696969' }}
                icon={<SettingOutlined />}
                size="large"
                onClick={handleOpenModalContra}
              >
                Cambiar contraseña
              </Button>,
              // <Button
              //   onClick={(e) => {
              //     setModal1Open(false);
              //   }}
              //   style={{ color: '#696969', width: 200 }}
              //   icon={<CloseCircleOutlined />}
              //   size="large"
              // >
              //   Cerrar
              // </Button>,
            ]}
          >
            <table cellSpacing={0}>
              <tbody>
                <tr>
                  <td rowSpan={2}>
                    <Upload
                      name="archivo"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action={`${URL}cambia-imagen?token=${AuthService.getCurrentToken()}`}
                      beforeUpload={handleBeforeUpload}
                      onChange={handleChange}
                    >
                      {userImagenUrl ? (
                        <img
                          src={user.imagen_usuario}
                          alt="avatar"
                          style={{ width: '100%' }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </td>
                  <td style={{ verticalAlign: 'bottom' }}>
                    <b>Usuario:</b> {nombreUsuario}
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: 'top' }}>
                    {' '}
                    <b>Correo:</b> {Correo}
                  </td>
                </tr>
              </tbody>
            </table>
          </Card>
        </Spin>
      </Modal>

      <ModalContrasena
        style={{ top: 20, left: window.innerWidth / 2 - 280 }}
        open={OpenmodalContrasena}
        footer={<></>}
        closable={false}
      >
        <ConfigProvider
          theme={{
            components: {
              Button: {
                colorPrimary: '#3c5c7c',
                colorPrimaryHover: '#517da9',
                colorPrimaryActive: '#517da9',
              },
            },
          }}
        >
          <b>Contraseña actual:</b>
          <Input.Password
            placeholder="Ingrese contraseña 123..."
            style={{ width: 480, marginBottom: 20 }}
            value={actualpassword}
            onChange={handleActualPasswordChange}
          />

          <b>Nueva contraseña:</b>
          <Input.Password
            placeholder="Ingrese contraseña 123..."
            style={{ width: 480, marginBottom: 20 }}
            value={password}
            onChange={handlePasswordChange}
          />
          <b>Vuelve a ingresar la nueva contraseña:</b>
          <Input.Password
            placeholder="Ingrese nuevamente la contraseña 123..."
            style={{ width: 480, marginBottom: 20 }}
            value={repassword}
            onChange={handleRePasswordChange}
          />
          <center>
            <table>
              <tbody>
                <tr>
                  <td>
                    <Button
                      style={{ width: 190 }}
                      type="primary"
                      danger
                      onClick={() => handleCloseModalContra()}
                      icon={<CloseOutlined />}
                    >
                      Salir
                    </Button>
                  </td>
                  <td>
                    <Button
                      style={{ width: 190 }}
                      type="primary"
                      onClick={() => handleGuardarContrasena()}
                      icon={<SaveOutlined />}
                    >
                      Guardar contraseña
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </center>
        </ConfigProvider>
      </ModalContrasena>
    </ConfigProvider>
  );
}
