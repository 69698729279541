import React, { useState, useEffect, useContext, useRef } from 'react';
import Structure from '../../componentes/StructureAdmin';
import AuthService from '../../services/auth.service';
import TicketsService from '../../services/tickets.Service';
import CatalogosService from '../../services/catalogos.Service';
import { useNavigate, useLocation } from 'react-router-dom';
import Highlighter from 'react-highlight-words';

import {
  Tabs,
  Input,
  Table,
  Table as TableEmpresa,
  Table as TablePlaza,
  Table as TableUsuarios,
  Typography,
  Button,
  Modal,
  Select,
  Tag,
  Space,
  ConfigProvider,
  Tooltip,
} from 'antd';

const { TextArea } = Input;
import {
  CaretUpOutlined,
  CaretDownOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleFilled,
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  CloseOutlined,
  CommentOutlined,
  SearchOutlined,
  EditOutlined,
  SaveOutlined,
  CheckOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { ComponentesContext } from '../../contextos/componentesContext';
import getColumnSearchProps from '../../componentes/SearchPropsCustom';

import UtilsClass from '../../clases/Utils.class';

const CatalogoTicket = () => {
  const { tableHeight, ShowLoading, ModalMessage, divContentWidth } =
    useContext(ComponentesContext);
  const tableRef = useRef();
  const tableRefEmpresa = useRef();
  const tableRefPlaza = useRef();
  const [user, setUser] = useState({});
  const [Data, setData] = useState([]);
  const [valorRow, setvalorRow] = useState('');
  const [ordenRow, setordenRow] = useState('');
  const [inputValue, setInputValue] = useState('');
  const { confirm } = Modal;
  const { Title } = Typography;

  const [inputPrimerNombre, setinputPrimerNombre] = useState('');
  const [inputSegundoNombre, setinputSegundoNombre] = useState('');
  const [inputApellidoMaterno, setinputApellidoMaterno] = useState('');
  const [inputApellidoPaterno, setinputApellidoPaterno] = useState('');
  const [inputCorreo, setinputCorreo] = useState('');
  const [inputContrasena, setinputContrasena] = useState('');

  const [inputUsuario, setinputUsuario] = useState('');

  const [inputNombrePuesto, setinputNombrePuesto] = useState('');

  const [formReporta, setformReporta] = useState('');

  const [DataEmpresas, setDataEmpresas] = useState([]);
  const [DataPlazas, setDataPlazas] = useState([]);
  const [DataUsuarios, setDataUsuarios] = useState([]);
  const [DataPuestos, setDataPuestos] = useState([]);

  const [DataSistemas, setDataSistemas] = useState([]);

  const [optionReporta, setoptionReporta] = useState([]);
  const [optionUsuarios, setoptionUsuarios] = useState('');

  const [selectedValue, setSelectedValue] = useState('');
  const [isResponseModalOpen, setisResponseModalOpen] = useState(false);

  const [isPuestosModalOpen, setisPuestosModalOpen] = useState(false);
  const [idPuestoModal, setidPuestoModal] = useState(null);

  const [isElementoModalOpen, setisElementoModalOpen] = useState(false);

  const [idSistemaModal, setidSistemaModal] = useState(null);
  const [isSistemaModalOpen, setisSistemaModalOpen] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const [inputNombreElemento, setinputNombreElemento] = useState('');
  const [inputDescripcionElemento, setinputDescripcionElemento] = useState('');

  const [formTipoElemento, setformTipoElemento] = useState('');

  const [DataElemento, setDataElemento] = useState([]);

  const [formDirector, setformDirector] = useState('');

  const [disableButton, setdisableButton] = useState(false);

  const [disableRuta, setdisableRuta] = useState(true);
  const [inputRecursosRuta, setinputRecursosRuta] = useState('');

  //SISTEMAS
  const [inputNombreSistema, setinputNombreSistema] = useState('');
  const [inputTipo, setinputTipo] = useState('');

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const EnumCatalogo = {
    Clasificacion: 0,
    Empresa: 1,
    Plaza: 2,
    Usuario: 3,
    Sistema: 4,
    Puesto: 5,
    Elemento: 6,
  };

  const optionsTipo = [
    {
      value: '',
      label: '',
    },
    {
      value: '1',
      label: 'Clientes',
    },
    {
      value: '2',
      label: 'Soporte',
    },
  ];

  const showConfirm = (opcion, idTabla = '', activo = 'Activo') => {
    let strMensaje = 'borrar';
    if (activo == 'Inactivo') {
      strMensaje = 'activar';
    }

    confirm({
      title: 'Confirmación',
      icon: <ExclamationCircleFilled />,
      content: 'Esta segur@ de ' + strMensaje + ' el registro seleccionado?',
      okText: 'Sí',
      cancelText: 'No, cancelar',
      onOk() {
        switch (opcion) {
          case EnumCatalogo.Clasificacion:
            HandleBorrarClasificacion(idTabla, activo);
            break;
          case EnumCatalogo.Empresa:
            HandleBorrarEmpresas(idTabla, activo);
            break;
          case EnumCatalogo.Plaza:
            HandleBorrarPlazas(idTabla, activo);
            break;
          case EnumCatalogo.Usuario:
            HandleBorrarUsuarios(idTabla, activo);
            break;
          case EnumCatalogo.Sistema:
            HandleBorrarSistema(idTabla, activo);
            break;
          case EnumCatalogo.Puesto:
            HandleBorrarPuesto(idTabla, activo);
            break;
          case EnumCatalogo.Elemento:
            HandleBorrarElemento(idTabla, activo);
            break;
          default:
            break;
        }
      },
      onCancel() {},
    });
  };

  //EVENTOS CLASIFICACIÓN---------------------------------------------------------------------------------------------------
  const getClasificacionData = async () => {
    let clasificacionData = await TicketsService.getClasificacionTicket();
    setData(clasificacionData.data);
  };

  const scrollToBottomClasificacion = () => {
    if (tableRef.current) {
      const tableBody = tableRef.current.querySelector('.ant-table-body');
      tableBody.scrollTop = tableBody.scrollHeight;
    }
  };

  const scrollToBottomEmpresa = () => {
    if (tableRefEmpresa.current) {
      const tableBody =
        tableRefEmpresa.current.querySelector('.ant-table-body');
      tableBody.scrollTop = tableBody.scrollHeight;
    }
  };

  const HandleAgregarClasificacion = async () => {
    ShowLoading(true);
    if (inputValue == '') {
      ModalMessage(
        'Mensaje',
        'La clasificación a agregar no puede estar en blanco.'
      );
      setdisableButton(false);
      ShowLoading(false);
      return false;
    }

    const postClasificacion = { clasificacion: inputValue };
    let clasificacionData =
      await CatalogosService.postAgregarClasificacion(postClasificacion);

    let ListaData = await TicketsService.getClasificacionTicket();
    setData(ListaData.data);

    setTimeout(function () {
      scrollToBottomClasificacion();
    }, 1000);

    setInputValue('');
    ShowLoading(false);
    setdisableButton(false);
    ModalMessage('Mensaje', 'La clasificación se agrego correctamente.');
  };

  const HandleBorrarClasificacion = async (idRegistro, activo) => {
    let intActivo = '0';
    if (activo == 'Inactivo') {
      intActivo = '1';
    }

    const jsonPost = { id_clasificacion: idRegistro, activo: intActivo };
    let respuesta = await CatalogosService.postBorrarClasificacion(jsonPost);
    if (respuesta.estado == 500) {
      ModalMessage('Mensaje', 'Se produjo un error al borrar el registro.');
      return false;
    }

    getClasificacionData();

    let strMensaje = 'borro';
    if (intActivo == '1') {
      strMensaje = 'activo';
    }

    ModalMessage('Mensaje', 'El registro se ' + strMensaje + ' correctamente.');
  };

  const HandleSeleccionaRegistroClasificacion = (record) => {
    //console.log(record);
    setvalorRow(record.value);
    setordenRow(record.orden);
  };

  const handleInputChangeClasificacion = (e) => {
    setInputValue(e.target.value);
  };
  //------------------------------------------------------------------------------------------------

  //------------------------------------------------------------------------------------------------
  //EVENTOS PLAZAS
  const columnsPlazas = [
    {
      title: 'Opciones',
      dataIndex: 'value',
      key: 'value',
      align: 'center',
      width: 200,
      render: (_, record) => (
        <div>
          <Button
            type="primary"
            icon={<CaretUpOutlined />}
            onClick={() => {
              HandleSubirRegistro(EnumCatalogo.Plaza, record.value);
            }}
            style={{ width: 40, height: 34 }}
          ></Button>

          <Button
            type="primary"
            icon={<CaretDownOutlined />}
            onClick={() => {
              HandleBajarRegistro(EnumCatalogo.Plaza, record.value);
            }}
            style={{ width: 40, height: 34, marginLeft: 10 }}
          ></Button>

          <Button
            type="primary"
            icon={
              record.id_activo == 1 ? <DeleteOutlined /> : <CheckOutlined />
            }
            danger={record.id_activo == 1 ? true : false}
            onClick={() => {
              showConfirm(EnumCatalogo.Plaza, record.value, record.activo);
            }}
            style={{ marginLeft: 10, width: 40, height: 34 }}
          ></Button>
        </div>
      ),
    },
    {
      width: 60,
      title: 'No.',
      dataIndex: 'orden',
      key: 'orden',
    },
    {
      title: 'Plaza',
      dataIndex: 'label',
      key: 'label',
    },
  ];

  const scrollToBottomPlaza = () => {
    if (tableRefPlaza.current) {
      const tableBody = tableRefPlaza.current.querySelector('.ant-table-body');
      tableBody.scrollTop = tableBody.scrollHeight;
    }
  };

  const getPlazasData = async () => {
    let plazasData = await TicketsService.getPlazasTicket();
    setDataPlazas(plazasData.data);
  };

  const handleInputChangePlazas = (e) => {
    setInputValue(e.target.value);
  };

  const HandleAgregarPlazas = async () => {
    ShowLoading(true);
    if (inputValue == '') {
      ModalMessage('Mensaje', 'La plaza a agregar no puede estar en blanco.');
      ShowLoading(false);
      setdisableButton(false);
      return false;
    }

    const postPlaza = { plaza: inputValue };
    let plazaData = await CatalogosService.postAgregarPlazas(postPlaza);

    let ListaData = await TicketsService.getPlazasTicket();
    setDataPlazas(ListaData.data);

    setTimeout(function () {
      scrollToBottomPlaza();
    }, 1000);

    setInputValue('');
    setdisableButton(false);
    ShowLoading(false);
    ModalMessage('Mensaje', 'La Plaza se agrego correctamente.');
  };

  const HandleBorrarPlazas = async (idRegistro, activo) => {
    let intActivo = '0';
    if (activo == 'Inactivo') {
      intActivo = '1';
    }

    const jsonPost = { id_plaza: idRegistro, activo: intActivo };
    let respuesta = await CatalogosService.postBorrarPlazas(jsonPost);
    if (respuesta.estado == 500) {
      ModalMessage('Mensaje', 'Se produjo un error al borrar el registro.');
      return false;
    }

    getPlazasData();

    let strMensaje = 'borro';
    if (intActivo == '1') {
      strMensaje = 'activo';
    }

    ModalMessage('Mensaje', 'El registro se ' + strMensaje + ' correctamente.');
  };

  const HandleSeleccionaRegistroPlazas = (record) => {
    //console.log(record);
    setvalorRow(record.value);
    setordenRow(record.orden);
  };
  //------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------
  //EMPRESAS
  const getEmpresasData = async () => {
    let empresasData = await TicketsService.getEmpresasTicket();
    setDataEmpresas(empresasData.data);
  };

  const handleInputChangeEmpresa = (e) => {
    setInputValue(e.target.value);
  };

  const HandleAgregarEmpresa = async () => {
    ShowLoading(true);
    if (inputValue == '') {
      ModalMessage('Mensaje', 'La empresa a agregar no puede estar en blanco.');
      setdisableButton(false);
      ShowLoading(false);
      return false;
    }
    const postEmpresa = { empresa: inputValue };
    let empresaData = await CatalogosService.postAgregarEmpresas(postEmpresa);

    let ListaData = await TicketsService.getEmpresasTicket();
    setDataEmpresas(ListaData.data);

    setTimeout(function () {
      scrollToBottomEmpresa();
    }, 1000);

    setInputValue('');
    ShowLoading(false);
    setdisableButton(false);
    ModalMessage('Mensaje', 'La Empresa se agrego correctamente.');
  };

  const columnsEmpresas = [
    {
      title: 'Opciones',
      dataIndex: 'value',
      key: 'value',
      align: 'center',
      width: 200,
      render: (_, record) => (
        <div>
          <Button
            type="primary"
            icon={<CaretUpOutlined />}
            onClick={() => {
              HandleSubirRegistro(EnumCatalogo.Empresa, record.value);
            }}
            style={{ width: 40, height: 34 }}
          ></Button>
          <Button
            type="primary"
            icon={<CaretDownOutlined />}
            onClick={() => {
              HandleBajarRegistro(EnumCatalogo.Empresa, record.value);
            }}
            style={{ width: 40, height: 34, marginLeft: 10 }}
          ></Button>

          <Button
            type="primary"
            icon={
              record.id_activo == 1 ? <DeleteOutlined /> : <CheckOutlined />
            }
            danger={record.id_activo == 1 ? true : false}
            onClick={() => {
              showConfirm(EnumCatalogo.Empresa, record.value, record.activo);
            }}
            style={{ marginLeft: 10, width: 40, height: 34 }}
          ></Button>
        </div>
      ),
    },
    {
      width: 60,
      title: 'No.',
      dataIndex: 'orden',
      key: 'orden',
    },
    {
      title: 'Empresa',
      dataIndex: 'label',
      key: 'label',
    },
  ];

  const HandleBorrarEmpresas = async (idRegistro, activo) => {
    let intActivo = '0';
    if (activo == 'Inactivo') {
      intActivo = '1';
    }

    const jsonPost = { id_empresa: idRegistro, activo: intActivo };
    let respuesta = await CatalogosService.postBorrarEmpresas(jsonPost);
    if (respuesta.estado == 500) {
      ModalMessage('Mensaje', 'Se produjo un error al borrar el registro.');
      return false;
    }

    getEmpresasData();

    let strMensaje = 'borro';
    if (intActivo == '1') {
      strMensaje = 'activo';
    }

    ModalMessage('Mensaje', 'El registro se ' + strMensaje + ' correctamente.');
  };

  //---------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------
  //USUARIOS
  const columnsUsuarios = [
    {
      title: 'Opciones',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 40,
      render: (_, record) => (
        <div>
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              handleOpenModalUpdate(record.id);
            }}
            style={{ width: 40, height: 34 }}
          ></Button>

          <Button
            type="primary"
            icon={
              record.activo == 'Activo' ? <DeleteOutlined /> : <CheckOutlined />
            }
            danger={record.activo == 'Activo' ? true : false}
            onClick={() => {
              showConfirm(EnumCatalogo.Usuario, record.id, record.activo);
            }}
            style={{ marginLeft: 10, width: 40, height: 34 }}
          ></Button>

          {record.activo == 'Activo' ? (
            <Button
              type="primary"
              icon={<MailOutlined />}
              onClick={() => {
                confirm({
                  title: 'Confirmación',
                  icon: <ExclamationCircleFilled />,
                  okText: 'Sí, enviar',
                  cancelText: 'No, cancelar',
                  content:
                    'Enviar correo a "' +
                    record.correo +
                    '" con sus datos de ingreso al sistema?',
                  onOk() {
                    HandleEnviarCorreo(record.id);
                  },
                  onCancel() {},
                });
              }}
              style={{ marginLeft: 10, width: 40, height: 34 }}
            ></Button>
          ) : (
            <div></div>
          )}
        </div>
      ),
    },
    {
      width: 60,
      title: 'Correo',
      dataIndex: 'correo',
      key: 'correo',
      ...getColumnSearchProps({
        dataIndex: 'correo',
        searchInput,
        handleSearch,
        handleReset,
        setSearchText,
        setSearchedColumn,
        searchText,
        searchedColumn,
      }),
    },

    {
      width: 60,
      title: 'Nombre',
      dataIndex: 'nombre_completo',
      key: 'nombre_completo',
      ...getColumnSearchProps({
        dataIndex: 'nombre_completo',
        searchInput,
        handleSearch,
        handleReset,
        setSearchText,
        setSearchedColumn,
        searchText,
        searchedColumn,
      }),
    },
    {
      width: 30,
      title: 'Tipo',
      dataIndex: 'tipo',
      key: 'tipo',
    },
    {
      width: 30,
      title: 'Puesto',
      dataIndex: 'nombre_puesto',
      key: 'nombre_puesto',
      ...getColumnSearchProps({
        dataIndex: 'nombre_puesto',
        searchInput,
        handleSearch,
        handleReset,
        setSearchText,
        setSearchedColumn,
        searchText,
        searchedColumn,
      }),
    },
    {
      width: 30,
      title: 'Activo',
      key: 'activo',
      dataIndex: 'activo',
      filters: [
        {
          text: 'Inactivo',
          value: 'Inactivo',
        },
        {
          text: 'Activo',
          value: 'Activo',
        },
      ],
      onFilter: (value, record) => record.activo.startsWith(value),
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.activo - b.activo,
      render: (_, record) => (
        <>&nbsp;&nbsp;&nbsp;&nbsp;{ReturnActivoTag(record.activo)}</>
      ),
    },
  ];

  const getUsuariosData = async () => {
    let UsuariosData = await TicketsService.getListaDatosUsuarios();
    setDataUsuarios(UsuariosData.data);
  };

  const HandleBorrarUsuarios = async (idUsuario, activo) => {
    let intActivo = '0';
    if (activo == 'Inactivo') {
      intActivo = '1';
    }

    const jsonPost = { id_usuario: idUsuario, activo: intActivo };
    let respuesta = await CatalogosService.postBorrarUsuarios(jsonPost);
    if (respuesta.estado == 500) {
      ModalMessage('Mensaje', 'Se produjo un error al borrar el registro.');
      return false;
    }

    getUsuariosData();

    let strMensaje = 'borro';
    if (intActivo == '1') {
      strMensaje = 'activo';
    }

    ModalMessage('Mensaje', 'El registro se ' + strMensaje + ' correctamente.');
  };

  const handleLimpiaFormulario = () => {
    setInputValue('');
    setinputPrimerNombre('');
    setinputSegundoNombre('');
    setinputApellidoPaterno('');
    setinputApellidoMaterno('');
    setinputCorreo('');
    setinputContrasena('');
    setSelectedValue('');
    setformReporta('');
  };

  const handleInputPrimerNombre = (e) => {
    setinputPrimerNombre(e.target.value);
  };

  const handleInputSegundoNombre = (e) => {
    setinputSegundoNombre(e.target.value);
  };

  const handleInputApellidoPaterno = (e) => {
    setinputApellidoPaterno(e.target.value);
  };

  const handleInputApellidoMaterno = (e) => {
    setinputApellidoMaterno(e.target.value);
  };

  const handleInputCorreo = (e) => {
    setinputCorreo(e.target.value);
  };

  const handleInputContrasena = (e) => {
    setinputContrasena(e.target.value);
  };

  const validaFormulario = () => {
    if (inputPrimerNombre == '') {
      ModalMessage('Mensaje', 'El primer nombre no puede estar en blanco.');
      return false;
    }

    // if (inputSegundoNombre == '') {
    //   ModalMessage('Mensaje', 'El segundo nombre no puede estar en blanco.');
    //   return false;
    // }

    if (inputApellidoPaterno == '') {
      ModalMessage('Mensaje', 'El apellido paterno no puede estar en blanco.');
      return false;
    }

    // if (inputApellidoMaterno == '') {
    //   ModalMessage('Mensaje', 'El apellido materno no puede estar en blanco.');
    //   return false;
    // }

    if (inputCorreo == '') {
      ModalMessage('Mensaje', 'El correo no puede estar en blanco.');
      return false;
    }

    if (inputContrasena == '') {
      ModalMessage('Mensaje', 'La contraseña no puede estar en blanco.');
      return false;
    }

    if (selectedValue == null) {
      ModalMessage(
        'Mensaje',
        'El combo tipo usuario no puede estar en blanco.'
      );
      return false;
    }

    if (formReporta == '') {
      ModalMessage('Mensaje', 'El puesto no puede estar en blanco.');
      return false;
    }

    return true;
  };

  const HandleAgregarUsuario = async () => {
    ShowLoading(true);

    let resultadoValidacion = validaFormulario();

    if (resultadoValidacion == false) {
      ShowLoading(false);
      setdisableButton(false);
      return true;
    }

    const postUsuario = {
      primerNombre: inputPrimerNombre,
      segundoNombre: inputSegundoNombre,
      apellidoPaterno: inputApellidoPaterno,
      apellidoMaterno: inputApellidoMaterno,
      correo: inputCorreo,
      contrasena: inputContrasena,
      tipoUsuario: selectedValue,
      idPuesto: formReporta,
    };
    let usuarioData = await CatalogosService.postAgregarUsuario(postUsuario);

    if (usuarioData.estado == 500) {
      ModalMessage('Mensaje', 'Se produjo un error al agregar el registro.');
      ShowLoading(false);
      setdisableButton(false);
      return false;
    }

    let ListaData = await TicketsService.getListaDatosUsuarios();
    setDataUsuarios(ListaData.data);

    setTimeout(function () {
      scrollToBottomPlaza();
    }, 1000);

    handleLimpiaFormulario();

    ShowLoading(false);
    setdisableButton(false);
    ModalMessage('Mensaje', 'El usuario se agrego correctamente.');
  };

  const HandleEnviarCorreo = async (idUsuario) => {
    const jsonPost = { id_usuario: idUsuario };
    let respuesta = await CatalogosService.postEnviarCorreoUsuario(jsonPost);

    if (respuesta.estado == 500) {
      ModalMessage('Mensaje', 'Se produjo un error al borrar el registro.');
      return false;
    }

    ModalMessage('Mensaje', 'El correo se envio correctamente.');
  };

  const HandleUpdateUser = async () => {
    let resultadoValidacion = validaFormulario();

    if (resultadoValidacion == false) {
      return false;
    }

    ShowLoading(true);
    const postUsuario = {
      id: idUsuarioUpdate,
      primerNombre: inputPrimerNombre,
      segundoNombre: inputSegundoNombre,
      apellidoPaterno: inputApellidoPaterno,
      apellidoMaterno: inputApellidoMaterno,
      correo: inputCorreo,
      contrasena: inputContrasena,
      tipoUsuario: selectedValue,
      idPuesto: formReporta,
    };

    let usuarioData = await CatalogosService.postActualizarUsuario(postUsuario);

    if (usuarioData.estado == 201) {
      ModalMessage('Mensaje', usuarioData.mensaje);
      ShowLoading(false);
      return false;
    }

    if (usuarioData.estado == 500) {
      ModalMessage('Mensaje', 'Se produjo un error al agregar el registro.');
      ShowLoading(false);
      return false;
    }

    let ListaData = await TicketsService.getListaDatosUsuarios();
    setDataUsuarios(ListaData.data);

    setTimeout(function () {
      scrollToBottomPlaza();
    }, 1000);

    handleLimpiaFormulario();
    HandleCloseModal();

    ShowLoading(false);
    ModalMessage('Mensaje', 'El usuario se actualizo correctamente.');
  };

  const HandleCloseModal = async () => {
    handleLimpiaFormulario();
    setisResponseModalOpen(false);
  };

  const [idUsuarioUpdate, setidUsuarioUpdate] = useState('');

  const handleOpenModalUpdate = async (id_usuario) => {
    let result = await CatalogosService.postActualizarDatosUsuario({
      id: id_usuario,
    });
    //console.log(result);

    handleLimpiaFormulario();

    console.log(result.dataEmp);

    setinputPrimerNombre(result.dataEmp.primer_nombre);
    setinputSegundoNombre(result.dataEmp.segundo_nombre);
    setinputApellidoPaterno(result.dataEmp.apellido_paterno);
    setinputApellidoMaterno(result.dataEmp.apellido_materno);
    setinputCorreo(result.dataEmp.correo);
    setinputContrasena(result.dataEmp.contrasena);
    setSelectedValue(result.dataEmp.tipo.toString());
    setformReporta(result.dataEmp.id_puesto);

    setidUsuarioUpdate(id_usuario);
    setisResponseModalOpen(true);
  };

  //------------------------------------------------------------------------------------------------------------------------

  //------------------------------------------------------------------------------------------------------------------------
  //PUESTOS

  const columnsPuestos = [
    {
      title: 'Opciones',
      dataIndex: 'value',
      key: 'value',
      align: 'center',
      width: 150,
      render: (_, record) => (
        <div>
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              handleOpenModalPuestos(record);
            }}
            style={{ width: 40, height: 34 }}
          ></Button>

          <Button
            type="primary"
            icon={
              record.id_activo == 1 ? <DeleteOutlined /> : <CheckOutlined />
            }
            danger={record.id_activo == 1 ? true : false}
            onClick={() => {
              showConfirm(EnumCatalogo.Puesto, record.id, record.activo);
            }}
            style={{ marginLeft: 10, width: 40, height: 34 }}
          ></Button>
        </div>
      ),
    },
    {
      title: 'Nombre del puesto',
      dataIndex: 'descripcion',
      key: 'descripcion',
      ...getColumnSearchProps({
        dataIndex: 'descripcion',
        searchInput,
        handleSearch,
        handleReset,
        setSearchText,
        setSearchedColumn,
        searchText,
        searchedColumn,
      }),
    },
    {
      title: 'Bajo el mando de',
      dataIndex: 'nombre_superior',
      key: 'nombre_superior',
      ...getColumnSearchProps({
        dataIndex: 'nombre_superior',
        searchInput,
        handleSearch,
        handleReset,
        setSearchText,
        setSearchedColumn,
        searchText,
        searchedColumn,
      }),
    },
    {
      title: 'Bajo la dirección de',
      dataIndex: 'nombre_director',
      key: 'nombre_director',
    },
  ];

  const getPuestosData = async () => {
    let puestosData = await CatalogosService.getListaPuestos();

    setoptionReporta(
      puestosData.data.map((item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.descripcion}</div>,
        nombre: item.descripcion,
      }))
    );

    setDataPuestos(puestosData.data);
  };

  const HandleAgregarPuestos = async () => {
    ShowLoading(true);

    if (inputNombrePuesto == '') {
      ModalMessage('Mensaje', 'El nombre del puesto no puede estar en blanco.');
      setdisableButton(false);
      ShowLoading(false);
      return false;
    }

    const postPuesto = {
      idPuesto: idPuestoModal,
      puesto: inputNombrePuesto,
      idSuperior: formReporta == '' ? null : formReporta,
      idDirector: formDirector == '' ? null : formDirector,
    };
    let puestosData = await CatalogosService.postAgregarPuesto(postPuesto);

    if (puestosData.estado == 500) {
      if (idPuestoModal == null) {
        ModalMessage('Mensaje', 'Se produjo un error al agregar el registro.');
      } else {
        ModalMessage(
          'Mensaje',
          'Se produjo un error al modificar el registro.'
        );
      }
      setdisableButton(false);
      ShowLoading(false);
      return false;
    }

    let ListaData = await CatalogosService.getListaPuestos();
    setDataPuestos(ListaData.data);

    setTimeout(function () {
      scrollToBottomPlaza();
    }, 1000);

    setinputNombrePuesto('');
    setformReporta('');
    setformDirector('');
    setidPuestoModal(null);
    setisPuestosModalOpen(false);
    getPuestosData();
    setdisableButton(false);
    ShowLoading(false);

    if (idPuestoModal == null) {
      ModalMessage('Mensaje', 'El puesto se agrego correctamente.');
    } else {
      ModalMessage('Mensaje', 'El puesto se modifico correctamente.');
    }
  };

  const [idElemento, setidElemento] = useState(null);

  const HandleAgregarElemento = async () => {
    ShowLoading(true);
    if (inputNombreElemento == '') {
      ModalMessage(
        'Mensaje',
        'El nombre del elemento no puede estar en blanco.'
      );
      setdisableButton(false);
      ShowLoading(false);
      return false;
    }

    if (formTipoElemento == '') {
      ModalMessage('Mensaje', 'El tipo de elemento no puede estar en blanco.');
      setdisableButton(false);
      ShowLoading(false);
      return false;
    }

    const json = {
      idRegistro: idElemento,
      nombre: inputNombreElemento,
      descripcion: inputDescripcionElemento,
      tipo: formTipoElemento,
    };
    let elementosData =
      await CatalogosService.postAgregarHardwareSoftware(json);

    if (elementosData.estado == 500) {
      if (idRegistro == null) {
        ModalMessage('Mensaje', 'Se produjo un error al agregar el registro.');
      } else {
        ModalMessage(
          'Mensaje',
          'Se produjo un error al modificar el registro.'
        );
      }

      setdisableButton(false);
      ShowLoading(false);
      return false;
    }

    let ListaData = await CatalogosService.getListaElementos();
    setDataElemento(ListaData.data);

    setTimeout(function () {
      scrollToBottomPlaza();
    }, 1000);

    setinputNombreElemento('');
    setformTipoElemento('');
    setinputDescripcionElemento('');

    setidElemento(null);
    setisElementoModalOpen(false);
    setdisableButton(false);
    ShowLoading(false);

    if (idElemento == null) {
      ModalMessage('Mensaje', 'El elemento se agrego correctamente.');
    } else {
      ModalMessage('Mensaje', 'El elemento se modifico correctamente.');
    }
  };

  const HandleBorrarPuesto = async (idPuesto, activo) => {
    let intActivo = '0';
    if (activo == 'Inactivo') {
      intActivo = '1';
    }

    const jsonPost = { idPuesto, activo: intActivo };
    let respuesta = await CatalogosService.postBorrarPuesto(jsonPost);
    if (respuesta.estado == 500) {
      ModalMessage('Mensaje', 'Se produjo un error al borrar el registro.');
      return false;
    }

    getPuestosData();

    let strMensaje = 'borro';
    if (intActivo == '1') {
      strMensaje = 'activo';
    }

    ModalMessage('Mensaje', 'El registro se ' + strMensaje + ' correctamente.');
  };

  const handleOpenModalPuestos = async (regPuesto) => {
    setinputNombrePuesto(regPuesto.descripcion);
    setformReporta(regPuesto.id_superior);
    setformDirector(regPuesto.id_director);
    setidPuestoModal(regPuesto.id);
    setisPuestosModalOpen(true);
  };
  //------------------------------------------------------------------------------------------------------------------------

  //------------------------------------------------------------------------------------------------------------------------
  //SISTEMAS

  const columnsSistemas = [
    {
      title: 'Opciones',
      dataIndex: 'value',
      key: 'value',
      align: 'center',
      width: 160,
      render: (_, record) => (
        <div>
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              handleOpenModalSistemas(record);
            }}
            style={{ width: 40, height: 34 }}
          ></Button>

          <Button
            type="primary"
            icon={
              record.id_activo == 1 ? <DeleteOutlined /> : <CheckOutlined />
            }
            danger={record.id_activo == 1 ? true : false}
            onClick={() => {
              showConfirm(EnumCatalogo.Sistema, record.id, record.activo);
            }}
            style={{ marginLeft: 10, width: 40, height: 34 }}
          ></Button>
        </div>
      ),
    },
    {
      title: 'Tipo',
      key: 'nombre_tipo',
      dataIndex: 'nombre_tipo',
      width: 120,
      filters: [
        {
          text: 'SISTEMA',
          value: 'SISTEMA',
        },
        {
          text: 'CARPETA',
          value: 'CARPETA',
        },
        {
          text: 'ARCHIVO',
          value: 'ARCHIVO',
        },
      ],
      onFilter: (value, record) => record.nombre_tipo.startsWith(value),
      sorter: (a, b) => a.nombre_tipo - b.nombre_tipo,
      render: (_, record) => <>{record.nombre_tipo}</>,
    },
    {
      title: 'Nombre del Recurso',
      dataIndex: 'descripcion',
      key: 'descripcion',
    },
    {
      title: 'Dueño del Recurso',
      dataIndex: 'dueno',
      key: 'dueno',
    },
  ];

  const getUsuariosSistemaData = async () => {
    let UsuariosData = await TicketsService.getListaDatosUsuarios();

    setoptionUsuarios(
      UsuariosData.data.map((item) => ({
        value: item.id, // Convierte el ID a una cadena
        label: <div className="lblOptionCombo">{item.nombre_completo}</div>,
        nombre: item.nombre_completo,
        correo: item.correo,
        puesto: item.id_puesto,
      }))
    );
  };

  const getSistemasData = async () => {
    let SistemasData = await CatalogosService.getListaSistemas();
    setDataSistemas(SistemasData.data);
  };

  const HandleAgregarSistemas = async () => {
    ShowLoading(true);
    if (inputNombreSistema == '') {
      ModalMessage(
        'Mensaje',
        'El nombre del recurso no puede estar en blanco.'
      );
      setdisableButton(false);
      ShowLoading(false);
      return false;
    }

    if (inputTipo == '') {
      ModalMessage('Mensaje', 'El tipo de recurso no puede estar en blanco.');
      setdisableButton(false);
      ShowLoading(false);
      return false;
    }

    const postSistema = {
      idSistema: idSistemaModal,
      nombreSistema: inputNombreSistema,
      tipo: inputTipo,
      idPropietario: inputUsuario == '' ? null : inputUsuario,
      ruta: inputRecursosRuta,
    };

    let sitemaData = await CatalogosService.postAgregarSistema(postSistema);

    if (sitemaData.estado == 500) {
      if (idSistemaModal == null) {
        ModalMessage('Mensaje', 'Se produjo un error al agregar el registro.');
      } else {
        ModalMessage(
          'Mensaje',
          'Se produjo un error al modificar el registro.'
        );
      }

      setdisableButton(false);
      ShowLoading(false);
      return false;
    }

    let ListaData = await CatalogosService.getListaSistemas();
    setDataSistemas(ListaData.data);

    setTimeout(function () {
      scrollToBottomPlaza();
    }, 1000);

    setinputNombreSistema('');
    setinputTipo('');
    setinputUsuario('');
    setidSistemaModal(null);

    setinputRecursosRuta('');
    setisSistemaModalOpen(false);

    setdisableButton(false);
    ShowLoading(false);

    if (idPuestoModal == null) {
      ModalMessage('Mensaje', 'El recurso se agrego correctamente.');
    } else {
      ModalMessage('Mensaje', 'El recurso se modifico correctamente.');
    }
  };

  const handleOpenModalSistemas = async (regPuesto) => {
    setinputNombreSistema(regPuesto.descripcion);
    setinputTipo(regPuesto.tipo.toString());
    setinputUsuario(regPuesto.id_usuario_propietario);
    setidSistemaModal(regPuesto.id);
    setinputRecursosRuta(regPuesto.ruta);

    console.log(regPuesto.tipo);
    if (regPuesto.tipo != 1) {
      setdisableRuta(false);
    } else {
      setdisableRuta(true);
    }

    setisSistemaModalOpen(true);
  };

  const HandleBorrarSistema = async (idSistema, activo) => {
    let intActivo = '0';
    if (activo == 'Inactivo') {
      intActivo = '1';
    }

    const jsonPost = { idSistema: idSistema, activo: intActivo };
    let respuesta = await CatalogosService.postBorrarSistema(jsonPost);
    if (respuesta.estado == 500) {
      ModalMessage('Mensaje', 'Se produjo un error al borrar el registro.');
      return false;
    }

    getSistemasData();

    let strMensaje = 'borro';
    if (intActivo == '1') {
      strMensaje = 'activo';
    }

    ModalMessage('Mensaje', 'El registro se ' + strMensaje + ' correctamente.');
  };

  //------------------------------------------------------------------------------------------------------------------------

  const handleSelectChange = (value) => {
    setSelectedValue(value.toString());
  };

  useEffect(() => {
    //console.log(tableHeight);
    let usr = AuthService.getCurrentUser();
    setUser(usr);

    if (usr === null || usr === undefined) {
      AuthService.logout();
      navigate('/login');
    }

    getClasificacionData();
    getEmpresasData();
    getPlazasData();
    getUsuariosData();
    getPuestosData();
    getSistemasData();
    getUsuariosSistemaData();
    getElementoData();
    //setLoading(false);
  }, []);

  const columns = [
    {
      title: 'Opciones',
      dataIndex: 'value',
      key: 'value',
      align: 'center',
      width: 200,
      render: (_, record) => (
        <div>
          <Button
            type="primary"
            icon={<CaretUpOutlined />}
            onClick={() => {
              HandleSubirRegistro(EnumCatalogo.Clasificacion, record.value);
            }}
            style={{ width: 40, height: 34 }}
          ></Button>

          <Button
            type="primary"
            icon={<CaretDownOutlined />}
            onClick={() => {
              HandleBajarRegistro(EnumCatalogo.Clasificacion, record.value);
            }}
            style={{ width: 40, height: 34, marginLeft: 10 }}
          ></Button>

          <Button
            type="primary"
            icon={
              record.id_activo == 1 ? <DeleteOutlined /> : <CheckOutlined />
            }
            danger={record.id_activo == 1 ? true : false}
            onClick={() => {
              showConfirm(
                EnumCatalogo.Clasificacion,
                record.value,
                record.activo
              );
            }}
            style={{ marginLeft: 10, width: 40, height: 34 }}
          ></Button>
        </div>
      ),
    },
    {
      width: 60,
      title: 'No.',
      dataIndex: 'orden',
      key: 'orden',
    },
    {
      title: 'Clasificación',
      dataIndex: 'label',
      key: 'label',
    },
  ];

  const ReturnActivoTag = (activo) => {
    let color = '';
    let text = '';
    switch (activo) {
      case 'Inactivo':
        color = '#ff0000';
        text = 'Inactivo';
        break;
      case 'Activo':
        color = '#00d61d';
        text = 'Activo';
        break;
    }
    return (
      <Tag color={color} key={activo}>
        {text.toUpperCase()}
      </Tag>
    );
  };

  const ReturnTipoElementoTag = (status) => {
    let color = '';
    let text = '';
    switch (status) {
      case 'Software':
        color = '#0886b4';
        text = 'Software';
        break;
      case 'Hardware':
        color = '#ff7e14';
        text = 'Hardware';
        break;
    }
    return (
      <Tag color={color} key={status}>
        {text.toUpperCase()}
      </Tag>
    );
  };

  const getElementoData = async () => {
    let elementosData = await CatalogosService.getListaElementos();

    setDataElemento(elementosData.data);
  };

  const handleOpenModalElementos = async (registro) => {
    setinputNombreElemento(registro.nombre);
    setinputDescripcionElemento(registro.descripcion);
    setformTipoElemento(registro.id_tipo.toString());
    setidElemento(registro.id);
    setisElementoModalOpen(true);
  };

  const HandleBorrarElemento = async (idRegistro, activo) => {
    ShowLoading(true);

    let intActivo = '0';
    if (activo == 'Inactivo') {
      intActivo = '1';
    }

    const jsonPost = { idRegistro, activo: intActivo };
    let respuesta =
      await CatalogosService.postActivaDesactivaHardwareSoftware(jsonPost);
    if (respuesta.estado == 500) {
      ModalMessage('Mensaje', 'Se produjo un error al borrar el registro.');
      ShowLoading(false);
      return false;
    }

    getElementoData();

    let strMensaje = 'borro';
    if (intActivo == '1') {
      strMensaje = 'activo';
    }

    ShowLoading(false);
    ModalMessage('Mensaje', 'El registro se ' + strMensaje + ' correctamente.');
  };

  const columnsElementos = [
    {
      title: 'Opciones',
      dataIndex: 'value',
      key: 'value',
      align: 'center',
      width: 150,
      render: (_, record) => (
        <div>
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              handleOpenModalElementos(record);
            }}
            style={{ width: 40, height: 34 }}
          ></Button>

          <Button
            type="primary"
            icon={
              record.id_activo == 1 ? <DeleteOutlined /> : <CheckOutlined />
            }
            danger={record.id_activo == 1 ? true : false}
            onClick={() => {
              showConfirm(EnumCatalogo.Elemento, record.id, record.activo);
            }}
            style={{ marginLeft: 10, width: 40, height: 34 }}
          ></Button>
        </div>
      ),
    },
    {
      title: 'Tipo',
      key: 'tipo',
      dataIndex: 'tipo',
      width: 150,
      filters: [
        {
          text: 'Hardware',
          value: 'Hardware',
        },
        {
          text: 'Software',
          value: 'Software',
        },
      ],
      onFilter: (value, record) => record.tipo.startsWith(value),
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.tipo - b.tipo,
      render: (_, record) => (
        <>&nbsp;&nbsp;&nbsp;&nbsp;{ReturnTipoElementoTag(record.tipo)}</>
      ),
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      ...getColumnSearchProps({
        dataIndex: 'nombre',
        searchInput,
        handleSearch,
        handleReset,
        setSearchText,
        setSearchedColumn,
        searchText,
        searchedColumn,
      }),
    },
  ];

  const HandleSubirRegistro = async (opcion, idRegistro) => {
    switch (opcion) {
      case EnumCatalogo.Clasificacion:
        if (ordenRow > 1) {
          const jsonPost = { id_clasificacion: idRegistro };
          let respuesta =
            await CatalogosService.postSubirOrdenClasificacion(jsonPost);
          if (respuesta.estado == 500) {
            ModalMessage(
              'Mensaje',
              'Se produjo un error al mover el registro.'
            );
          }
          getClasificacionData();
        }
        break;
      case EnumCatalogo.Empresa:
        if (ordenRow > 1) {
          const jsonPost = { id_empresa: idRegistro };
          let respuesta =
            await CatalogosService.postSubirOrdenEmpresa(jsonPost);
          if (respuesta.estado == 500) {
            ModalMessage(
              'Mensaje',
              'Se produjo un error al mover el registro.'
            );
          }
          getEmpresasData();
        }
        break;
      case EnumCatalogo.Plaza:
        if (ordenRow > 1) {
          const jsonPost = { id_plaza: idRegistro };
          let respuesta = await CatalogosService.postSubirOrdenPlazas(jsonPost);
          if (respuesta.estado == 500) {
            ModalMessage(
              'Mensaje',
              'Se produjo un error al mover el registro.'
            );
          }
          getPlazasData();
        }
        break;
      default:
        break;
    }
  };

  const HandleBajarRegistro = async (opcion, idRegistro) => {
    switch (opcion) {
      case EnumCatalogo.Clasificacion:
        const jsonPost = { id_clasificacion: idRegistro };
        let respuesta =
          await CatalogosService.postBajarOrdenClasificacion(jsonPost);
        if (respuesta.estado == 500) {
          ModalMessage('Mensaje', 'Se produjo un error al mover el registro.');
        }
        getClasificacionData();
        break;
      case EnumCatalogo.Empresa:
        const jsonPostEmpresa = { id_empresa: idRegistro };
        let respuestaEmpresa =
          await CatalogosService.postBajarOrdenEmpresa(jsonPostEmpresa);
        if (respuestaEmpresa.estado == 500) {
          ModalMessage('Mensaje', 'Se produjo un error al mover el registro.');
        }
        getEmpresasData();
        break;
      case EnumCatalogo.Plaza:
        const jsonPostPlaza = { id_plaza: idRegistro };
        let respuestaPlaza =
          await CatalogosService.postBajarOrdenPlazas(jsonPostPlaza);
        if (respuestaPlaza.estado == 500) {
          ModalMessage('Mensaje', 'Se produjo un error al mover el registro.');
        }
        getPlazasData();
        break;
      default:
        break;
    }
  };

  const onChange = (key) => {
    setformReporta('');
    setidSistemaModal(null);
  };

  const items = [
    {
      key: '4',
      label: <Title level={4}>Usuarios</Title>,
      children: (
        <div
          style={{ width: divContentWidth + 28, marginTop: 10, minWidth: 1000 }}
        >
          <table
            style={{
              marginBottom: 15,
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <tbody>
              <tr>
                <td
                  width={130}
                  style={{ textAlign: 'right', paddingRight: 5, minWidth: 130 }}
                >
                  <b>Primer nombre:</b>
                </td>
                <td>
                  <Input
                    maxLength={60}
                    style={{ width: 200 }}
                    onChange={handleInputPrimerNombre}
                    value={inputPrimerNombre}
                  />
                </td>

                <td width={20}>&nbsp;</td>

                <td width={130} style={{ textAlign: 'right' }}>
                  <b>Segundo nombre:</b>
                </td>
                <td>
                  <Input
                    maxLength={60}
                    style={{ width: 200 }}
                    onChange={handleInputSegundoNombre}
                    value={inputSegundoNombre}
                  />
                </td>
                <td width={20}>&nbsp;</td>

                <td style={{ textAlign: 'right' }}>
                  <b>Apellido paterno:</b>
                </td>
                <td>
                  <Input
                    maxLength={60}
                    style={{ width: 200 }}
                    onChange={handleInputApellidoPaterno}
                    value={inputApellidoPaterno}
                  />
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: 'right' }}>
                  <b>Apellido materno:</b>
                </td>
                <td>
                  <Input
                    maxLength={60}
                    style={{ width: 200 }}
                    onChange={handleInputApellidoMaterno}
                    value={inputApellidoMaterno}
                  />
                </td>

                <td width={20}>&nbsp;</td>

                <td style={{ textAlign: 'right' }}>
                  <b>Correo:</b>
                </td>
                <td>
                  <Input
                    maxLength={60}
                    style={{ width: 200 }}
                    onChange={handleInputCorreo}
                    value={inputCorreo}
                    placeholder="Username@finamo.mx"
                  />
                </td>

                <td width={20}>&nbsp;</td>

                <td style={{ textAlign: 'right' }}>
                  <b>Contraseña:</b>
                </td>
                <td>
                  <Input.Password
                    maxLength={60}
                    style={{ width: 200 }}
                    onChange={handleInputContrasena}
                    value={inputContrasena}
                    placeholder="Contraseña123.."
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: 'right' }}>
                  <b>Tipo usuario:</b>
                </td>
                <td>
                  <Select
                    style={{ textAlign: 'left', width: 200 }}
                    options={optionsTipo}
                    onChange={handleSelectChange}
                    value={selectedValue}
                  />
                </td>

                <td width={20}>&nbsp;</td>

                <td style={{ textAlign: 'right' }}>
                  <b>Puesto:</b>
                </td>
                <td>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Seleccione una opción"
                    options={optionReporta}
                    value={formReporta}
                    onChange={setformReporta}
                    filterOption={UtilsClass.filterOption}
                    style={{ textAlign: 'left', width: 200 }}
                  />
                </td>

                <td colSpan={3}>
                  <Button
                    type="primary"
                    onClick={() => {
                      setdisableButton(true);
                      HandleAgregarUsuario();
                    }}
                    icon={<PlusOutlined />}
                    style={{ marginLeft: 30 }}
                    disabled={disableButton}
                  >
                    Agregar
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>

          <TableUsuarios
            size="small"
            style={{ width: divContentWidth, minWidth: 1000, marginTop: -10 }}
            columns={columnsUsuarios}
            dataSource={DataUsuarios}
            pagination={false}
            ref={tableRefPlaza}
            scroll={{
              y: tableHeight - 10,
            }}
            onRow={(record, rowIndex) => {
              return {
                onMouseEnter: (event) => {
                  HandleSeleccionaRegistroPlazas(record);
                },
              };
            }}
          />
        </div>
      ),
    },
    {
      key: '1',
      label: <Title level={4}>Clasificaciones</Title>,
      children: (
        <div
          style={{ width: divContentWidth + 28, marginTop: 10, minWidth: 1000 }}
        >
          <table style={{ marginBottom: 15 }}>
            <tbody>
              <tr>
                <td>
                  <b>Nueva clasificación:</b>
                </td>
                <td>
                  <Input
                    maxLength={60}
                    style={{ width: 400 }}
                    onChange={handleInputChangeClasificacion}
                    value={inputValue}
                  />
                </td>
                <td>
                  <Button
                    type="primary"
                    onClick={() => {
                      setdisableButton(true);
                      HandleAgregarClasificacion();
                    }}
                    icon={<PlusOutlined />}
                    disabled={disableButton}
                  >
                    Agregar
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>

          <Table
            size="small"
            columns={columns}
            dataSource={Data}
            pagination={false}
            ref={tableRef}
            scroll={{
              y: tableHeight + 60,
            }}
            onRow={(record, rowIndex) => {
              return {
                onMouseEnter: (event) => {
                  HandleSeleccionaRegistroClasificacion(record);
                },
              };
            }}
          />
        </div>
      ),
    },
    {
      key: '2',
      label: <Title level={4}>Empresa</Title>,
      children: (
        <div
          style={{ width: divContentWidth + 28, marginTop: 10, minWidth: 1000 }}
        >
          <table style={{ marginBottom: 15 }}>
            <tbody>
              <tr>
                <td>
                  <b>Nueva Empresa:</b>
                </td>
                <td>
                  <Input
                    maxLength={60}
                    style={{ width: 400 }}
                    onChange={handleInputChangeEmpresa}
                    value={inputValue}
                  />
                </td>
                <td>
                  <Button
                    type="primary"
                    onClick={() => {
                      setdisableButton(true);
                      HandleAgregarEmpresa();
                    }}
                    disabled={disableButton}
                    icon={<PlusOutlined />}
                  >
                    Agregar
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>

          <TableEmpresa
            size="small"
            columns={columnsEmpresas}
            dataSource={DataEmpresas}
            pagination={false}
            ref={tableRefEmpresa}
            scroll={{
              y: tableHeight + 60,
            }}
            onRow={(record, rowIndex) => {
              return {
                onMouseEnter: (event) => {
                  HandleSeleccionaRegistroClasificacion(record);
                },
              };
            }}
          />
        </div>
      ),
    },
    {
      key: '3',
      label: <Title level={4}>Plaza</Title>,
      children: (
        <div
          style={{ width: divContentWidth + 28, marginTop: 10, minWidth: 1000 }}
        >
          <table style={{ marginBottom: 15 }}>
            <tbody>
              <tr>
                <td>
                  <b>Nueva Plaza:</b>
                </td>
                <td>
                  <Input
                    maxLength={60}
                    style={{ width: 400 }}
                    onChange={handleInputChangePlazas}
                    value={inputValue}
                  />
                </td>
                <td>
                  <Button
                    type="primary"
                    onClick={() => {
                      setdisableButton(true);
                      HandleAgregarPlazas();
                    }}
                    icon={<PlusOutlined />}
                    disabled={disableButton}
                  >
                    Agregar
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>

          <TablePlaza
            size="small"
            columns={columnsPlazas}
            dataSource={DataPlazas}
            pagination={false}
            ref={tableRefPlaza}
            scroll={{
              y: tableHeight + 60,
            }}
            onRow={(record, rowIndex) => {
              return {
                onMouseEnter: (event) => {
                  HandleSeleccionaRegistroPlazas(record);
                },
              };
            }}
          />
        </div>
      ),
    },

    {
      key: '5',
      label: <Title level={4}>Puestos</Title>,
      children: (
        <div
          style={{ width: divContentWidth + 28, marginTop: 10, minWidth: 1000 }}
        >
          <table
            style={{
              marginBottom: 15,
              display: 'flex',
              justifyContent: 'flex-start',
              marginLeft: 15,
            }}
          >
            <tbody>
              <tr>
                <td width={150}>
                  <b>Nombre del puesto:</b>
                </td>
                <td>
                  <Input
                    maxLength={60}
                    style={{ width: 600 }}
                    onChange={(e) => {
                      setinputNombrePuesto(e.target.value);
                    }}
                    value={inputNombrePuesto}
                  />
                </td>
              </tr>
              <tr>
                <td width={150}>
                  <b>Bajo el mando de:</b>
                </td>
                <td>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Seleccione una opción"
                    options={optionReporta}
                    value={formReporta}
                    onChange={setformReporta}
                    filterOption={UtilsClass.filterOption}
                    style={{ textAlign: 'left', width: 600 }}
                  />
                </td>
              </tr>
              <tr>
                <td width={150}>
                  <b>Bajo la dirección de:</b>
                </td>
                <td>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Seleccione una opción"
                    options={optionReporta}
                    value={formDirector}
                    onChange={setformDirector}
                    filterOption={UtilsClass.filterOption}
                    style={{ textAlign: 'left', width: 600 }}
                  />
                </td>
                <td width={20}>&nbsp;</td>
                <td>
                  <Button
                    type="primary"
                    onClick={() => {
                      setdisableButton(true);
                      HandleAgregarPuestos();
                    }}
                    disable={disableButton}
                    icon={<PlusOutlined />}
                  >
                    Agregar
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>

          <Table
            size="small"
            columns={columnsPuestos}
            dataSource={DataPuestos}
            pagination={false}
            ref={tableRefPlaza}
            scroll={{
              y: tableHeight - 10,
            }}
            onRow={(record, rowIndex) => {
              return {
                onMouseEnter: (event) => {
                  HandleSeleccionaRegistroPlazas(record);
                },
              };
            }}
          />
        </div>
      ),
    },

    {
      key: '6',
      label: (
        <Tooltip title="Sistemas, Carpetas y Archivos">
          <Title level={4}>Recursos</Title>
        </Tooltip>
      ),
      children: (
        <div
          style={{ width: divContentWidth + 28, marginTop: 10, minWidth: 1000 }}
        >
          <table
            style={{
              marginBottom: 15,
              display: 'flex',
              justifyContent: 'flex-start',
              marginLeft: 15,
            }}
          >
            <tbody>
              <tr>
                <td width={140} style={{ textAlign: 'right' }}>
                  <b>Nombre:</b>
                </td>
                <td>
                  <Input
                    maxLength={60}
                    style={{ width: 600 }}
                    onChange={(e) => {
                      setinputNombreSistema(e.target.value);
                    }}
                    value={inputNombreSistema}
                  />
                </td>
              </tr>

              <tr>
                <td width={140} style={{ textAlign: 'right' }}>
                  <b>Tipo de recurso:</b>
                </td>
                <td>
                  <Select
                    style={{ textAlign: 'left', width: 600 }}
                    options={[
                      {
                        value: '1',
                        label: 'Sistema',
                      },
                      {
                        value: '2',
                        label: 'Carpeta',
                      },
                      {
                        value: '3',
                        label: 'Archivo',
                      },
                    ]}
                    onChange={(value) => {
                      if (value != '1') {
                        setdisableRuta(false);
                      } else {
                        setinputRecursosRuta('');
                        setdisableRuta(true);
                      }
                      setinputTipo(value);
                    }}
                    value={inputTipo}
                  />
                </td>
              </tr>

              <tr>
                <td width={140} style={{ textAlign: 'right' }}>
                  <b>Ruta:</b>
                </td>
                <td>
                  <Input
                    maxLength={60}
                    style={{ width: 600 }}
                    onChange={(e) => {
                      setinputRecursosRuta(e.target.value);
                    }}
                    value={inputRecursosRuta}
                    disabled={disableRuta}
                  />
                </td>
              </tr>

              <tr>
                <td width={140} style={{ textAlign: 'right' }}>
                  <b>Dueño del recurso:</b>
                </td>
                <td>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Seleccione una opción"
                    options={optionUsuarios}
                    value={inputUsuario}
                    onChange={setinputUsuario}
                    filterOption={UtilsClass.filterOption}
                    style={{ textAlign: 'left', width: 600 }}
                  />
                </td>
                <td width={20}>&nbsp;</td>
                <td>
                  <Button
                    type="primary"
                    onClick={() => {
                      setdisableButton(true);
                      HandleAgregarSistemas();
                    }}
                    disabled={disableButton}
                    icon={<PlusOutlined />}
                  >
                    Agregar
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>

          <Table
            size="small"
            columns={columnsSistemas}
            dataSource={DataSistemas}
            pagination={false}
            ref={tableRefPlaza}
            scroll={{
              y: tableHeight - 50,
            }}
            onRow={(record, rowIndex) => {
              return {
                onMouseEnter: (event) => {
                  HandleSeleccionaRegistroPlazas(record);
                },
              };
            }}
          />
        </div>
      ),
    },
    {
      key: '7',
      label: <Title level={4}>Hardware y Software</Title>,
      children: (
        <div
          style={{ width: divContentWidth + 28, marginTop: 10, minWidth: 1000 }}
        >
          <table
            style={{
              marginBottom: 15,
              display: 'flex',
              justifyContent: 'flex-start',
              marginLeft: 15,
            }}
          >
            <tbody>
              <tr>
                <td width={140}>
                  <b>Nombre:</b>
                </td>
                <td>
                  <Input
                    maxLength={60}
                    style={{ width: 600 }}
                    onChange={(e) => {
                      setinputNombreElemento(e.target.value);
                    }}
                    value={inputNombreElemento}
                  />
                </td>
              </tr>
              <tr>
                <td width={140}>
                  <b>Descripción:</b>
                </td>
                <td>
                  <TextArea
                    rows={2}
                    value={inputDescripcionElemento}
                    onChange={(e) => {
                      setinputDescripcionElemento(e.target.value);
                    }}
                    style={{ textAlign: 'left', width: 600 }}
                  />
                </td>
              </tr>
              <tr>
                <td width={140}>
                  <b>Tipo:</b>
                </td>
                <td>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Seleccione una opción"
                    options={[
                      {
                        value: '1',
                        label: 'Hardware',
                      },
                      {
                        value: '2',
                        label: 'Software',
                      },
                    ]}
                    value={formTipoElemento}
                    onChange={setformTipoElemento}
                    filterOption={UtilsClass.filterOption}
                    style={{ textAlign: 'left', width: 600 }}
                  />
                </td>
                <td width={20}>&nbsp;</td>
                <td>
                  <Button
                    type="primary"
                    onClick={() => {
                      setdisableButton(true);
                      setidElemento(null);
                      HandleAgregarElemento();
                    }}
                    icon={<PlusOutlined />}
                    disabled={disableButton}
                  >
                    Agregar
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>

          <Table
            size="small"
            columns={columnsElementos}
            dataSource={DataElemento}
            pagination={false}
            ref={tableRefPlaza}
            scroll={{
              y: tableHeight - 30,
            }}
            onRow={(record, rowIndex) => {
              return {
                onMouseEnter: (event) => {
                  HandleSeleccionaRegistroPlazas(record);
                },
              };
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <Structure
      breadcrumb={'Bienvenido al sistema Fondeadora Finamo'}
      menuOption={'5'}
    >
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: '#3c5c7c',
              colorPrimaryHover: '#517da9',
              colorPrimaryActive: '#517da9',
            },
          },
        }}
      >
        <center>
          <Tabs
            defaultActiveKey="4"
            type="card"
            items={items}
            onChange={onChange}
            rowSelection={10}
          />
        </center>
      </ConfigProvider>
      <Modal
        closable={false}
        destroyOnClose={true}
        open={isPuestosModalOpen}
        width={900}
        title={
          <div>
            <UserOutlined /> &nbsp; Actualizar datos del puesto
          </div>
        }
        footer={[
          <Button
            type="primary"
            onClick={() => {
              HandleAgregarPuestos();
            }}
          >
            <SaveOutlined />
            Guardar
          </Button>,
          <Button
            type="primary"
            onClick={() => {
              setformReporta('');
              setinputNombrePuesto('');
              setisPuestosModalOpen(false);
            }}
            danger
          >
            <CloseOutlined />
            Cerrar
          </Button>,
        ]}
      >
        <table
          style={{
            marginTop: 40,
            marginBottom: 40,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <tbody>
            <tr>
              <td width={140}>
                <b>Nombre del puesto:</b>
              </td>
              <td>
                <Input
                  maxLength={60}
                  style={{ width: 600 }}
                  onChange={(e) => {
                    setinputNombrePuesto(e.target.value);
                  }}
                  value={inputNombrePuesto}
                />
              </td>
            </tr>
            <tr>
              <td width={140}>
                <b>Bajo el mando de:</b>
              </td>
              <td>
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Seleccione una opción"
                  options={optionReporta}
                  value={formReporta}
                  onChange={setformReporta}
                  filterOption={UtilsClass.filterOption}
                  style={{ textAlign: 'left', width: 600 }}
                />
              </td>
              <td width={20}>&nbsp;</td>
            </tr>
            <tr>
              <td width={150}>
                <b>Bajo la dirección de:</b>
              </td>
              <td>
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Seleccione una opción"
                  options={optionReporta}
                  value={formDirector}
                  onChange={setformDirector}
                  filterOption={UtilsClass.filterOption}
                  style={{ textAlign: 'left', width: 600 }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Modal>

      <Modal
        closable={false}
        destroyOnClose={true}
        open={isElementoModalOpen}
        width={900}
        title={
          <div>
            <UserOutlined /> &nbsp; Actualizar datos del elemento
          </div>
        }
        footer={[
          <Button
            type="primary"
            onClick={() => {
              HandleAgregarElemento();
            }}
          >
            <SaveOutlined />
            Guardar
          </Button>,
          <Button
            type="primary"
            onClick={() => {
              setinputNombreElemento('');
              setinputDescripcionElemento('');
              setformTipoElemento('');
              setisElementoModalOpen(false);
            }}
            danger
          >
            <CloseOutlined />
            Cerrar
          </Button>,
        ]}
      >
        <table
          style={{
            marginTop: 40,
            marginBottom: 40,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <tbody>
            <tr>
              <td width={140}>
                <b>Nombre del elemento:</b>
              </td>
              <td>
                <Input
                  maxLength={60}
                  style={{ width: 600 }}
                  onChange={(e) => {
                    setinputNombreElemento(e.target.value);
                  }}
                  value={inputNombreElemento}
                />
              </td>
            </tr>

            <tr>
              <td width={140}>
                <b>Descripción:</b>
              </td>
              <td>
                <TextArea
                  rows={2}
                  value={inputDescripcionElemento}
                  onChange={(e) => {
                    setinputDescripcionElemento(e.target.value);
                  }}
                  style={{ textAlign: 'left', width: 600 }}
                />
              </td>
            </tr>

            <tr>
              <td width={140}>
                <b>Tipo:</b>
              </td>
              <td>
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Seleccione una opción"
                  options={[
                    {
                      value: '1',
                      label: 'Hardware',
                    },
                    {
                      value: '2',
                      label: 'Software',
                    },
                  ]}
                  value={formTipoElemento}
                  onChange={setformTipoElemento}
                  filterOption={UtilsClass.filterOption}
                  style={{ textAlign: 'left', width: 600 }}
                />
              </td>
              <td width={20}>&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </Modal>

      <Modal
        closable={false}
        destroyOnClose={true}
        open={isSistemaModalOpen}
        width={900}
        title={
          <div>
            <UserOutlined /> &nbsp; Actualizar datos del recurso
          </div>
        }
        footer={[
          <Button
            type="primary"
            onClick={() => {
              HandleAgregarSistemas();
            }}
          >
            <SaveOutlined />
            Guardar
          </Button>,
          <Button
            type="primary"
            onClick={() => {
              setformReporta('');
              setinputTipo('');
              setinputNombreSistema('');
              setinputRecursosRuta('');
              setinputUsuario('');
              setisSistemaModalOpen(false);
            }}
            danger
          >
            <CloseOutlined />
            Cerrar
          </Button>,
        ]}
      >
        <table
          style={{
            marginTop: 40,
            marginBottom: 40,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <tbody>
            <tr>
              <td width={140} style={{ textAlign: 'right' }}>
                <b>Nombre:</b>
              </td>
              <td>
                <Input
                  maxLength={60}
                  style={{ width: 600 }}
                  onChange={(e) => {
                    setinputNombreSistema(e.target.value);
                  }}
                  value={inputNombreSistema}
                />
              </td>
            </tr>

            <tr>
              <td width={140} style={{ textAlign: 'right' }}>
                <b>Tipo de recurso:</b>
              </td>
              <td>
                <Select
                  style={{ textAlign: 'left', width: 600 }}
                  options={[
                    {
                      value: '1',
                      label: 'Sistema',
                    },
                    {
                      value: '2',
                      label: 'Carpeta',
                    },
                    {
                      value: '3',
                      label: 'Archivo',
                    },
                  ]}
                  onChange={(value) => {
                    if (value != '1') {
                      setdisableRuta(false);
                    } else {
                      setinputRecursosRuta('');
                      setdisableRuta(true);
                    }
                    setinputTipo(value);
                  }}
                  value={inputTipo}
                />
              </td>
            </tr>

            <tr>
              <td width={140} style={{ textAlign: 'right' }}>
                <b>Ruta:</b>
              </td>
              <td>
                <Input
                  maxLength={60}
                  style={{ width: 600 }}
                  onChange={(e) => {
                    setinputRecursosRuta(e.target.value);
                  }}
                  value={inputRecursosRuta}
                  disabled={disableRuta}
                />
              </td>
            </tr>

            <tr>
              <td width={140} style={{ textAlign: 'right' }}>
                <b>Dueño del recurso:</b>
              </td>
              <td>
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Seleccione una opción"
                  options={optionUsuarios}
                  value={inputUsuario}
                  onChange={setinputUsuario}
                  filterOption={UtilsClass.filterOption}
                  style={{ textAlign: 'left', width: 600 }}
                />
              </td>
              <td width={20}>&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </Modal>

      <Modal
        closable={false}
        destroyOnClose={true}
        open={isResponseModalOpen}
        width={1020}
        title={
          <div>
            <UserOutlined /> &nbsp; Actualizar datos de usuario
          </div>
        }
        footer={[
          <Button
            type="primary"
            onClick={() => {
              HandleUpdateUser(idUsuarioUpdate);
            }}
          >
            <SaveOutlined />
            Guardar
          </Button>,
          <Button type="primary" onClick={HandleCloseModal} danger>
            <CloseOutlined />
            Cerrar
          </Button>,
        ]}
      >
        <table style={{ marginTop: 30, marginBottom: 30 }}>
          <tbody>
            <tr>
              <td width={140} style={{ textAlign: 'right', paddingRight: 10 }}>
                <b>Primer nombre:</b>
              </td>
              <td>
                <Input
                  maxLength={60}
                  style={{ width: 300 }}
                  onChange={handleInputPrimerNombre}
                  value={inputPrimerNombre}
                />
              </td>

              <td width={10}>&nbsp;</td>

              <td width={140} style={{ textAlign: 'right', paddingRight: 10 }}>
                <b>Segundo nombre:</b>
              </td>
              <td>
                <Input
                  maxLength={60}
                  style={{ width: 300 }}
                  onChange={handleInputSegundoNombre}
                  value={inputSegundoNombre}
                />
              </td>
            </tr>

            <tr>
              <td style={{ textAlign: 'right', paddingRight: 10 }}>
                <b>Apellido paterno:</b>
              </td>
              <td>
                <Input
                  maxLength={60}
                  style={{ width: 300 }}
                  onChange={handleInputApellidoPaterno}
                  value={inputApellidoPaterno}
                />
              </td>

              <td width={10}>&nbsp;</td>

              <td style={{ textAlign: 'right', paddingRight: 10 }}>
                <b>Apellido materno:</b>
              </td>
              <td>
                <Input
                  maxLength={60}
                  style={{ width: 300 }}
                  onChange={handleInputApellidoMaterno}
                  value={inputApellidoMaterno}
                />
              </td>
            </tr>

            <tr>
              <td style={{ textAlign: 'right', paddingRight: 10 }}>
                <b>Correo:</b>
              </td>
              <td>
                <Input
                  maxLength={60}
                  style={{ width: 300 }}
                  onChange={handleInputCorreo}
                  value={inputCorreo}
                  placeholder="Username@finamo.mx"
                />
              </td>

              <td width={10}>&nbsp;</td>

              <td style={{ textAlign: 'right', paddingRight: 10 }}>
                <b>Contraseña:</b>
              </td>
              <td>
                <Input.Password
                  maxLength={60}
                  style={{ width: 300 }}
                  onChange={handleInputContrasena}
                  value={inputContrasena}
                  placeholder="Contraseña123.."
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </td>
            </tr>

            <tr>
              <td style={{ textAlign: 'right', paddingRight: 10 }}>
                <b>Tipo usuario:</b>
              </td>
              <td>
                <Select
                  style={{ textAlign: 'left', width: 300 }}
                  options={optionsTipo}
                  onChange={handleSelectChange}
                  value={selectedValue}
                />
              </td>

              <td width={20}>&nbsp;</td>

              <td style={{ textAlign: 'right', paddingRight: 10 }}>
                <b>Puesto:</b>
              </td>
              <td>
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Seleccione una opción"
                  options={optionReporta}
                  value={formReporta}
                  onChange={setformReporta}
                  filterOption={UtilsClass.filterOption}
                  style={{ textAlign: 'left', width: 300 }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Modal>
    </Structure>
  );
};

export default CatalogoTicket;
